import { groupBy } from 'lodash-es'
import ProductService from '../services/ProductService'
import { Adjustment, Order, OrderItem, OrderItemWithRoxProps, RoxableAttributes } from '../types/order'
import {
  getAntiBlue,
  getBrand,
  getCorrectionType,
  getIsPolarized,
  getLensThickness,
  getLensTreatment,
  getLensesColor,
  getProductType,
  getTransitionType,
} from './productAttributes'
import { PRODUCT_PRICE_USAGE_NAMES, ServerProductPrice, DetailedRxPrice } from '../types/product'
import { orderHasPrescriptionUploadedV2 } from './order'
import Log from '../services/Log'

export const getRxAttributes = (lensObject): RoxableAttributes => {
  // TODO: Woulnd't it be better to incapsulate 'getProductAttribute' alongside all other getters?
  return {
    correctionType: getCorrectionType(lensObject),
    lensBrand: getBrand(lensObject),
    lensType: getAntiBlue(lensObject) || getTransitionType(lensObject) || 'Clear',
    lensColor: getLensesColor(lensObject),
    lensThickness: getLensThickness(lensObject),
    lensTreatment: getLensTreatment(lensObject),
    polarized: getIsPolarized(lensObject) === 'True',
  }
}

export const isRxCart = (orderExtendAttribute: Order['orderExtendAttribute']): boolean => {
  return !!orderExtendAttribute?.find(a => a.attributeName === 'IS_ROX_ORDER' && a.attributeValue === 'true')
}

export const isRxProduct = (
  // @ts-expect-error
  orderItemExtendAttribute: Order['orderItemExtendAttribute']
): boolean => {
  return !!orderItemExtendAttribute?.find(a => {
    return a.attributeName === 'IsRoxLens' && a.attributeValue === 'true'
  })
}

export const isRox = (orderItemExtendAttribute: OrderItem['orderItemExtendAttribute']): boolean => {
  try {
    return !!orderItemExtendAttribute?.find(a => a.attributeName === 'IsRox')
  } catch (e) {
    return false
  }
}

export const isInsuranceEligible = (orderItem: OrderItem): boolean => {
  const isContactLens =
    orderItem?.orderItemExtendAttribute?.find(attr => attr.attributeName === 'isContactLens')?.attributeValue === 'true'
  return isContactLens || isRxFrame(orderItem.orderItemExtendAttribute)
}

export const isRxFrame = (orderItemExtendAttribute: OrderItem['orderItemExtendAttribute']): boolean => {
  try {
    return !!orderItemExtendAttribute?.find(a => a.attributeName === 'RxLensId')
  } catch (e) {
    return false
  }
}

export const isRxLens = (orderItemExtendAttribute: OrderItem['orderItemExtendAttribute']): boolean => {
  try {
    return !!orderItemExtendAttribute?.find(a => a.attributeName === 'IsRoxLens')
  } catch (e) {
    return false
  }
}

export const getRxLensItem = (parsedOrderItem?: OrderItem): OrderItem | undefined => {
  return parsedOrderItem?.roxableServices?.find(x => isRxLens(x.orderItemExtendAttribute))
}

export const getGroupedFrames = (orderItems: OrderItem[]) => {
  return groupBy(orderItems || [], item => item.xitem_field1 || item.correlationGroup)
}

export const parseRxOrderItems = (items: OrderItem[]): OrderItem[] | OrderItemWithRoxProps[] => {
  const groupedFrames = getGroupedFrames(items)
  const groupedFrameValues = Object.values(groupedFrames)

  return groupedFrameValues.map(framesArray => {
    if (framesArray.length > 1) {
      const lens = framesArray.find(({ orderItemExtendAttribute }) =>
        orderItemExtendAttribute.find(a => a.attributeName === 'IsRoxLens')
      )

      const frame = framesArray.find(({ orderItemExtendAttribute }) =>
        orderItemExtendAttribute.find(a => a.attributeName === 'RxLensId')
      )

      const roxableServices = framesArray.filter(item => item?.correlationGroup !== frame?.correlationGroup)
      const isPrescriptionUploadedForLens = roxableServices ? orderHasPrescriptionUploadedV2(roxableServices[0]) : false
      const roxableAttributes = getRxAttributes(lens)

      return {
        ...frame,
        prescriptionUploaded: isPrescriptionUploadedForLens,
        roxableAttributes,
        roxableServices,
        prescriptionDetails: lens?.prescriptionDetails || null,
      } as OrderItemWithRoxProps
    } else return framesArray[0]
  })
}

export const getRxPrice = (rxServices?: any[], rxFramePrice?: string): number => {
  const frameTotal = parseFloat(rxFramePrice || '0')
  const rxTotals =
    rxServices?.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue?.orderItemPrice || 0)
    }, 0) || 0

  return rxTotals + frameTotal
}

export const getRxLensPrice = (rxServices: OrderItemWithRoxProps['roxableServices']): number | undefined => {
  try {
    return rxServices?.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue?.x_offerpriceRx || currentValue?.orderItemPrice || 0)
    }, 0)
  } catch (e) {
    return undefined
  }
}

const getAdjustmentDiscount = (adjustments: Adjustment[] = []) =>
  adjustments
    .filter(adjustment => adjustment?.usage === 'Discount')
    .reduce((accumulator, adjustment) => accumulator + parseFloat(adjustment?.amount ?? '0'), 0)

export const getRxLensDiscountedPrice = (rxServices: OrderItemWithRoxProps['roxableServices']): number | undefined => {
  try {
    return rxServices?.reduce((accumulator, rxService) => {
      const { x_offerDiscountpriceRx, orderItemPrice, adjustment } = rxService
      /* use pricelist to account for MDT discount */
      const mdtDiscountPrice = parseFloat(x_offerDiscountpriceRx || orderItemPrice || '0')
      /* use adjustments to tally discount from Order Item level discounts */
      const promoDiscount = getAdjustmentDiscount(adjustment || [])
      return accumulator + mdtDiscountPrice + promoDiscount
    }, 0)
  } catch (e) {
    return undefined
  }
}

export const getRxDiscountedPrice = (
  rxServices: OrderItemWithRoxProps['roxableServices'] | undefined,
  rxFramePrice: string | undefined,
  rxFrameAdjustments: Adjustment[]
): number => {
  if (!rxServices || !rxFramePrice) return 0

  const rxLensDiscountedPrice = getRxLensDiscountedPrice(rxServices) || 0
  const rxFrameAdjustmentsAmount = getAdjustmentDiscount(rxFrameAdjustments || []) || 0
  return rxLensDiscountedPrice + parseFloat(rxFramePrice || '0') + rxFrameAdjustmentsAmount
}

export const getDetailedRxPrice = (rxServices: OrderItem[]): DetailedRxPrice[] | null => {
  try {
    return rxServices.map(service => ({
      type: getProductType(service),
      currentPrice: {
        usage: PRODUCT_PRICE_USAGE_NAMES.CURRENT,
        currency: service?.currency ?? 'GBP',
        value: service?.orderItemPrice ?? '0',
      },
      initialPrice: {
        usage: PRODUCT_PRICE_USAGE_NAMES.INITIAL,
        currency: service?.currency ?? 'GBP',
        value: service?.x_offerpriceRx ?? '0',
      },
    }))
  } catch (e: any) {
    Log.error('Could not get detailed rx price: ' + e)
    return null
  }
}

export const getFormattedTotalRxPrice = (rxPrices: DetailedRxPrice[]): ServerProductPrice[] | null => {
  try {
    const { initialPrice, currentPrice } = rxPrices[0]
    return rxPrices.reduce(
      (total, price, index) => {
        const [initialPriceModel, currentPriceModel] = total
        return index
          ? [
              {
                ...initialPriceModel,
                value: String(+initialPriceModel.value + +price.initialPrice.value),
              },
              {
                ...currentPriceModel,
                value: String(+currentPriceModel.value + +price.currentPrice.value),
              },
            ]
          : total
      },
      [initialPrice, currentPrice]
    )
  } catch (e: any) {
    Log.error('Could not get formatted rx price: ' + e)
    return null
  }
}

export const getDetailedPrices = (productPrices: ServerProductPrice[], rxPrices?: OrderItem[]) => {
  try {
    const formattedPrice = ProductService.genPriceWithoutValue(productPrices)

    const detailedRxPrice = rxPrices ? getDetailedRxPrice(rxPrices) : null
    const totalRxPrice = detailedRxPrice ? getFormattedTotalRxPrice(detailedRxPrice) : null

    const initialFramePrice = ProductService.getInitialPriceModel(formattedPrice) as ServerProductPrice
    const currentFramePrice = ProductService.getCurrentPriceModel(formattedPrice) as ServerProductPrice

    const initialRxProductsPrice = totalRxPrice ? ProductService.getInitialPriceModel(totalRxPrice) : null
    const currentRxProductsPrice = totalRxPrice ? ProductService.getCurrentPriceModel(totalRxPrice) : null

    const totalCurrentPrice = ProductService.calculateTotalPriceModel(
      currentRxProductsPrice ? [currentFramePrice, currentRxProductsPrice] : [currentFramePrice]
    )
    const totalInitialPrice = ProductService.calculateTotalPriceModel(
      initialRxProductsPrice ? [initialFramePrice, initialRxProductsPrice] : [initialFramePrice]
    )

    return {
      initialFramePrice,
      currentFramePrice,
      initialRxProductsPrice,
      currentRxProductsPrice,
      totalInitialPrice,
      totalCurrentPrice,
    }
  } catch (e: any) {
    Log.error('Could not get detailed prices: ' + e)
    return null
  }
}
