import { createAsyncThunk } from '@reduxjs/toolkit'
import { CountryStatesResponse } from '../../types/common'
import countryService from '../../foundation/apis/transaction/country.service'

export interface ICountryListPayload {
  storeId?: string
  profileName?: string | string[]
  countryCode?: string | string[]
  widget?: string
}

export const fetchCountryListDetails = createAsyncThunk<CountryStatesResponse, ICountryListPayload>(
  'account/fetchCountryListDetails',
  async (parameters) => {
    try {
      const serviceResponse = await countryService.findCountryStateList(parameters)

      return serviceResponse.data
    } catch (e: any) {
      return e
    }
  }
)
