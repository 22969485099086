export const countryUtil = (country = '', langCode = '') => {
  const currentCountry = country.substr(3)?.toLowerCase()
  const currentLangCode = `${langCode.slice(0, 2)}-${langCode.slice(3, 5)?.toLowerCase()}`
  const currentLangDescription = (currentLangCode.length > 1 && currentLangCode.slice(0, 2)) || 'en'

  return {
    currentCountry,
    currentLangCode,
    currentLangDescription,
  }
}

export const localeLangCountryUtil = (langCode = '', delimeter = '_') => {
  const currentLang = langCode.split('-')
  const currentLangCountry = currentLang[0] + delimeter + currentLang[1].toUpperCase()
  return currentLangCountry
}

export const NEW_ZEALAND_PROVINCES_MAP = new Map<string, string>([
  ['Auckland', 'AKL'],
  ['Bay of Islands', 'BOI'],
  ['Bay of Plenty', 'BOP'],
  ['Canterbury', 'CAN'],
  ['Christchurch Metro', 'CHC'],
  ['Chatham Islands', 'CIT'],
  ['Dunedin Metro', 'DUN'],
  ['East Coast', 'EAC'],
  ['Fiordland', 'FIO'],
  ['Gisborne', 'GIS'],
  ['Hawke\'s Bay', 'HAB'],
  ['King Country', 'KNG'],
  ['Manawatu-Wanganui', 'MAN'],
  ['Marlborough', 'MBH'],
  ['Mid Canterbury', 'MIC'],
  ['Martinborough', 'MRT'],
  ['Nelson', 'NLS'],
  ['North Canterbury', 'NOC'],
  ['Northland', 'NTL'],
  ['Otago', 'OTA'],
  ['South Canterbury', 'SOC'],
  ['Southland', 'STL'],
  ['Taranaki', 'TAR'],
  ['Tasman', 'TAS'],
  ['Waikato', 'WAI'],
  ['Wairarapa', 'WAR'],
  ['West Coast', 'WEC'],
  ['Wellington', 'WGN'],
  ['Westland', 'WTL'],
])
