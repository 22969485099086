import { HASH_NAVIGATION_ELEMENT_TIMEOUT, HASH_NAVIGATION_PREFIX } from '@constants/navigation'

export const waitForLazyElementToLoad = async (
  selector: string,
  timeout = HASH_NAVIGATION_ELEMENT_TIMEOUT
): Promise<Element | undefined> => {
  return new Promise(resolve => {
    const interval = setInterval(() => {
      const element = document.querySelector(selector)
      if (element) {
        clearInterval(interval)
        resolve(element)
      }
    }, 100)
    setTimeout(() => {
      clearInterval(interval)
      resolve(undefined)
    }, timeout)
  })
}

export const generateAnchorID = (placementName: string): string => {
  return `${HASH_NAVIGATION_PREFIX}${placementName}`
}
