export const TRACKER_ADDTOCART = 'Event'
export const TRACKER_ADDTOWISHLIST = 'Event'
export const TRACKER_PRODS_DELETE = 'Prods-Delete'
export const TRACKER_PRODS_CHANGE_UPC = 'Prods-Update'
export const TRACKER_MINI_WISHLIST_OVERLAY_OPEN = 'MiniWishOverlay-Open'
export const TRACKER_MINICART_OVERLAY_OPEN = 'MinicartOverlay-Open'
export const TRACKER_OPEN_VIRTUAL_MIRROR = 'VirtualMirror'
export const TRACKER_CHECKOUT_STEP_GATEWAY = 'Checkout-Step-Gateway'

export const TRACKER_NEWSLETTER_SIGNUP = 'Event'
export const TRACKER_REGISTRATION = 'Account-Create'
export const TRACKER_SEARCH = 'SearchPageReady'
export const TRACKER_PROMOCODE_TOGGLE = 'X_X_PromoCode_Open'
export const TRACKER_ADD_TO_WISH_LIST = 'X_X_Prod_AddToWishList'
export const TRACKER_REMOVE_FROM_WISH_LIST = 'X_X_Prod_RemoveFromWishList'
export const TRACKER_MOUSE_CLICK = 'Click'

// Server' error generated or managed by server side, 'User' error generated by user and properly managed by code (i.e. for filling errors), 'Client' error generated by code in page usually for a catched JS error, "Anomaly" unexpected error/behaviour of page/content, "Product" error caused by product DB
export const TRACKER_ERROR_SOURCE_USER = 'User'
export const TRACKER_ERROR_SOURCE_404 = '404'
export const TRACKER_ERROR_SOURCE_SERVER = 'Server'
export const TRACKER_ERROR_SOURCE_CLIENT = 'Client'
export const TRACKER_ERROR_SOURCE_PRODUCT = 'Product'
export const TRACKER_ERROR_SOURCE_ANOMALY = 'Anomaly'

export const PRODUCT_STATUS = {
  AVAILABLE: 'Available',
  AVAILABLE_ONE_LEFT: 'Available-oneleft',
  AVAILABLE_TWO_LEFT: 'Available-twoleft',
  AVAILABLE_FEW_LEFT: 'Available-fewleft',
  COMING_SOON: 'Coming soon',
  OUT_OF_STOCK: 'Out-of-stock',
  NOT_FOR_SALE: 'Not-for-sale',
  SOLD_OUT: 'SoldOut',
}
