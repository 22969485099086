import { CMS_PAGES_URL_PREFIX } from '../constants/routes'
import { cmsImageCrops } from '../constants/ui'
import config from '../configs'
import qs from 'qs'
import queryString from 'query-string'
import { IVideoMedia } from '@typesApp/cmsPlacement/Media'
import { isBrowser } from './isBrowser'

export const formatPathWithBase = (path: string, basePath: string): string => {
  return basePath + (path.startsWith('/') ? path : `/${path}`)
}

/**
 * Update URL query string values
 * @returns {string}
 */
export const updateUrlParameter = (
  url: string,
  key: string,
  value: number | boolean | string | undefined | null
): string => {
  const [baseurl, search] = url.split('?')
  const querystring = qs.parse(search)
  querystring[key] = value == null ? '' : value.toString()

  return `${baseurl}?${qs.stringify(querystring)}`
}

export const replaceUrl = url => {
  window.history.replaceState({}, '', url)
}

export const updateUrl = (url, parameters?) => {
  const updatedUrl = queryString.stringifyUrl(
    {
      url,
      query: parameters,
    },
    {
      encode: false,
    }
  )
  window.history.pushState({}, '', updatedUrl)
}

export const formattedParams = params => {
  return queryString.stringifyUrl(
    {
      url: '',
      query: params,
    },
    {
      encode: false,
    }
  )
}

export const fromUrlParamsToObject = (url: string, decode = false) => {
  return queryString.parse(url, { decode })
}

export const getImageFromCMS = (
  hostnameUrls: Record<string, string>,
  url: string,
  crop?: string,
  width?: number | string
) => {
  const imageHostname = hostnameUrls.cmsImageServerUrl || config.cmsImageServerUrl
  if (!url) {
    return ''
  }
  if (crop) {
    const rep = url.replace('/{cropName}/{width}', `/${crop || 'NOTFOUND'}/${width || 'NOTFOUND'}`)
    return `${imageHostname}${rep}`
  }
  return undefined
}

export const getCmsImageCrops = (type: string) => {
  const bannerCropOption = cmsImageCrops[type]
  const { mobile, deskL, deskS, tabletL, tabletP } = bannerCropOption || {}
  return {
    mobile,
    deskL,
    deskS,
    tabletL,
    tabletP,
  }
}

export const getSrcSetsImageFromCms = (type: string, url: string, hostnameUrls: Record<string, string>) => {
  const bannerCropOption = cmsImageCrops[type]
  const { desk, mobile, tablet, deskL, deskS, tabletL, tabletP } = bannerCropOption || {}

  const srcSets = {
    desk: getImageFromCMS(hostnameUrls, url, desk?.crop || '', desk?.width),
    tablet: getImageFromCMS(hostnameUrls, url, tablet?.crop || '', tablet?.width),
    mobile: getImageFromCMS(hostnameUrls, url, mobile?.crop || '', mobile?.width),

    deskL: getImageFromCMS(hostnameUrls, url, deskL?.crop || '', deskL?.width),
    deskS: getImageFromCMS(hostnameUrls, url, deskS?.crop || '', deskS?.width),
    tabletL: getImageFromCMS(hostnameUrls, url, tabletL?.crop || '', tabletL?.width),
    tabletP: getImageFromCMS(hostnameUrls, url, tabletP?.crop || '', tabletP?.width),
  }

  return srcSets
}

export const getVideoFromCMS = (video: IVideoMedia) => {
  const url = video.data?.uri || video.dataUrl
  const uri = url.startsWith('/') ? url : `/${url}`
  return `${config.cmsImageServerUrl}${uri}`
}

/**
 * removeStoreSegment remove the store segment of the url
 * retrieved from CMS
 * @param  url the url to parse
 *
 */
export const removeStoreSegment = url => {
  return url
    .split('/')
    .filter(item => item.length > 0)
    .join('/')
    .replace('//', '/')
}

export const seoUrlConcat = (langCode: string, seoHref: string, absoluteUrl = true) => {
  let url = !langCode.startsWith('/') && absoluteUrl ? `/${langCode}` : langCode
  let divider = '/'

  if (url && url.endsWith('/')) {
    url = url.slice(0, -1)
  }

  if (seoHref && seoHref.startsWith('/')) {
    divider = ''
  }

  return [url, seoHref].join(divider)
}

export const getUrlPathNoLangCode = (url?: string) => {
  const path = url || window.location.pathname
  return path.split('/').slice(2)?.join('/')
}

export const getUrlPathParams = (url: string) => {
  const params = url.substring(url.indexOf(CMS_PAGES_URL_PREFIX) + CMS_PAGES_URL_PREFIX?.length)
  const levels = params.split('/')
  return {
    pageNameLv1: levels[1],
    pageNameLv2: levels[2],
    pageNameLv3: levels[3],
  }
}

export const cmsApiUrl = `${isBrowser() ? config.cmsApiUrl : config.cmsApiUrlInternal}`.replace(/\/$/, '').concat('/')
