import { CartRecuringOrderSectionProps } from '@views/Cart/components/CartRecuringOrderSection'
import { OrderTotalSummaryProps } from '@components/OrderTotalSummary/OrderTotalSummary'
import { OrderItem, PromotionCode } from './order'
import { ContactLensData, IProduct, IServerProduct } from './product'
import { CheckoutPayload } from './checkout'

export interface ICartItemsCountMap {
  [productId: string]: {
    count: number
    product: IProduct | IServerProduct | OrderItem
  }
}

export interface CartViewProps {
  cartItemsCountMap: ICartItemsCountMap
  hasOrderItems: boolean
  isApplePayLoading: boolean
  isCheckoutEnabled: boolean
  isRXProductsLimitExceeded: boolean
  lastRemovedItem: any
  orderTotalSummaryProps: OrderTotalSummaryProps['order']
  payloadBase: any
  promotionCodes: PromotionCode[]
  recuringOrderProps: CartRecuringOrderSectionProps
  isRecurringOrderFeatureEnabled?: boolean
  rxParsedCartItems: any[]
  onCheckoutClick(): void
  onRemoveItem: React.Dispatch<any>
  onRemoveItemUndo(): void
}

export interface CartPayload extends CheckoutPayload {
  currency?: string
  contractId?: string
  checkInventory?: boolean
  fetchCatentries?: boolean
  fetchShippingInfo?: boolean
  filterPrescriptionItemType?: 'cl' | 'rx'
  filterPrescriptionNeededItems?: boolean
  refetch?: boolean
  fromOrderId?: any
  sortOrderItemBy?: string
  body?: CheckoutPayload['body'] & {
    x_calculateOrder?: string
    x_calculationUsage?: string
    x_inventoryValidation?: boolean
    fromOrderId_1?: string
    toOrderId?: string
    copyOrderItemId_1?: string
    orderItemId?: string
    orderItemId_1?: string
    orderItemId_2?: string
    orderItemId_3?: string
    orderItemId_4?: string
    orderItem?: {
      quantity?: string
      orderItemId?: string
    }[]
  }
}
export interface OrderItemEyeContactLensData {
  data: ContactLensData | null
  quantity: string | null
  orderItemId: string | null
}

export type OrderItemContactLensData = Record<string, OrderItemEyeContactLensData>

export interface ReorderData {
  copyOrderItemId?: string
  fromOrderId: string
  fromOrderId_0?: string
  rxCopyEnabled: string
  newUserId: string
}

export interface OneClickReorderPayload {
  orderId: string
  isOldOrderId?: boolean
}

export interface ShippingChargesPayload {
  storeId: string
  orderId: string
}

export enum ShippingMethodsEnum {
  Standard = 'Standard',
  Express = 'Express',
  PremiumExpress = 'Premium-Express',
  Green = 'Green',
  StandardException = 'Standard-exception',
  ExpressException = 'Express-exception',
  PremiumExpressException = 'Premium-Express-exception',
}

export type ShippingMethods =
  | ShippingMethodsEnum.Standard
  | ShippingMethodsEnum.StandardException
  | ShippingMethodsEnum.Express
  | ShippingMethodsEnum.ExpressException
  | ShippingMethodsEnum.PremiumExpress
  | ShippingMethodsEnum.PremiumExpressException
  | ShippingMethodsEnum.Green

export const SHIPPING_DAYS_MAP = {
  [ShippingMethodsEnum.Standard]: 7,
  [ShippingMethodsEnum.Express]: 3,
  [ShippingMethodsEnum.PremiumExpress]: 1,
}

export type DiscountedShipMethodsList = Record<string, string>

export const IS_CUT_OVER = false

export const MIN_SLA_PLANO = IS_CUT_OVER
  ? {
      ca: 5,
      au: 10,
      nz: 10,
    }
  : {
      ca: 2,
      au: 5,
      nz: 5,
    }

export const MIN_SLA_RX = IS_CUT_OVER
  ? {
      ca: 7,
      au: 10,
      nz: 10,
    }
  : {
      ca: 3,
      au: 6,
      nz: 6,
    }

export const SLA_BUFFER_PLANO = 2

export const SLA_BUFFER_RX = IS_CUT_OVER ? 3 : 4

export const CUT_OVER_IN_STOCK_BUFFER = { ca: 1, au: 2, nz: 2 }
export const CUT_OVER_OUT_OF_STOCK_BUFFER = { ca: 6, au: 4, nz: 4 }

export const shipModeCodesOrdered = [
  ShippingMethodsEnum.Standard,
  ShippingMethodsEnum.Express,
  ShippingMethodsEnum.PremiumExpress,
]

export const SHIP_METHOD_SHORT_CODE_MAP = {
  Standard: {
    FRAME: 'SHIPPING_DAYS_STANDARD_FRAME',
    ROX: 'SHIPPING_DAYS_STANDARD_ROX',
    CL: 'SHIPPING_DAYS_STANDARD_CL',
    ACCESSORY: 'SHIPPING_DAYS_STANDARD_SOLUTIONS',
  },
  Express: {
    FRAME: 'SHIPPING_DAYS_EXPRESS_FRAME',
    ROX: 'SHIPPING_DAYS_EXPRESS_ROX',
    CL: 'SHIPPING_DAYS_EXPRESS_CL',
    ACCESSORY: 'SHIPPING_DAYS_EXPRESS_SOLUTIONS',
  },
  'Premium-Express': {
    FRAME: 'SHIPPING_DAYS_PREMIUM_EXPRESS_FRAME',
    ROX: 'SHIPPING_DAYS_PREMIUM_EXPRESS_ROX',
    CL: 'SHIPPING_DAYS_PREMIUM_EXPRESS_CL',
    ACCESSORY: 'SHIPPING_DAYS_PREMIUM_EXPRESS_SOLUTIONS',
  },
  'Standard-exception': {
    FRAME: 'SHIPPING_DAYS_STANDARD_FRAME',
    ROX: 'SHIPPING_DAYS_STANDARD_ROX',
    CL: 'SHIPPING_DAYS_STANDARD_CL',
    ACCESSORY: 'SHIPPING_DAYS_STANDARD_SOLUTIONS',
  },
  'Express-exception': {
    FRAME: 'SHIPPING_DAYS_EXPRESS_FRAME',
    ROX: 'SHIPPING_DAYS_EXPRESS_ROX',
    CL: 'SHIPPING_DAYS_EXPRESS_CL',
    ACCESSORY: 'SHIPPING_DAYS_EXPRESS_SOLUTIONS',
  },
  'Premium-Express-exception': {
    FRAME: 'SHIPPING_DAYS_PREMIUM_EXPRESS_FRAME',
    ROX: 'SHIPPING_DAYS_PREMIUM_EXPRESS_ROX',
    CL: 'SHIPPING_DAYS_PREMIUM_EXPRESS_CL',
    ACCESSORY: 'SHIPPING_DAYS_PREMIUM_EXPRESS_SOLUTIONS',
  },
}
