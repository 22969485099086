import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AlternateURL } from '../../foundation/seo/SEO'
import { ISeo } from '@typesApp/seo'

export type SeoState = {
  loading?: boolean
  seoData?: ISeo | null
  alternateHrefs?: Array<AlternateURL>
}

const initialState: SeoState = { loading: false, seoData: null }

export const seoSlice = createSlice({
  name: 'seo',
  initialState: initialState,
  reducers: {
    setSeoData: (state: SeoState, action) => {
      state.seoData = action.payload
    },
    setAlternateHrefs(state, action: PayloadAction<Array<AlternateURL>>) {
      return {
        ...state,
        alternateHrefs: action.payload,
      }
    },
  },
})

export const { setAlternateHrefs, setSeoData } = seoSlice.actions

export const seoAlternateHrefsSelector = (state: any): Array<AlternateURL> => state?.['seo']?.['alternateHrefs']

export default seoSlice.reducer
