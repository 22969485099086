import clsx from 'clsx'
import styles from './styles/SVGIcon.module.scss'
import { SVG_LIBRARY } from './library'
import { SVGIconConditionalProps } from './SVGIcon.types'

export type SVGExtendedProps = {
  sizeAuto?: boolean
  size?: number
}

export type SVGIconProps = React.BaseHTMLAttributes<SVGElement> & SVGIconConditionalProps & SVGExtendedProps

/**
 * This component is used to show external svg icons available in public/icons folder of the project.
 *
 * Each icon is treated as being square, so we only need to pass one `size` prop to control dimensions of the icon.
 * Default icon size is 1rem x 1rem (16px x 16px).
 *
 * **IMPORTANT:** The preferred way of setting icon sizes is via custom CSS where height and width can be set according to your requirements.
 * Using `size` prop overrides styling set in a CSS class.
 *
 * Most icons use single fill color, so it is enough to either set fill attribute in css or
 * pass color directly to this component.
 *
 * For multicolored icons you can use css variables to pass required color down to shadow content (external svg).
 * E.g.: --color-primary, --color-secondary
 *
 * Example usage of custom color variables with this component:
 *
 * ```
 * <SVGIcon library="validation" id="checkmark-filled" className={clsx(styles.icon)} />
 * ```
 *
 * The passed CSS class definition:
 * ```
 * .icon {
 *   --color-primary: blue;
 *   --color-secondary: yellow;
 * }
 * ```
 *
 * In the above example icon has two customizable colors: primary and secondary.
 * Setting both CSS variables is optional, as each color option within the svg has a fallback color.
 * E.g.:
 * ```
 * <circle id="svg-primary" fill="var(--color-primary, transparent)" cx="8" cy="8" r="8" />
 * ```
 *
 * Available color variable names for multicolor icons can be found in the corresponding symbol definition in icon's library file.
 */
export function SVGIcon(props: SVGIconProps) {
  const { name: id, library, className, sizeAuto, color, size, style, ...rest } = props
  if (!library && !id) return null
  const fileName = SVG_LIBRARY[library].fileName

  // NOTE: If an icon supports multiple colors its id is postfixed with '-mc'.
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(styles.icon, className, sizeAuto && styles.sizeAuto)}
      color={color}
      style={{ width: size, height: size, ...style }}
      {...rest}
    >
      <use href={id ? `${fileName}#${id}` : fileName} />
    </svg>
  )
}
