import { IncomingMessage } from 'node:http'
import domainUrlsConfiguration from '../../configuration/config.json'

export const getHostnameUrls = (req: IncomingMessage | undefined) => {
  /* Akamai sends a comma-separated string of all origin hostnames for each jump: "uat.clearly.ca, uat.clearly.ca" */
  const xForwardedHost = req?.headers?.['x-forwarded-host'] ?? []
  const xForwardedHostList = Array.isArray(xForwardedHost) ? xForwardedHost : xForwardedHost?.split(',')
  const hostname = xForwardedHostList[0] || req?.headers?.host || ''
  const hostnameUrls = domainUrlsConfiguration?.[hostname] ?? {}

  return {
    hostname,
    hostnameUrls
  }
}
