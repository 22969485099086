import { AxiosPromise, AxiosRequestConfig, Method } from 'axios'
import { CheckoutPayload } from '../../../types/checkout'
//Standard libraries
import RequestService from '../../../services/RequestService'

//Foundation libraries
import { executeRequest } from '../../axios/axiosConfig'
import { getCookieByName } from '../../../utils/cookie'
import { getSite } from '../../hooks/useSite'
import { site as siteConstant } from '@foundation/constants/site'

const paymentInstructionService = {
  /**
   * Gets the order estimated taxes value without shipping address set.
   * `@method`
   * `@name estimateTaxes`
   * `@property {string} orderId (required)` The order identifier
   * `@property {string} zipCode (required)` The zipCode value
   */
  estimateTaxes(orderId: string, zipCode: string, storeId: string) {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    return RequestService.request({
      body: { orderId, zipCode, userAgent, forterToken },
      method: 'POST',
      pathParams: { storeId },
      path: '/store/{storeId}/estimatedTax/calculate',
    })
  },

  /**
   * Invalidate the addresses added during the apple pay checkout flow
   * `@method`
   * `@name invalidateExpressAdresses`
   */
  invalidateExpressAdresses() {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    return RequestService.request({
      method: 'GET',
      path: '/store/{storeId}/person/@self/contact/invalidateexpressadresses',
      body: { userAgent, forterToken },
    })
  },
  /**
   * Gets the order estimated taxes value without shipping address set.
   * `@method`
   * `@name estimateTaxes`
   * `@property {string} orderId (required)` The order identifier
   * `@property {string} zipCode (required)` The zipCode value
   */
  getPaypalPaymentInfo(successUrl: string, cancelUrl: string) {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    return RequestService.request({
      body: { userAgent, forterToken },
      method: 'POST',
      path: '/store/{storeId}/paypal/sessionMerchantUrl/@self',
      queryParams: { successUrl, cancelUrl },
    })
  },
  /**
   * get all payment Instructions from the shopping cart.
   * `@method`
   * `@name PaymentInstruction#deleteAllPaymentInstructions`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   */
  getAllPaymentInstructions(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const siteContext: string = siteConstant.transactionContextUrl
    const site = getSite()
    const domain = url || siteContext
    let path = '/store/{storeId}/cart/@self/payment_instruction'
    let requestUrl = domain + path
    let method: Method = 'GET'
    let form: any = {}
    let body = {}
    let header: Headers
    let queryParameters = new URLSearchParams()
    let formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    let headerValues: any = {}
    headerValues['Accept'] = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (let val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error('Request /store/{storeId}/cart/@self/payment_instruction missing path parameter storeId')
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      let formData = new FormData()
      for (let p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (let p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (let headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    let requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },

  /**
   * Adds payment instruction to the shopping cart.
   * @method
   * @name PaymentInstruction#addPaymentInstruction
   * @param {CheckoutPayload} payload` request payload
   */

  addPaymentInstruction(payload: CheckoutPayload): AxiosPromise<any> {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    return RequestService.request({
      body: { userAgent, forterToken, ...payload.body },
      method: 'POST',
      path: '/store/{storeId}/cart/@self/payment_instruction',
    })
  },

  /**
   * Deletes all payment Instructions from the shopping cart.
   * @method
   * @name PaymentInstruction#deleteAllPaymentInstructions
   *  @param {CheckoutPayload} payload` request payload
   */

  deleteAllPaymentInstructions(): AxiosPromise<any> {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    return RequestService.request({
      body: { userAgent, forterToken },
      method: 'DELETE',
      path: '/store/{storeId}/cart/@self/payment_instruction',
    })
  },
  /**
   * update the cart payment instruction.
   * @method
   * @name PaymentInstruction#deleteAllPaymentInstructions
   *  @param {CheckoutPayload} payload` request payload
   */

  updatePaymentInstructions(payload: CheckoutPayload): AxiosPromise<any> {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    return RequestService.request({
      body: { userAgent, forterToken, ...payload.body },
      method: 'PUT',
      path: '/store/{storeId}/cart/@self/payment_instruction',
    })
  },

  calculateTax(payload: CheckoutPayload): AxiosPromise<any> {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    return RequestService.request({
      body: {
        userAgent,
        forterToken,
        x_calculateOrder: 1,
        calculationUsageId: [-1, -2, -3, -4, -5, -6, -7],
        orderId: '.',
        ...payload.body,
      },
      method: 'POST',
      path: '/store/{storeId}/cart/calculate',
    })
  },
  setupPayerAuthentication(payload: CheckoutPayload): AxiosPromise<any> {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    return RequestService.request({
      body: { userAgent, forterToken, ...payload.body },
      method: 'GET',
      path: '/store/{storeId}/payerAuthentication/paSetup/@self',
    })
  },
  checkPayerAuthenticationEnroll(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const siteContext: string = siteConstant.transactionContextUrl
    const site = getSite()
    const domain = url || siteContext
    let path = '/store/{storeId}/payerAuthentication/paCheckEnroll/@self'
    let requestUrl = domain + path
    let method: Method = 'GET'
    let body = {}
    let header: Headers
    let queryParameters = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    let headerValues: any = {}
    headerValues['Accept'] = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (let val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error('Request /store/{storeId}/payerAuthentication/paCheckEnroll/@self missing path parameter storeId')
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }
    if (parameters['body'] !== undefined) {
      body = { ...body, ...parameters['body'] }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    const headerObject: any = {}
    for (let headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    body = { ...body, userAgent, forterToken }
    queryParameters.sort()
    let requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },

  validatePayerAuthentication(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const siteContext: string = siteConstant.transactionContextUrl
    const site = getSite()
    const domain = url || siteContext
    let path = '/store/{storeId}/payerAuthentication/paValidate/@self'
    let requestUrl = domain + path
    let method: Method = 'GET'
    let body = {}
    let header: Headers
    let queryParameters = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    let headerValues: any = {}
    headerValues['Accept'] = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (let val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error('Request /store/{storeId}/payerAuthentication/paValidate/@self missing path parameter storeId')
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    const headerObject: any = {}
    for (let headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    body = { ...body, userAgent, forterToken }
    let requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },
  signPaymentData(payload: CheckoutPayload): AxiosPromise<any> {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    return RequestService.request({
      body: { userAgent, forterToken, ...payload.body },
      method: 'GET',
      path: '/store/{storeId}/paypal/signPaymentData/@self',
    })
  },
  createKlarnaSession() {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    return RequestService.request({
      body: { userAgent, forterToken },
      method: 'GET',
      path: '/store/{storeId}/klarna/create-session/@self',
    })
  },
}

export default paymentInstructionService
