import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '@services/AxiosBaseQuery'
import {
  ReturnCreateRequest,
  ReturnCreateResponse,
  ReturnDetailsResponse,
  ReturnLabelRequestParams,
  ReturnLabelResponse,
  ReturnReasonsResponse,
  ReturnSecurityCheckResponse,
} from '@typesApp/orderReturn'

export const orderReturnApi = createApi({
  reducerPath: 'orderReturnApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/',
  }),
  endpoints: build => ({
    getAvailableReturnReasons: build.query<
      ReturnReasonsResponse,
      {
        storeId: string
      }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/return/reasons`,
          method: 'GET',
          pathParams: {
            storeId: storeId,
          },
        })
        return result?.data
          ? { data: result.data as ReturnReasonsResponse }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    createReturn: build.mutation<ReturnCreateResponse, { returnRequest: ReturnCreateRequest; storeId: string }>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { returnRequest, storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/process/weaver/shipping/api`,
          method: 'POST',
          body: {
            ...returnRequest,
          },
          pathParams: {
            storeId: storeId,
          },
        })
        return result?.data
          ? { data: result.data as ReturnCreateResponse }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    generateSecurityToken: build.mutation<ReturnSecurityCheckResponse, { rmaId: string; storeId: string }>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { rmaId, storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/process/weaver/returns/security_check`,
          method: 'POST',
          body: { rmaId },
          pathParams: {
            storeId: storeId,
          },
        })
        return result?.data
          ? { data: result.data as ReturnSecurityCheckResponse }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    generateReturnLabel: build.mutation<
      ReturnLabelResponse,
      { returnLabel: ReturnLabelRequestParams; storeId: string }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { returnLabel, storeId } = args
        const { rmaId, securityCheck } = returnLabel
        const result = await fetchWithBQ({
          url: `store/${storeId}/process/weaver/returns/rma/${rmaId}/label/${securityCheck}`,
          method: 'GET',
          pathParams: {
            storeId: storeId,
            rmaId: rmaId,
            securityCheck: securityCheck,
          },
        })
        return result?.data
          ? { data: result.data as ReturnLabelResponse }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getReturnItemDetails: build.query<ReturnDetailsResponse, { orderItemId: string; storeId: string }>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { orderItemId, storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/returns/rmaItemDetail/${orderItemId}`,
          method: 'GET',
          pathParams: {
            storeId: storeId,
            orderItemId: orderItemId,
          },
        })
        return result?.data
          ? { data: result.data as ReturnDetailsResponse }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
  }),
})

export const {
  useGetAvailableReturnReasonsQuery,
  useCreateReturnMutation,
  useGenerateSecurityTokenMutation,
  useGenerateReturnLabelMutation,
  useGetReturnItemDetailsQuery,
} = orderReturnApi
