import { SiteInfo } from '@redux/reducers/reducerStateInterface'
import { StateManagerSSR } from './stateManager'

class StoreInfoData extends StateManagerSSR<SiteInfo> {
  private static instance: StoreInfoData | null = null

  constructor() {
    super()
  }

  public static newInstance = (): StoreInfoData => {
    if (this.instance) {
      return this.instance
    }
    const newInstance = new StoreInfoData()
    this.instance = newInstance
    return newInstance
  }
}

export const storeInfoDataInstance = StoreInfoData.newInstance()
