import { EMAIL_VALIDATION_PATTERN } from '../components/UI/Form'
import { IFormField } from '../types/form'
import { PasswordFormData, PersonalInfoFormData, RegistrationFormData, SettingsFormData } from '../types/user'

export const PERSONAL_INFO_FORM_FIELDS: IFormField<keyof PersonalInfoFormData>[] = [
  {
    fieldName: 'firstName',
    mandatory: true,
    validation: new RegExp(/^[a-zA-Z\s]*$/),
  },
  { fieldName: 'lastName', mandatory: true },
  {
    fieldName: 'phone1',
    mandatory: false,
    validation: new RegExp(/^(?:\+?(\d{1,3}))?([-. (]?(\d{3})[-. )]*)?((\d{3})[-. ]?(\d{2,4})(?:[-. ]?(\d{1,5}))?)$/gm),
  },
  { fieldName: 'preferredLanguage', mandatory: false },
  { fieldName: 'preferredCurrency', mandatory: false },
  { fieldName: 'gender', mandatory: false },
  { fieldName: 'dateOfBirth', mandatory: true },
  { fieldName: 'email1', mandatory: true },
]

export const PASSWORD_FORM_FIELDS: IFormField<keyof PasswordFormData>[] = [
  { fieldName: 'password1', mandatory: true },
  { fieldName: 'password2', mandatory: true },
]

export const REGISTRATION_FORM_FIELDS: IFormField<keyof RegistrationFormData>[] = [
  {
    fieldName: 'firstName',
    mandatory: true,
    validation: new RegExp(/^[a-zA-Z\s]*$/),
  },
  { fieldName: 'lastName', mandatory: true },
  {
    fieldName: 'email',
    mandatory: true,
    validation: new RegExp(EMAIL_VALIDATION_PATTERN),
  },
  {
    fieldName: 'email2',
    mandatory: true,
    validation: new RegExp(EMAIL_VALIDATION_PATTERN),
  },
  { fieldName: 'password1', mandatory: true },
  { fieldName: 'password2', mandatory: true },
  { fieldName: 'privacy1', mandatory: true },
  { fieldName: 'privacy2', mandatory: false },
]

export const SETTINGS_FORM_FIELDS: IFormField<keyof SettingsFormData>[] = [
  { fieldName: 'currentPassword', mandatory: true },
  { fieldName: 'newPassword', mandatory: true },
  { fieldName: 'verifyPassword', mandatory: true },
]
