import first from 'lodash/first'
import { IProduct } from '../types/product'

export const padDecimals = (price: string | number, padLength?: number): string => {
  if (isNaN(+price)) {
    return price ? price.toString() : ''
  }
  return (Math.round(Number(price) * 100) / 100).toFixed(padLength || 2)
}

// TODO implement all logic for cluster selection
export const getProductPrices = (product: IProduct) => {
  let currentSku
  const clusters = product.cluster ?? product.items //items when products are not clustered

  if (clusters && clusters.length > 0 && clusters[0]?.sKUs) {
    currentSku = first(clusters[0]?.sKUs)
  } else {
    currentSku = product
  }

  if (!currentSku.x_prices || !currentSku.x_prices.list) return

  const fullPrice = currentSku?.x_prices?.list.price
  const discountPrice = currentSku?.x_prices?.offer
    ? currentSku?.x_prices?.offer.price
    : currentSku?.x_prices?.list.price

  return {
    full: fullPrice || '0',
    discount: discountPrice || '0',
    currency: currentSku.x_prices.list.currency,
  }
}

export const retrieveProductPrices = price => {
  //price is not defined or price is defined put empty
  if (!price || (price && !price.offerPrice && !price.listPrice)) {
    return {
      initialPrice: '',
      currentPrice: '',
      discountAmount: 0,
      hasInitialPriceDiscount: false,
    }
  }
  //price is defined and offerPrice and listPrice are defined
  if (price.offerPrice && price.listPrice) {
    if (+price.offerPrice < +price.listPrice || +price.offerPrice === +price.listPrice) {
      return {
        initialPrice: price.listPrice,
        currentPrice: price.offerPrice,
        discountAmount: price?.percentageDiscount,
        hasInitialPriceDiscount: !!(
          price?.percentageDiscount &&
          +price?.percentageDiscount > 0 &&
          price.listPrice &&
          +price.listPrice > 0
        ),
      }
    } else {
      return {
        initialPrice: price.segment === 'Guest' ? price.listPrice : price.offerPrice,
        currentPrice: price.segment === 'Guest' ? price.listPrice : price.offerPrice,
        discountAmount: 0,
        hasInitialPriceDiscount: false,
      }
    }
  } else {
    //only listPrice defined
    if (!price.offerPrice) {
      return {
        initialPrice: price.listPrice,
        currentPrice: price.listPrice,
        discountAmount: 0,
        hasInitialPriceDiscount: false,
      }
    } else {
      //only offerPrice defined
      return {
        initialPrice: price.offerPrice,
        currentPrice: price.offerPrice,
        discountAmount: 0,
        hasInitialPriceDiscount: false,
      }
    }
  }
}
export const formatPriceValue = (value: number): string => {
  return value.toFixed(2)
}
export const getFinalPrice = (
  initialPrice: string,
  totalBoxes?: number,
  isCL?: boolean,
  isCLAccessory?: boolean,
  quantity?: number
) => {
  switch (true) {
    case isCLAccessory && !!quantity:
      return formatPriceValue(+initialPrice * (quantity || 0))
    case isCL:
      return formatPriceValue(+initialPrice * (totalBoxes || 0))
    default:
      return formatPriceValue(+initialPrice)
  }
}
export const parseProductTypeForPrice = (productType?: string) => {
  return productType?.toLowerCase().replaceAll(' ', '-')
}
