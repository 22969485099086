import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { RootReducerState } from '@redux/rootReducer'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import DateService from '../../services/DateService'
import { Order, OrderResponse } from '../../types/order'
import { stringArrayToArray } from '@utils/common'

export type OrderHistoryArgs = {
  userId?: string
  dateBefore?: string
  pageNumber?: number
  pageSize?: number
  sort?: 'descending' | 'ascending' | 'none'
}

const GET_HISTORY_REQUEST_PAGE_SIZE = 100
const DEFAULT_MONTHS_BEFORE = 6

const sortByDate = (orders: Order[], sort: OrderHistoryArgs['sort'] = 'descending'): Order[] => {
  if (sort === 'none') {
    return orders
  }

  return sort === 'descending'
    ? orders.sort((a, b) => new Date(b.placedDate || '').getTime() - new Date(a.placedDate || '').getTime())
    : orders.sort((a, b) => new Date(a.placedDate || '').getTime() - new Date(b.placedDate || '').getTime())
}

export const orderHistoryApi = createApi({
  reducerPath: 'orderHistoryApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/store/{storeId}/order',
  }),

  endpoints: build => ({
    getOrderHistory: build.query<Order[], Partial<OrderHistoryArgs>>({
      queryFn: async (args, queryApi, _extraOptions, fetchWithBQ) => {
        const state = queryApi.getState() as RootReducerState
        const params: OrderHistoryArgs & { userId: string; storeId: string } = {
          ...args,
          pageNumber: args.pageNumber || 1,
          pageSize: args.pageSize || GET_HISTORY_REQUEST_PAGE_SIZE,
          userId: args.userId || state.user.userId,
          storeId: state.site.currentSite?.storeID || '',
        }

        if (!params.dateBefore) {
          const dateNow = new Date()
          dateNow.setMonth(dateNow.getMonth() - DEFAULT_MONTHS_BEFORE)
          params.dateBefore = DateService.format(dateNow, 'yyyy-MM-dd')
        }

        const result = await fetchWithBQ({
          url: '/@history',
          method: 'get',
          pathParams: { storeId: state.site.currentSite?.storeID || '' },
          queryParams: params,
        })

        if (result?.data) {
          // Converting data from API response to FE format
          const orders: OrderResponse[] = result.data?.Order || []
          const modifiedOrders: Order[] = orders.map(order => {
            if (order.x_orderItemsThumbnails) {
              order.x_orderItemsThumbnailsArray = stringArrayToArray(order.x_orderItemsThumbnails)
              delete order.x_orderItemsThumbnails
            }
            if (order.x_estimatedDeliveryDates) {
              order.x_estimatedDeliveryDatesArray = stringArrayToArray(order.x_estimatedDeliveryDates)
              delete order.x_estimatedDeliveryDates
            }
            return order
          })

          return { data: sortByDate(modifiedOrders, args.sort) }
        } else {
          return { error: result.error as FetchBaseQueryError }
        }
      },
    }),
  }),
})

export const { useGetOrderHistoryQuery } = orderHistoryApi
