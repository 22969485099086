import { Attribute, OrderItem } from '@typesApp/order'

export const getOrderItemTaxFilters = {
  isProvincial: (orderId: string, attribute: Attribute<string>): boolean => {
    const regex = new RegExp(`${orderId}:TAX_RATE:.@PROVINCE@`, 'i')
    return regex.test(attribute.attributeName)
  },
  isFederal: (orderId: string, attribute: Attribute<string>): boolean => {
    const regex = new RegExp(`${orderId}:TAX_RATE:.@COUNTRY@`, 'i')
    return regex.test(attribute.attributeName)
  },
}

export const getOrderSalesTaxFilters = {
  isProvincial: (attribute: Attribute<string>): boolean => {
    const regex = new RegExp('._RATE:.@PROVINCE@', 'i')
    return regex.test(attribute.attributeName)
  },
  isFederal: (attribute: Attribute<string>): boolean => {
    const regex = new RegExp('._RATE:.@COUNTRY@', 'i')
    return regex.test(attribute.attributeName)
  },
}

export const getFormattedDiscountedPrice = (orderItem: OrderItem) => {
  const adjustmentDiscount = orderItem?.adjustment?.find(item => item.usage === 'Discount')
  const discountedPrice = Number(adjustmentDiscount?.amount)
  return (Number.isFinite(discountedPrice) && +discountedPrice.toFixed(2)) || 0
}
