import axios from 'axios'
import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { generateUrl, getHeaders } from '../utils'
import { CartData, OnBehalfApiArgs } from '../types'
import { axiosBaseQuery } from '@services/AxiosBaseQuery'
import { PersonResponse } from '@typesApp/user'

export const onBehalfApi = createApi({
  reducerPath: 'onBehalfApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/' }),
  tagTypes: ['OnBehalf'],
  endpoints: builder => ({
    getHclUserSession: builder.query<PersonResponse, OnBehalfApiArgs>({
      queryFn: async ({ urlSelector, params }) => {
        try {
          // We can't use fetchWithBQ here since we are checking for an active user in fetchWithBQ and we don't have it before this call
          const response = await axios(generateUrl(urlSelector, params), {
            method: 'GET',
            headers: getHeaders(),
          })

          return { data: response.data }
        } catch (error) {
          return {
            error: 'Error in fetching HCL user details',
          }
        }
      },
    }),
    getCart: builder.query<CartData, OnBehalfApiArgs>({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { urlSelector, params } = args
        const result = await fetchWithBQ({
          url: generateUrl(urlSelector, params),
          method: 'get',
          headers: getHeaders(),
        })

        return result?.data ? { data: result.data } : { error: result.error as FetchBaseQueryError }
      },
    }),
    buttonActions: builder.mutation<void, OnBehalfApiArgs>({
      queryFn: async (args, _queryApi, _extraOptions, fetchWithBQ) => {
        const { urlSelector, params } = args
        const result = await fetchWithBQ({
          url: generateUrl(urlSelector, params),
          method: 'post',
          headers: getHeaders(),
        })

        return result?.data ? { data: result.data } : { error: result.error as FetchBaseQueryError }
      },
    }),
  }),
})

export const { useGetHclUserSessionQuery, useGetCartQuery, useButtonActionsMutation } = onBehalfApi
