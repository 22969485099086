import { IXStoreConfig } from '@redux/rootReducer'
import { AddressFormData } from '../types/form'
import { LANGUAGE_CODE_MAP } from './common'

const { ...notNorthAmericaCountriesLangCode } = LANGUAGE_CODE_MAP

// export const NORTH_AMERICA_LANG_CODES = [en_us, en_ca, en_ww]
export const NOT_NORTH_AMERICA_LANG_CODES = Object.values(notNorthAmericaCountriesLangCode)

export const hasCountryNewsletterCheck = (optInBoxes: IXStoreConfig['optInBoxes']): boolean => {
  // return NOT_NORTH_AMERICA_LANG_CODES.includes(langCode)

  return Array.isArray(optInBoxes) && typeof optInBoxes[1] === 'boolean' ? optInBoxes[1] : true
}

export const CART_DATA_ELEMENT_IDS = {
  CHECKOUT: 'X_X_Cart_Checkout',
  APPLEPAY_CHECKOUT: 'Checkout_ApplePay-CTA',
  APPLEPAY_CHECKOUT_EXPRESS: 'Cart_ApplePayExpress',
  PAYPAL_CHECKOUT: 'X_X_Cart_CheckoutPaypal',
  PROMOCODE_SUBMIT: 'X_X_PromoCode_Submit',
  PROMOCODE_REMOVE: 'X_X_PromoCode_Remove',
} as const

export const cartDesktopBreakpoint = theme => theme.breakpoints.up('md')

export const CHECKOUT_STEPS = {
  SHIPPING: 'shipping',
  PAYMENT: 'payment',
  REORDER_SUMMARY: 'reorder-summary',
  ORDER_CONFIRMATION: 'order-confirmation',
  UPLOAD_PRESCRIPTION: 'upload-prescription',
} as const

export const APPLEPAY_ADDRESS_PLACEHOLDER: AddressFormData = {
  addressLine: ['applepay-none'],
  firstName: 'applepay-none',
  lastName: 'applepay-none',
  email1: 'applepay@custom.com',
}
export const APPLEPAY_ORDER_ID = 'applePayOrderId'
export const BILLING_ADDRESS_ID = 'billingAddressId'
export const ORDER_CONFIRMATION_DEBUG = 'orderConfirmationDebug'
export const ORDER_CONFIRMATION_DEBUG_FLAG = 'debugOrder'
export const APPLEPAY_ORDER_ID_PARAM = 'applePayOrderId'
export const APPLEPAY_ORDER_FLAG = 'isApplePay'
export const ORDER_ID_PARAM = 'orderId'
