export const FILTER_ICONS = {
  fileName: '/images/icons/filter-sprite.svg',
  icons: [
    'filters',
    'plp-listing-full',
    'plp-listing-compact',
    'cl-accessories',
    'contact-lens',
    'glasses-case',
    'glasses',
    'eye-glasses',
    'accessories',
    'eye-care',
    'home',
    'glasses-cross',
  ],
} as const
