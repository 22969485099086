export const PAYMENT_ICONS = {
  fileName: '/images/icons/payment-sprite.svg',
  icons: [
    'affirm',
    'amex',
    'klarna',
    'placeholder',
    'apple-pay',
    'apple-pay-transparent',
    'discover',
    'jcb',
    'afterpay',
    'mastercard',
    'visa',
    'paypal',
    'paypal-regular',
    'paypal-white',
  ],
} as const
