export const FRAME_ICONS = {
  fileName: '/images/icons/frame-sprite.svg',
  icons: [
    'butterfly',
    'cat-eye',
    'geometrical',
    'hexagonal',
    'irregular',
    'oval',
    'panthos',
    'pillow',
    'pilot',
    'rectangle',
    'round',
    'round-large',
    'square',
  ],
} as const
