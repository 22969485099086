import config from '@configs/index'
export const site = {
  storeName: config?.name || '',
  country: config?.defaultCountry || '',
  transactionContextUrl: `${config?.transactionHost}${config?.transactionContext}` || '',
  transactionContext: config?.transactionContext || '',
  searchContext: config?.searchContext || '',
  searchContextUrl: `${config?.transactionHost}${config?.searchContext}` || '',
  searchContextUrlInternal: `${config?.transactionHostInternal}${config?.searchContext}` || '',
  cmcPath: '/lobtools/cmc/ManagementCenterMain',
}
