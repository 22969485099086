//Standard libraries
import { createAction } from '@reduxjs/toolkit'
//Redux
import * as ACTIONTYPES from '../action-types/site'

export const INIT_SITE_SUCCESS_ACTION = createAction<any, string>(ACTIONTYPES.INIT_SITE_SUCCESS)

export const SET_HOSTNAME_URLS = createAction<any, string>(ACTIONTYPES.SET_HOSTNAME_URLS)

export const SET_HOSTNAME = createAction<any, string>(ACTIONTYPES.SET_HOSTNAME)

export const SET_ALGOLIA_BASED_PLP_ACTION = createAction<any, string>(ACTIONTYPES.SET_PLP_BASED_PLP)
