import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ISuccessSliceState {
  key: string
  link?: {
    url: string
    textKey: string
  }
  messageParameters?: Record<string, string | undefined>
}

const initialState: ISuccessSliceState = {
  key: '',
}

const successSlice = createSlice({
  name: 'success',
  initialState,
  reducers: {
    sendSuccessMessage: (
      state,
      { payload }: PayloadAction<Pick<ISuccessSliceState, 'key'> & Partial<Omit<ISuccessSliceState, 'key'>>>
    ) => {
      state.key = payload.key
      state.link = payload.link
      state.messageParameters = payload.messageParameters
    },
    reset: () => initialState,
  },
})

export const { sendSuccessMessage, reset } = successSlice.actions

export default successSlice.reducer
