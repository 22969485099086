import { groupBy } from 'lodash-es'
import { Order, OrderItem, OrderItemWithRoxProps, RoxableAttributes } from '@typesApp/order'
import { isOpticalProduct } from './product'
import {
  getAntiBlue,
  getBrand,
  getCorrectionType,
  getIsPolarized,
  getIsRoxable,
  getLensThickness,
  getLensTreatment,
  getLensesColor,
  getTransitionType,
} from './productAttributes'

export const getRxAttributes = (lensObject): RoxableAttributes => {
  return {
    correctionType: getCorrectionType(lensObject),
    lensBrand: getBrand(lensObject),
    lensType: getAntiBlue(lensObject) || getTransitionType(lensObject) || 'Clear',
    lensColor: getLensesColor(lensObject),
    lensThickness: getLensThickness(lensObject),
    lensTreatment: getLensTreatment(lensObject),
    polarized: getIsPolarized(lensObject) === 'True',
  }
}

export const isRxCart = (orderExtendAttribute: Order['orderExtendAttribute']): boolean => {
  return !!orderExtendAttribute?.find(a => a.attributeName === 'IS_ROX_ORDER' && a.attributeValue === 'true')
}

export const isRxProduct = (
  // @ts-expect-error
  orderItemExtendAttribute: Order['orderItemExtendAttribute']
): boolean => {
  return !!orderItemExtendAttribute?.find(a => {
    return a.attributeName === 'IsRoxLens' && a.attributeValue === 'true'
  })
}

export const isRoxableProduct = (orderItem: OrderItem): boolean => {
  return getIsRoxable(orderItem) && isOpticalProduct(orderItem)
}

export const isRox = (orderItemExtendAttribute: OrderItem['orderItemExtendAttribute']): boolean => {
  return !!orderItemExtendAttribute?.find(a => a.attributeName === 'IsRox')
}

export const isRxFrame = (orderItemExtendAttribute: OrderItem['orderItemExtendAttribute']): boolean => {
  return !!orderItemExtendAttribute?.find(a => a.attributeName === 'RxLensId')
}

export const isRxLens = (orderItemExtendAttribute: OrderItem['orderItemExtendAttribute']): boolean => {
  return !!orderItemExtendAttribute?.find(a => a.attributeName === 'IsRoxLens')
}

const getGroupedFrames = (orderItems: OrderItem[]) => {
  return groupBy(orderItems || [], item => item.xitem_field1 || item.correlationGroup)
}

export const parseOrderItems = (items: OrderItem[]): OrderItem[] | OrderItemWithRoxProps[] => {
  const groupedFrames = getGroupedFrames(items)
  const groupedFrameValues = Object.values(groupedFrames)

  return groupedFrameValues.map(framesArray => {
    if (framesArray.length > 1) {
      const lens = framesArray.find(({ orderItemExtendAttribute }) =>
        orderItemExtendAttribute.find(a => a.attributeName === 'IsRoxLens')
      )

      const frame = framesArray.find(({ orderItemExtendAttribute }) =>
        orderItemExtendAttribute.find(a => a.attributeName === 'RxLensId')
      )

      const roxableServices = framesArray.filter(item => item?.correlationGroup !== frame?.correlationGroup)

      const roxableAttributes = getRxAttributes(lens)

      return {
        ...frame,
        roxableAttributes,
        roxableServices,
      } as OrderItemWithRoxProps
    } else return framesArray[0]
  })
}

export const getRxPrice = (rxServices: any[], rxFramePrice: string | undefined): number => {
  return (
    rxServices.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue?.x_offerpriceRx || currentValue?.orderItemPrice || 0)
    }, 0) + parseFloat(rxFramePrice || '0')
  )
}
