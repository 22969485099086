import get from 'lodash/get'
import { RootReducerState } from '@redux/rootReducer'

const loginStatusSelector = (state: RootReducerState): boolean => {
  return state.user.userLoggedIn || false
}
const logoutProgressSelector = (state: RootReducerState) => {
  return state.user.isLogoutInProgress || false
}
const loginErrorMsgSelector = (state: RootReducerState) => {
  return state.user.userLoginErrorMsg
}
const guestStatusSelector = (state: RootReducerState) => {
  return state.user.isGuest || false
}
const wcTokenSelector = (state: RootReducerState) => {
  return state.user.WCToken
}
const wcTrustedTokenSelector = (state: RootReducerState) => {
  return state.user.WCTrustedToken
}

const logonIdSelector = (state: RootReducerState) => {
  return state.user.details?.logonId || ''
}

const userIdSelector = (state: RootReducerState) => {
  return state.user.userId
}

const forUserIdSelector = (state: RootReducerState) => {
  return state.user.forUserId
}

const userNameSelector = (state: RootReducerState) => {
  const firstName = state.user.details?.firstName || ''
  const lastName = state.user.details?.lastName || ''
  return { firstName, lastName }
}

const userInitStatusSelector = (state: RootReducerState) => {
  return state.user.initiatedFromStorage
}

const userLastUpdatedSelector = (state: RootReducerState) => {
  return state.user.lastUpdated
}

const isNewPasswordValidSelector = (state: RootReducerState) => {
  return state.user.isNewPasswordValid || false
}

const registrationStatusSelector = (state: RootReducerState) => {
  return state.user.userRegistration || false
}

const pendingRegistrationStatusSelector = (state: RootReducerState) => {
  return state.user.pendingRegistration || false
}

const firstAvailableEmailContactSelector = (state: RootReducerState) => {
  const contacts = state.user?.details?.contact || []
  const firstContactWithValidEmail = contacts.find(c => c.email1)
  return firstContactWithValidEmail?.email1
}

const userEmailSelector = (state: RootReducerState) => {
  return state.user.details?.email1
}

const userDetailsSelector = (state: RootReducerState) => state.user.details

const userDetailsResponseStatusSelector = (state: RootReducerState) => state.user.isUserDetailsResponseStatusOk

const areUserDetailsLoadingSelector = (state: RootReducerState) => state.user.areDetailsLoading

const loginLoadingStatusSelector = (state: RootReducerState) => {
  return state.user.isLoading || false
}

const firstAvailableContactInformationSelector = (state: RootReducerState) => get(state.user, 'details.contact[0]')

const customerSegmentSelector = (state: RootReducerState) => get(state.user, 'details.x_data.customerSegments[0].name')

const userWalletSelector = (state: RootReducerState) => state.user.wallet

const activityIdSelector = (state: RootReducerState) => state.context?.activityToken?.activityId

const isPartiallyAuthenticatedSelector = (state: RootReducerState): boolean => state.context?.isPartiallyAuthenticated


export {
  loginStatusSelector,
  logoutProgressSelector,
  loginErrorMsgSelector,
  loginLoadingStatusSelector,
  guestStatusSelector,
  wcTokenSelector,
  wcTrustedTokenSelector,
  logonIdSelector,
  registrationStatusSelector,
  pendingRegistrationStatusSelector,
  userNameSelector,
  userIdSelector,
  userInitStatusSelector,
  userLastUpdatedSelector,
  forUserIdSelector,
  firstAvailableEmailContactSelector,
  userEmailSelector,
  userDetailsSelector,
  areUserDetailsLoadingSelector,
  firstAvailableContactInformationSelector,
  customerSegmentSelector,
  userWalletSelector,
  activityIdSelector,
  isNewPasswordValidSelector,
  userDetailsResponseStatusSelector,
  isPartiallyAuthenticatedSelector,
}
