import { CANADA_COUNTRY_CODE, EMPTY_STRING, REG_EX } from '../constants/common'
//Custom libraries
import { isArray } from 'lodash-es'
import { AddressFormData } from '../types/form'
import { AddressLine } from '../types/common'

const addressUtil = {
  optionalAddressFields: ['addressLine2', 'phone1', 'buzzerCode', 'apartmentCheck'],
  addressFormFields: [
    'firstName',
    'lastName',
    'addressLine1',
    'addressLine2',
    'city',
    'country',
    'state',
    'zipCode',
    'phone1',
    'nickName',
    'email1',
    'addressType',
    'buzzerCode',
    'apartmentCheck',
  ],
  addressDelimiter: '|',

  validateAddressForm: (formData: AddressFormData, edit?: boolean) => {
    const editVal = edit ? edit : false

    for (const key in formData) {
      if (!addressUtil.optionalAddressFields.includes(key)) {
        if (formData[key] !== undefined && formData[key].trim() === '') {
          return false
        }
      }
    }

    if (!addressUtil.validatePhoneNumber(formData.phone1)) {
      return false
    }
    if (!addressUtil.validateEmail(formData.email1)) {
      return false
    }
    if (!editVal && !addressUtil.validateNickName(formData.nickName)) {
      return false
    }

    return true
  },

  validatePhoneNumber: (phoneNumber: string | undefined) => {
    if (!phoneNumber) return false
    const PHONE = REG_EX.PHONE
    return phoneNumber === undefined || phoneNumber.trim() === '' || PHONE.test(phoneNumber.trim())
  },

  validateEmail: (email: string | undefined) => {
    const EMAIL = REG_EX.EMAIL
    if (!email) return false
    return email === undefined || email.trim() === '' || EMAIL.test(email)
  },

  validateNickName: (nickName: string | undefined) => {
    if (!nickName) return false
    const NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR = REG_EX.NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR
    return nickName === undefined || nickName.trim() === '' || NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR.test(nickName)
  },

  formatZipCodeForSAP: (shippingAddressData: AddressFormData): AddressFormData => {
    if (shippingAddressData && shippingAddressData.country === 'ca' && shippingAddressData.zipCode) {
      /*
        Canadian zip codes consist of 6 alpha-numeric characters with an optional
        space separating the first three characters from the remaning three.
        
        The regex that does form validation on FE supports both these formats,
        i.e. with and without space for the best user-experience.

        Regex from BE store configuration:
        ^(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\d(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\s{0,1}\\d(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\d$
        
        However, the SAP system that deals with shipping needs CA zip codes with space,
        so we add the space from here before sending the address to BE.
      */
      const zipCodeWithSpace = `${shippingAddressData.zipCode?.substring(
        0,
        3
      )} ${shippingAddressData.zipCode?.substring(shippingAddressData.zipCode.length - 3)}`.toUpperCase()
      const formattedAddress = {
        ...shippingAddressData,
        zipCode: zipCodeWithSpace,
      }
      return formattedAddress
    }
    return shippingAddressData
  },

  removeLeadingTrailingWhiteSpace: (formData: any): any => {
    const result: any = {}
    // remove leading and trailing white space from all form input fields.
    if (formData !== undefined && formData !== null) {
      Object.keys(formData).forEach(key => {
        let value
        if (isArray(formData[key])) {
          value = formData[key].map(val => {
            return typeof val === 'string' ? val.trim() : val
          })
        } else if (typeof formData[key] === 'string') {
          value = formData[key].trim()
        } else {
          value = formData[key]
        }
        result[key] = value
      })
    }
    return result
  },

  removeIgnorableAddressFormFields: (formData: any): any => {
    let result: any = { ...formData }
    for (let key in result) {
      if (!addressUtil.addressFormFields.includes(key)) {
        delete result[key]
      }
    }
    return result
  },

  getAddressNickName: (addressDataFields: string[]): string => {
    return addressDataFields.reduce((accumulator, currentValue, currentIndex) => {
      return `${accumulator}${currentIndex < addressDataFields.length - 2 ? `${currentValue}, ` : `${currentValue} `}`
    }, '')
  },

  concatAddress: (addressLineField: string[]): string => {
    return addressLineField.reduce((accumulator, currentValue) => {
      return `${accumulator}${currentValue}`
    }, '')
  },

  concatAddressWithDelimiter: (addressLine: AddressLine): string => {
    /**
     * The following three fields from Address Form are saved as one field on the BE:
     * - This is an apartment address, rural address or PO box (denoted by Checkbox column below)
     * - Apartment, suite, company, etc. (denoted by Apartment column below)
     * - Buzzer code (denoted by Buzzer column below)
     *
     * The values are concatenated using the below rules.
     * - If checkbox is checked, add a trailing |.
     * - If there is a buzzer code, add | before it.
     *
     * This results in concatenated values as shown below.
     * Y and N denote presence or absence of user-entered value.
     *
     * Apartment  Buzzer  Checkbox    Concatenated result
        Y          Y       Y           Unit|Buzzer|
        Y          Y       N           Unit|Buzzer
        Y          N       Y           Unit|
        N          Y       Y           |Buzzer|
        N          N       Y           |
        N          Y       N           |Buzzer
        Y          N       N           Unit
        N          N       N           <empty>
    */
    return (
      addressLine.addressLine2 +
      (addressLine.buzzerCode ? addressUtil.addressDelimiter : '') +
      addressLine.buzzerCode +
      (addressLine.apartmentCheck === true ? addressUtil.addressDelimiter : '')
    )
  },

  getAddressWithDelimiter: (addressLineField?: string): AddressLine => {
    const addressFields = addressLineField?.split(addressUtil.addressDelimiter)
    return {
      addressLine2: addressLineField?.startsWith(addressUtil.addressDelimiter) ? '' : addressFields?.[0] || '',
      buzzerCode: addressFields?.[1] || '',
      apartmentCheck: addressLineField?.endsWith(addressUtil.addressDelimiter) || false,
    }
  },
  addressIsSame: (address1: AddressFormData, address2: AddressFormData): boolean => {
    try {
      const isSameAddressLine = address1?.addressLine === address2?.addressLine
      const isSameCityAndZipCode = address1?.cityAndZipCode === address2?.cityAndZipCode
      return isSameAddressLine && isSameCityAndZipCode
    } catch (e) {
      return false
    }
  },
  sanitizeZipCodeChars:(charValue, position) => {
  switch(position){
    case 0:
    case 2:
    case 5: {
      return charValue.replace(/[^A-Za-z]/g, '')
    }
    case 1:
    case 4:
    case 6: {
        return charValue.replace(/[^0-9]/g, '')
      }
      case 3: return ' '
      default: return ''
  }

  },
  formatZipCode: (unformattedValue: string, storeCountry: string): string => {
  
    if (unformattedValue && CANADA_COUNTRY_CODE.toUpperCase() === storeCountry.toUpperCase()) {
      const ZIP_CODE_SEPARATOR_CA = ' '
      const ZIP_CODE_MAX_SIZE = 7
      let charValues = unformattedValue.replaceAll(ZIP_CODE_SEPARATOR_CA, EMPTY_STRING).split(EMPTY_STRING)
      
      if(charValues.length > 3){
        //only adds the space after the user inserted the fourth digit otherwise one cannot delete the blank space
        charValues = charValues.slice(0,3).concat(ZIP_CODE_SEPARATOR_CA).concat(charValues.slice(3,charValues.length))
      }

      for(let i = 0; i< charValues.length; i++){
        charValues[i] = addressUtil.sanitizeZipCodeChars(charValues[i],i)
      }

      let newValue = charValues.join(EMPTY_STRING)
      
      return newValue.substring(0, Math.min(newValue.length, ZIP_CODE_MAX_SIZE)).toUpperCase()
    }
    return unformattedValue
  },
}

export default addressUtil
