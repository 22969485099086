import { createApi } from '@reduxjs/toolkit/query/react'

import { axiosBaseQuery } from '@services/AxiosBaseQuery'

export interface IReorderState {
  orderId: string[],
  newOrderItemsCount: number,
  orderItemId: string[],
  viewTaskName: 'RedirectView' | ''
}

export interface ReorderRequestBody {
  orderId: string
  orderIdHashed: string
}

export const reorderApi = createApi({
  reducerPath: 'reorderApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/',
  }),
  tagTypes: ['Reorder'],
  endpoints: (builder) => ({
    reorderCart: builder.mutation<IReorderState, { storeId: string; body: ReorderRequestBody }>({
      query: ({storeId, body}) => ({
        url: `/store/${storeId}/reorder`,
        method: 'POST',
        body
      })
    }),
  }),
})

export const {
  useReorderCartMutation
} = reorderApi
