import { CART } from '@constants/routes'
import { transactionHost, urlList } from '../constants'
import { URLList, URLParams } from '../types'
import { localStorageUtil } from '@foundation/utils/storageUtil'
import { PERSONALIZATION_ID, WCToken, WCTrustedToken } from '@foundation/constants/user'

export const getFormattedUrl = (urlSelector: URLList, urlParams: URLParams) => {
  const rawUrl = urlList[urlSelector]
  const providedParams = Object.keys(urlParams)
  let finalUrl = rawUrl

  providedParams.map(param => {
    finalUrl = finalUrl.replace(`{${param}}`, urlParams[param])
  })

  return finalUrl
}

export const generateUrl = (urlSelector: URLList, params: Record<string, string>) => {
  const storeId = localStorageUtil.get('storeId')
  const urlDomain = urlSelector === 'getHCLUserSession' ? `${transactionHost}/wcs/resources/` : ''
  return urlDomain + getFormattedUrl(urlSelector, { storeId, ...params })
}

export const getHeaders = () => {
  const wcToken = localStorageUtil.get(WCToken)
  const wcTrustedToken = localStorageUtil.get(WCTrustedToken)
  const personalizationId = localStorageUtil.get(PERSONALIZATION_ID)

  return {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    WCPersonalization: personalizationId,
    WCToken: wcToken,
    WCTrustedToken: wcTrustedToken,
  }
}

export const clearOnBehalfStorage = () => {
  localStorage.clear()
  sessionStorage.clear()
}

export const reloadPage = (langCode: string, exitOnBehalfSession?: boolean) => {
  if (exitOnBehalfSession) {
    window.open(`/${langCode}`, '_self')
  } else {
    window.open(`/${langCode}/${CART}`, '_self')
  }
}
