import { AxiosError, AxiosRequestConfig, Method } from 'axios'
import RequestService, { RequestProps } from './RequestService'
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers'

export type CustomQueryReturnValue<T = any, E = unknown, M = unknown> =
  | {
      error: E
      meta?: M
    }
  | T

export type CustomBaseQueryFn<Args = any, Result = unknown, Error = unknown, DefinitionExtraOptions = {}, Meta = {}> = (
  args: Args,
  api: BaseQueryApi,
  extraOptions: DefinitionExtraOptions
) => MaybePromise<CustomQueryReturnValue<Result, Error, Meta>>

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): CustomBaseQueryFn<
    {
      url: string
      method: Method
      body?: RequestProps['body']
      extraParams?: RequestProps['extraParams']
      headers?: RequestProps['headers']
      pathParams?: RequestProps['pathParams']
      queryParams?: RequestProps['queryParams'] | AxiosRequestConfig['data']
    },
    any,
    AxiosError
  > =>
  async ({ body, url, method = 'get', queryParams, extraParams, pathParams }) => {
    try {
      const result = await RequestService.requestForRtk({
        body,
        method,
        path: baseUrl + url,
        queryParams,
        extraParams,
        pathParams,
      })
      return result
    } catch (e) {
      return {
        error: e,
        data: undefined,
      }
    }
  }
