//import * as CATALOG from './watchers/catalog'
import * as CONTEXT from './watchers/context'
import * as CONTRACT from './watchers/contract'
import * as ERROR from './watchers/error'
import * as ORDERDETAILS from './watchers/orderDetails'
import * as ORGANIZATION from './watchers/organization'
import * as USER from './watchers/user'

//Standard libraries
import { all } from 'redux-saga/effects'

/**
 * Root Saga
 */
export default function* rootSaga() {
  yield all([
    //CATALOG.watchSaga(),
    ERROR.watchSaga(),
    USER.watchSaga(),
    CONTRACT.watchSaga(),
    CONTEXT.watchSaga(),
    ORGANIZATION.watchSaga(),
    ORDERDETAILS.watchSaga(),
  ])
}
