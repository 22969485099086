//Standard libraries
import { call, put } from 'redux-saga/effects'
import { chunk } from 'lodash-es'
//Foundation libraries
import orderService from '../../../foundation/apis/transaction/order.service'
import productsService from '../../../foundation/apis/search/products.service'
//Redux
import {
  FETCH_ORDER_DETAILS_SUCCESS_ACTION,
  FETCH_ORDER_ITEM_DETAILS_SUCCESS_ACTION,
  FETCH_ORDER_ITEM_DETAILS_FAIL_ACTION,
  FETCH_ORDER_DETAILS_FAIL_ACTION,
  SET_ORDER_DETAILS_LOADINGS_ACTION,
  RESET_INVALID_ORDERS_ACTION,
  ADD_FRAME_ROX_ORDER_DETAILS_ACTION,
} from '../../actions/orderDetails'
import { IOrderDetails, OrderItem } from '../../../types/order'
import { IProduct } from '../../../types/product'

export function* getOrderDetails(action) {
  const { orderId, storeId, currency, contractId, skipErrorSnackbar, widget } = action.payload
  try {
    yield put(SET_ORDER_DETAILS_LOADINGS_ACTION(orderId))
    const orderDetails: IOrderDetails = yield call(orderService.findByOrderId, {
      orderId,
      storeId,
      queryParams: { currency, contractId, skipErrorSnackbar, widget },
    })
    yield put(FETCH_ORDER_DETAILS_SUCCESS_ACTION(orderDetails))
    try {
      // check if order already has product details
      let orderItemDetails: IProduct[] = []

      if (orderDetails.x_data?.productDetails?.length === +orderDetails.recordSetCount) {
        orderItemDetails = orderDetails.x_data.productDetails
      } else {
        const orderItems: OrderItem[] = orderDetails.orderItem
        const id = [...new Set(orderItems.map(i => i.productId))]
        const itemDetailsParams = {
          id,
          currency,
          contractId,
        }

        orderItemDetails = yield call(fetchOrderItemDetailsByIds, itemDetailsParams)
      }

      yield put(
        FETCH_ORDER_ITEM_DETAILS_SUCCESS_ACTION({
          orderId,
          items: orderItemDetails,
        })
      )
    } catch (error) {
      yield put(FETCH_ORDER_ITEM_DETAILS_FAIL_ACTION({ orderId, error }))
    }
  } catch (error) {
    yield put(FETCH_ORDER_DETAILS_FAIL_ACTION({ orderId, error }))
  }
}

export const fetchOrderItemDetailsByIds = (param: any) => {
  const promiseArray: Promise<any>[] = []
  const { currency, id, widget, profileName } = param
  const paramBase = { currency, widget, profileName }
  const ids = chunk(id, 50)
  ids.forEach(id => {
    const param = Object.assign({}, paramBase, { id })
    promiseArray.push(
      productsService.findProductsUsingGET({
        ...param,
        profileName: 'LX_findItemByIds_Details',
      })
    )
  })
  return Promise.all(promiseArray).then(rs => {
    let contents = []
    rs.forEach(r => {
      if (r.data?.contents) {
        contents = contents.concat(r.data.contents)
      }
    })
    return contents
  })
}

export function* getInvalidOrderDetails(action: any) {
  const payload = action.payload
  yield put(ADD_FRAME_ROX_ORDER_DETAILS_ACTION(payload))
}

export function* resetInvalidOrders() {
  yield put(RESET_INVALID_ORDERS_ACTION())
}
