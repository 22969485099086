export const MISC_2_ICONS = {
  fileName: '/images/icons/misc2-sprite.svg',
  icons: [
    'green-shipping',
    'luxury-glasses',
    'magnifying-glass',
    'mask',
    'oakley-glass',
    'offer',
    'optician',
    'optometrist',
    'padlock',
    'pilot-dark-glasses',
    'pilot-glasses-meter',
    'prescription',
    'rayban-glass',
    'retinal-screening',
    'replace',
    'smooth-rectangle-glasses',
    'social-distance',
    'store-manager',
    'temple-size',
    'test',
    'text-module-eyeglasses',
    'call',
    'mail',
    'customer-care',
    'price-match-guarantee',
    'polarized',
    'derek-cardigan',
    'main-central',
    'reincarnate',
    'perspective',
    'kam-dhillon',
    'joseph-marc',
  ],
} as const
