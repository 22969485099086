import { createSlice } from '@reduxjs/toolkit'

export interface PlpStateInterface {
  /*  productList: IProduct[] //Stores list of product objects for the current category/search
  productListTotal: number //Stores total number of products in the current category/search
  priceMode: string //Price mode returned from catalog services
  facets: any[] | null //Stores list of all facet objects (including price facet) associated to the current category/search
  baseFacets: any[] | null
  facetPrice: any //Stores only the price facet object associated to the current category/search
  selectedFacets: {
    [key: string]: { value: string; facetName: string }
  } //Stores map of selected facets for the current category/search. Format is "[facetEntryValue]: facetEntryLabel"
  selectedFacetLimits: string[] //Stores list of selected facet limit values for the current category/search. Format is "facetvalue:-1"
  selectedFacetOffer: string
  selectedFacetPrices: {
    min: number
    max: number
  } //Stores the selected min/max price range for the current category/search. Persisted values should always be valid
  selectedPageOffset: number //Stores the selected pagination offset value for the current category/search
  defaultSortOption: SingleSortOrderOption
  selectedSortOption: SingleSortOrderOption //Stores the selected sort option value for the current category/search
  profileName: string | null
  breadcrumbs: any[]
  loading?: boolean
  facetsUpdating?: boolean*/
  facets: any[]
  breadcrumbs: any[]
  selectedFacets: {
    [key: string]: { value: string; facetName: string }
  }
  //productsMobileListingLayout?: 'compact' | 'full'
}

export const initialPlpState: PlpStateInterface = {
  /*  defaultSortOption: {
    value: '',
    profileName: 'LX_findProductsByCategory_Grouping',
  },
  baseFacets: null,
  productList: [],
  productListTotal: -1,
  priceMode: '1',
  facets: null,
  facetPrice: null,
  selectedFacets: {},
  selectedFacetLimits: [],
  selectedFacetOffer: '',
  selectedFacetPrices: { min: -1, max: -1 },
  selectedPageOffset: 1,
  selectedSortOption: {
    value: '',
    profileName: 'LX_findProductsByCategory_Grouping',
  },
  breadcrumbs: [],
  profileName: null,
  loading: false,
  facetsUpdating: false,*/
  facets: [],
  breadcrumbs: [],
  selectedFacets: {},
  //productsMobileListingLayout: 'compact',
}

export const {
  //actions: { setSelectedSortOption, setDefaultSortOption },
  reducer,
} = createSlice({
  name: 'plp',
  initialState: initialPlpState,
  reducers: {
    /* setSelectedSortOption: (
      state: PlpStateInterface,
      action: PayloadAction<any>
    ) => {
      state.selectedSortOption = action.payload
    },
    setDefaultSortOption: (
      state: PlpStateInterface,
      action: PayloadAction<any>
    ) => {
      state.defaultSortOption = action.payload
    },*/
  },
})

export default reducer
