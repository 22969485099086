import Head from 'next/head'
import { useTranslation } from 'next-i18next'

import config from '@configs/index'

export const MainHead: React.FC<{ damDomain: string | undefined; cmsImageServerUrlPreconnect: string }> = ({
  damDomain,
  cmsImageServerUrlPreconnect,
}) => {
  const { t } = useTranslation()

  return (
    <Head>
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />

      <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      <link rel="apple-touch-icon" sizes="57x57" href="/apple-touch-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/apple-touch-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon-76x76.png" />
      <link rel="apple-touch-icon" sizes="114x114" href="/apple-touch-icon-114x114.png" />
      <link rel="apple-touch-icon" sizes="120x120" href="/apple-touch-icon-120x120.png" />
      <link rel="apple-touch-icon" sizes="144x144" href="/apple-touch-icon-144x144.png" />
      <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon-152x152.png" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon-180x180.png" />
      <link rel="preconnect" href="//tags.tiqcdn.com" />
      <link rel="preconnect" href={cmsImageServerUrlPreconnect} />
      <link rel="preconnect" href={damDomain || config.defaultDamDomain} />

      <link rel="manifest" href="/manifest.json" />

      <script id="service-worker" dangerouslySetInnerHTML={{__html: `
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker
            .register('/sw.js')
            .then(function (registration) {
              console.log('Service Worker successfully registered:', registration)
            })
            .catch(function (error) {
              console.error('Error registering the Service Worker:', error)
            })
        } else {
          console.log('Service workers are not supported in this browser.')
        }
      `}} />

      <title>{t('CommerceEnvironment.Title')}</title>
      <meta name="description" content={t('CommerceEnvironment.Description')} />

      <meta name="theme-color" content="#ffffff" />

      <link href="/fonts/Mulish-Regular.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
      <link href="/fonts/Mulish-Bold.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
    </Head>
  )
}
