//Redux
import * as ACTIONS from '../../action-types/user'

import { EXPIRED_PASSWORD_PAGE_ERROR, WC_PREVIEW_TOKEN } from '../../../foundation/constants/common'
import {
  FETCH_USER_DETAILS_SUCCESS_ACTION,
  GUEST_LOGIN_SUCCESS_ACTION,
  INIT_USER_FROM_STORAGE_SUCCESS_ACTION,
  LOGIN_SUCCESS_ACTION,
  LOGON_AND_CHANGE_PASSWORD_FAIL_ACTION,
  LOGOUT_SUCCESS_ACTION,
  REGISTRATION_PENDING_ACTION,
  REGISTRATION_SUCCESS_ACTION,
  SESSION_ERROR_LOGIN_ERROR_ACTION,
  FETCH_USER_WALLET_SUCCESS_ACTION,
  USER_DETAILS_RESPONSE_STATUS_ACTION,
} from '../../actions/user'
//Standard libraries
import { call, put, select } from 'redux-saga/effects'
import { localStorageUtil, storageSessionHandler } from '../../../foundation/utils/storageUtil'
import { sendRegistrationEvent } from '../../../foundation/analytics/tealium/lib'
import { AppPayloadWithWidgetAction } from '../../store'
import Log from '../../../services/Log'
//Foundation libraries
import { PERSONALIZATION_ID } from '../../../foundation/constants/user'
import { USER_CONTEXT_REQUEST_ACTION } from '../../actions/context'
import loginIdentity from '../../../foundation/apis/transaction/loginIdentity.service'
import personService from '../../../foundation/apis/transaction/person.service'
import { userLastUpdatedSelector } from '../../selectors/user'
import { AxiosResponse } from 'axios'
import { PersonResponse, PersonWalletResponse } from '../../../types/user'
import get from 'lodash/get'
import personWalletService from '@foundation/apis/transaction/person.wallet.service'
import { RX_PRESCRIPTION_OBJECT_KEY } from '@constants/rxConfigurator'
import { getNewsletterPrivacyFlag } from '@features/newsletter/query'
import { convertToLocale } from '@utils/common'

/**
 *
 */
function* loginAndFetchDetail(payload: any) {
  const response = yield call(loginIdentity.login, payload)

  // ensure subsequent context request has storeId parameter
  // otherwise the request will fail and F/E will not receive
  // the correct session id
  const loginPayload = {
    ...response.data,
    storeId: payload.storeId,
  }

  if (payload?.widget) {
    loginPayload['widget'] = payload.widget
  }
  yield put(LOGIN_SUCCESS_ACTION(loginPayload))

  const response2: AxiosResponse<PersonResponse> = yield call(personService.findPersonBySelf, {
    widget: payload.widget,
    storeId: payload.storeId,
  })
  const loginPayload2 = response2.data

  yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(loginPayload2))

  try {
    yield call(getNewsletterPrivacyFlag, {
      EmailAddress: loginPayload2.email1,
      storeId: payload.storeId,
      locale: convertToLocale(loginPayload2.preferredLanguage),
    })
  } catch (error) {
    Log.warn('Wunderkind login sync has failed')
  }
}

const preProcessLogonAndChangePasswordError = (error: any) => {
  if (error?.isAxiosError && error.response?.data?.errors && error.response.data.errors[0]) {
    return {
      ...error.response.data.errors[0],
      [EXPIRED_PASSWORD_PAGE_ERROR]: true,
    }
  } else {
    return {
      errorMessage: error.toLocaleString(),
      [EXPIRED_PASSWORD_PAGE_ERROR]: true,
    }
  }
}

/**
 *
 */
export function* logonAndChangePassword(action: any) {
  try {
    const payload = action.payload
    yield* loginAndFetchDetail(payload)
  } catch (error) {
    yield put(LOGON_AND_CHANGE_PASSWORD_FAIL_ACTION(preProcessLogonAndChangePasswordError(error)))
  }
}

/**
 *
 */
export function* login({ payload }: any) {
  try {
    yield* loginAndFetchDetail(payload)
  } catch (error) {
    yield put({ type: ACTIONS.LOGIN_ERROR, error })
  }
}

/**
 *
 */
export function* sessionErrorReLogin(action: any) {
  try {
    const payload = action.payload
    storageSessionHandler.removeCurrentUser()
    yield* loginAndFetchDetail(payload)
  } catch (error: any) {
    if (error && error.response && error.response.data && error.response.data.errors && error.response.data.errors[0]) {
      yield put(SESSION_ERROR_LOGIN_ERROR_ACTION(error.response.data.errors[0]))
    }
  }
}

export function* fetchUserDetails(action: AppPayloadWithWidgetAction) {
  try {
    const payload = action.payload

    const response: AxiosResponse<PersonResponse> = yield call(personService.findPersonBySelf, payload)

    yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(response.data))
  } catch (error) {
    Log.error('error: ' + error)
  }
}

export function* fetchUserWallet(action: AppPayloadWithWidgetAction) {
  try {
    const payload = action.payload
    const response: AxiosResponse<PersonWalletResponse> = yield call(personWalletService.getWalletBySelf, payload)
    yield put(FETCH_USER_WALLET_SUCCESS_ACTION(response.data))
  } catch (error) {
    Log.error('error: ' + error)
  }
}

/**
 *
 */
export function* logout(action: any) {
  const payload = action.payload
  try {
    yield call(loginIdentity.logout, payload)
    yield put(LOGOUT_SUCCESS_ACTION(payload))
  } catch (error) {
    yield put({ type: ACTIONS.LOGOUT_ERROR, error })
    //still need to clear user token, event though logout fail to avoid infinite loop
    yield put(LOGOUT_SUCCESS_ACTION(payload))
  }
}

/**
 *
 */
export function* registration(action: any) {
  try {
    yield put(REGISTRATION_PENDING_ACTION({ isRegistrationPending: true }))
    const payload = action.payload
    const response = yield call(personService.registerPerson, payload)
    const registrationPayload = response.data
    if (payload?.widget) {
      registrationPayload['widget'] = payload.widget
    }
    yield put(REGISTRATION_SUCCESS_ACTION(registrationPayload))
    const acceptedNewsletterFlag = get(payload.body, 'x_optIn.x_newsLetter', false)
    const userHasPrescriptions = localStorageUtil.get(RX_PRESCRIPTION_OBJECT_KEY) ? '1' : '0'
    setTimeout(() => {
      sendRegistrationEvent(
        payload.body.email1,
        registrationPayload.userId,
        payload.body.country,
        userHasPrescriptions,
        acceptedNewsletterFlag
      )
    }, 3e3)

    const response2 = yield call(personService.findPersonBySelf, {
      widget: payload.widget,
      storeId: payload.storeId,
    })

    const registrationPayload2 = response2.data
    yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(registrationPayload2))
  } catch (error) {
    yield put({ type: ACTIONS.REGISTRATION_ERROR, error })
  }
}

/**
 *
 */
export function* initStateFromStorage(action: AppPayloadWithWidgetAction) {
  Log.info('INIT STATE FROM STORAGE')
  try {
    let currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
    if (currentUser === null) {
      // if we have both previewtoken and newPreviewSession, the current user is removed in inistates.ts
      // then we should get new personalizationID from preview session
      const previewToken = storageSessionHandler.getPreviewToken()
      if (!previewToken || !previewToken[WC_PREVIEW_TOKEN]) {
        const personalizationID = localStorageUtil.get(PERSONALIZATION_ID)
        if (personalizationID !== null) {
          currentUser = { personalizationID }
        }
      }
    }
    yield put(INIT_USER_FROM_STORAGE_SUCCESS_ACTION(currentUser))
    if (currentUser && currentUser.WCToken) {
      const response2 = yield call(personService.findPersonBySelf, {
        ...action.payload,
      })
      const loginPayload2 = response2.data
      yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(loginPayload2))
      yield put(USER_DETAILS_RESPONSE_STATUS_ACTION(true))
    }
    yield put(USER_CONTEXT_REQUEST_ACTION({ ...action.payload }))
    //yield put(ENTITLED_ORG_ACTION({ ...action.payload }))
    //yield put(FETCH_CONTRACT_REQUESTED_ACTION({ ...action.payload }))
  } catch (e) {
    Log.error('error: ' + e)
    yield put(USER_DETAILS_RESPONSE_STATUS_ACTION(false))
  }
}

/**
 *
 */
export function* updateStateFromStorage() {
  try {
    const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
    if (currentUser && currentUser.forUserId) {
      return
    }
    const userLastUpdated = yield select(userLastUpdatedSelector)
    if (currentUser && currentUser.lastUpdated && (!userLastUpdated || userLastUpdated < currentUser.lastUpdated)) {
      yield put(INIT_USER_FROM_STORAGE_SUCCESS_ACTION(currentUser))
      if (currentUser.isGuest) {
        yield put(GUEST_LOGIN_SUCCESS_ACTION(null))
      } else {
        yield put(LOGIN_SUCCESS_ACTION(null))
      }
    }
  } catch (e) {
    Log.error('error: ' + e)
  }
}
