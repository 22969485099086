export const GLOBAL_ICONS = {
  fileName: '/images/icons/global-sprite.svg',
  icons: [
    'account-user',
    'account-user-logged',
    'calendar',
    'calendar-thin',
    'cart',
    'search',
    'close-search',
    'question-mark',
    'find-store',
    'bag-checkout',
    'hamburger-menu',
    'logout',
    'login',
    'look',
    'stores',
    'call-alt',
    'chat-alt',
    'mail-alt',
    'dot',
    'locator',
    'geoposition',
    'checked-radio',
    'frame-genius',
    'frame-advisor-frame-size',
    'eye',
    'plus-circle',
    'minus-circle',
    'unsuitable-driving',
    'camera',
    'checkmark-outlined',
    'indeterminate-checkbox',
    'bag',
  ],
} as const
