import debug from 'debug'

const BASE = 'dcw-app'

export type ErrorLevel = 'trace' | 'info' | 'warn' | 'error'

const COLOURS: Record<ErrorLevel, string> = {
  trace: 'lightblue',
  info: 'blue',
  warn: 'orange',
  error: 'red',
}

const NO_MESSAGE = 'No message available'

/** Generate a debug message */
export const generateMessage = (
  // TODO: generate and send two separate func
  level: ErrorLevel,
  origin_url = '',
  message?: ErrorInput,
  source?: string
) => {
  // Set the prefix which will cause debug to enable the message
  const namespace = `${BASE}:${level}`
  const createDebug = debug(namespace)

  if (createDebug.enabled) {
    // Set the colour of the message based on the level
    createDebug.color = COLOURS[level]

    if (source) {
      createDebug(source, origin_url, message)
    } else {
      createDebug(origin_url, message)
    }
  }
}

/**
 * How to use:
 *  import Log from './libs/Log'
 *  Log.info('AAA', false, 'window.location.href','app.js')
 */

export type ErrorInput = { message: string } | string

class Log {
  static trace(message: ErrorInput, origin_url?: string, source?: string) {
    // TODO: use better interface to describe message
    return generateMessage('trace', origin_url, message, source)
  }

  static info(message: ErrorInput, origin_url?: string, source?: string) {
    return generateMessage('info', origin_url, message, source)
  }

  static warn(message: ErrorInput, origin_url?: string, source?: string) {
    return generateMessage('warn', origin_url, message, source)
  }

  static error(message: ErrorInput, origin_url?: string, source?: string) {
    return generateMessage('error', origin_url, message, source)
  }
}

export default Log
