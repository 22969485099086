import { CHECKOUT_STEPS } from './common'

export const BASE_COUNTRY_KEY = 'country'
export const BASE = `/:${BASE_COUNTRY_KEY}`
export const HOME = '/'
export const CMSPREVIEW = '/cmpreview'
export const NOT_FOUND = '/not-found-page'

//Checkout
export const CHECKOUT = 'checkout'
export const CHECKOUT_CHILDREN = {
  SHIPPING: 'shipping',
  PAYMENT: 'payment',
  REORDER_SUMMARY: 'reorder-summary',
  ORDER_CONFIRMATION: 'order-confirmation',
  UPLOAD_PRESCRIPTION: 'upload-prescription',
}

//register user access only
export const REGISTER_PROTECTED = 'register'
export const REGISTER_PROTECTED_ROUTES = ['account']

//Order
export const CART = 'cart'
export const MAINTENANCE = 'maintenance'

export const CHECKOUT_SHIPPING = `${CHECKOUT}/${CHECKOUT_STEPS.SHIPPING}`
export const CHECKOUT_PAYMENT = `${CHECKOUT}/${CHECKOUT_STEPS.PAYMENT}`
export const CHECKOUT_REVIEW = `${CHECKOUT}/${CHECKOUT_STEPS.REVIEW}`
export const CHECKOUT_REORDER_SUMMARY = `${CHECKOUT}/${CHECKOUT_STEPS.REORDER_SUMMARY}`
export const ORDER_CONFIRMATION = `${CHECKOUT}/${CHECKOUT_STEPS.ORDER_CONFIRMATION}`
export const CHECKOUT_UPLOAD_PRESCRIPTION = `${CHECKOUT}/${CHECKOUT_STEPS.UPLOAD_PRESCRIPTION}`
export const WISHLIST = 'wishlist'
export const FAQ = 'c/customer-service/help'
export const PRESCRIPTION_VERIFICATION = 'upload-prescription'
export const FRENCH_CA_LOCALE = 'fr-ca'
export const STORELOCATOR = 'store-locator'
export const STORELOCATOR_FR = 'boutiques'

// RETURNS
export const ORDER_RETURN = 'return'
export const ORDER_RETURN_SELECT = 'select'
export const ORDER_RETURN_CONFIRMATION = 'confirmation'
export const ORDER_RETURN_PRINT = 'print'

//Account
export const SIGNIN = 'sign-in'
export const SIGNUP = 'sign-up'
export const FORGOT_PASSWORD = 'forgot-password'
export const ACCOUNTWITHOUTPARAM = 'account'
export const ACCOUNT = 'account'
export const ACCOUNT_CHILDREN = {
  DASHBOARD: 'dashboard',
  ADDRESS_BOOK: 'address-book',
  ORDER_HISTORY: 'order-history',
  MY_CLAIMS: 'my-claims',
  PERSONAL_INFORMATION: 'personal-information',
  PAYMENT_METHODS: 'payment-methods',
  STORES: 'stores',
  FAVOURITES: 'wishlist',
  PRESCRIPTIONS: 'prescriptions',
  ORDER_REMINDER: 'order-reminder',
  SUBSCRIPTIONS: 'subscriptions',
}

//Search
export const SEARCH = '/search'

//Services
export const CONTACT_US = '/contact-us'
export const CUSTOMER_SERVICES_CONTACT_US = 'c/customer-service/contact-us'
export const CUSTOMER_SERVICES_TRACK_ORDER = 'c/customer-service/track-order'
export const SITEMAP = 'sitemap'

//only guest and generic user access
export const GENERIC_PROTECTED = 'generic'

export const CMS_PAGES_URL_PREFIX = 'c/'
export const CMS_PAGE_NAME_LV1 = '/:pageNameLv1'
export const CMS_PAGE_NAME_LV2 = '/:pageNameLv2'
export const CMS_PAGE_NAME_LV3 = '/:pageNameLv3'
export const CMS_PAGE_NAME_LV4 = '/:pageNameLv4'
export const CMS_PAGE_ROUTE = `c${CMS_PAGE_NAME_LV1}`

export const BOARDS_DEMO = '/boards-demo'

// Clearly
export const STYLEGUIDE = {
  INDEX: 'styleguide',
  GRID: 'grid',
  NOPAGE: '*',
}

//CMS Demo page
export const BOX_WITH_WITHOUT_MARGIN = '/box-with-without-margin'
export const BOX_WITHOUT_MARGIN = '/box-without-margin'
export const MISC_ICONS = '/misc-icons'
export const CAROUSEL = '/carousel'
export const TEXT_MODULE_1_DEFAULT = '/text-module-1-demo'
export const CMS_FOUR_PRODUCT_CAROUSEL = '/cms/four-product-carousel-slide'
export const GRID_OF_PRODUCTS_DEMO = '/grid-of-products-demo'
export const VIDEO_IMAGE_DEMO = '/video-image-demo'
export const PDP_Banner = '/pdp-banner'
export const CMS_FOUR_PRODUCT_CAROUSEL_TILE = '/cms/four-product-carousel-tile'
export const THREE_PRODUCT_CAROUSEL = '/cms/three-product-carousel'
export const PDP_STICKY_BAR = 'pdp-sticky-bar'
export const CUSTOM_DIALOG = '/custom-dialog'
