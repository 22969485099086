export const ARROW_ICONS = {
  fileName: '/images/icons/arrow-sprite.svg',
  icons: [
    'arrow-slim-down',
    'arrow-down',
    'arrow-left',
    'arrow-right',
    'arrow-up',
    'unicode-arrow-left',
    'unicode-arrow-right',
  ],
} as const
