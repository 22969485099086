import React, { useEffect } from 'react'
import { IOrderDetails, OrderItem } from '../../types/order'
import { WunderkindLocales } from './types'
import config from '@configs/index'

const WUNDERKIND_GOAL = 'purchase'

const convertToWunderkindStoreKey = (str: string) => {
  return str.toLowerCase().replace('-', '_')
}

export const isWunderkindEnabledForLocale = (localeCode: WunderkindLocales | string) => {
  if (!config?.wunderkindEnabled) return false
  if (typeof localeCode === 'string') {
    return config?.wunderkindAvailableLocales.includes(localeCode as WunderkindLocales)
  }
  return config?.wunderkindAvailableLocales.includes(localeCode)
}

interface WunderkindConversionMultiPixelProps {
  orderDetails: IOrderDetails
  orderItem: OrderItem
  langCode: WunderkindLocales | string
}
export const WunderkindConversionMultiPixel: React.FC<WunderkindConversionMultiPixelProps> = ({
  orderDetails,
  orderItem,
  langCode,
}) => {
  if (!orderDetails || !orderItem) return null

  const { orderId, grandTotalCurrency, grandTotal } = orderDetails
  const { email1 } = orderItem

  const imgSrc =
    orderDetails && orderItem && isWunderkindEnabledForLocale(langCode)
      ? `//api.bounceexchange.com/capture/convert2.gif?website_id=${
          config.wunderkindStoreKeys[convertToWunderkindStoreKey(langCode)]
        }&order_id=${orderId}&email=${email1}&amount=${grandTotal}&currency=${grandTotalCurrency}&goal=${WUNDERKIND_GOAL}`
      : null

  return <WunderkindImage imgSrc={imgSrc} />
}

interface WunderkindImageProps {
  imgSrc: string | null
}
export const WunderkindImage: React.FC<WunderkindImageProps> = ({ imgSrc }) => {
  useEffect(() => {
    const id = 'wunderkind-multi-pixel'
    if (!imgSrc || document.getElementById(id)) return

    const img = document.createElement('img')
    img.id = id
    img.height = 1
    img.width = 1
    img.style.border = '0'
    img.style.display = 'none'
    img.src = imgSrc
    document.body.insertBefore(img, document.body.firstChild)

    return () => {
      if (document.body.contains(img)) {
        document.body.removeChild(img)
      }
    }
  }, [imgSrc])

  return null
}

interface WunderkindConversionScriptProps {
  document: Document
  langCode: WunderkindLocales | string
  orderDetails: IOrderDetails
  orderItem: OrderItem
}
export const addWunderkindConversionScript = ({
  document,
  langCode,
  orderDetails,
  orderItem,
}: WunderkindConversionScriptProps) => {
  const id = 'wunderkind-conversion-script'
  if (!orderDetails || !orderItem || !isWunderkindEnabledForLocale(langCode) || document.getElementById(id)) return

  const {
    orderId,
    grandTotalCurrency,
    totalAdjustment,
    totalSalesTax,
    totalShippingCharge,
    grandTotal,
    x_data,
    paymentInstruction,
    promotionCode,
  } = orderDetails
  const { email1, phone1, country } = orderItem
  const coupon = promotionCode ? JSON.stringify(promotionCode) : '[]'
  const items = x_data?.productDetails ? JSON.stringify(x_data?.productDetails) : '[]'

  const scriptContent = `window.top.bouncex = window.top.bouncex || [];
  window.top.bouncex.push(["conversion", {
    "order_id": "${orderId}",
    "email": "${email1}",
    "phone": "${phone1}",
    "goal": "${WUNDERKIND_GOAL}",
    "transaction_origin": "${country}",
    "currency": "${grandTotalCurrency}",
    "coupon": ${coupon},
    "total_discount": "${totalAdjustment}",
    "tax": "${totalSalesTax}",
    "shipping": "${totalShippingCharge}",
    "amount": "${grandTotal}",
    "pay_method": "${
      paymentInstruction && paymentInstruction.length ? paymentInstruction[0]?.payMethodId : 'undefined'
    }",
    "item": ${items}
  }]);`

  const script = document.createElement('script')
  script.id = id
  script.type = 'text/javascript'
  script.text = scriptContent
  script.async = true

  document.head.insertBefore(script, document.head.firstChild)
}

interface WunderkindSmartTagProps {
  document: Document
  langCode: WunderkindLocales
}
export const addWunderkindSmartTag = ({ document, langCode }: WunderkindSmartTagProps) => {
  const id = 'wunderkind-smart-tag'
  if (!isWunderkindEnabledForLocale(langCode) || document.getElementById(id)) return

  const script = document.createElement('script')
  script.id = id
  script.type = 'text/javascript'
  script.text = `(function(d) {
    var e = d.createElement('script');
    e.src = d.location.protocol + '//tag.wknd.ai/${
      config.wunderkindStoreKeys[convertToWunderkindStoreKey(langCode)]
    }/i.js';
    e.async = true;
    d.getElementsByTagName('head')[0].appendChild(e);
  }(document));`
  script.async = true

  document.head.insertBefore(script, document.head.firstChild)
}
