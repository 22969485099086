export const MISC_1_ICONS = {
  fileName: '/images/icons/misc1-sprite.svg',
  icons: [
    'face-glasses-smile',
    'face-oval',
    'face-oval-thick',
    'face-rectangle',
    'face-rectangle-slim',
    'face-rectangle-thick',
    'face-round',
    'face-round-thick',
    'face-slim',
    'face-square',
    'face-triangle',
    'transitions-seamless',
    'transitions-protection',
    'transitions-style',
    'clearly-basics',
    'clearly-gamer',
    'clearly-junior',
    'address',
    'after-care',
    'baby-smile',
    'chat',
    'free-return',
    'choose',
    'clock',
    'contact-lens-finger',
    'credit-card',
    'drop',
    'eyewear-range',
    'global-care',
  ],
} as const
