import config from '@configs/index'
import { FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { withCredentialsEnabled } from '@utils/helpers'
import qs from 'qs'
import { TLightProduct, TLightProductRes, lightHclDTO } from '@utils/productNew'
import { RootReducerState } from '@redux/rootReducer'

type TLightProductArgs = {
  partnumber: (string | number)[]
  profile: string
}

export const bffProductApi = createApi({
  reducerPath: 'bffProductApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.cmsApiUrl,
    paramsSerializer: params => {
      if (!params) {
        return ''
      }
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
    ...(withCredentialsEnabled() ? { credentials: 'include' } : {}),
  }),
  endpoints: builder => ({
    getProductsByPartnumber: builder.query<Record<string, TLightProduct>, TLightProductArgs>({
      async queryFn(args, queryApi, _extraOptions, baseQuery) {
        const state = queryApi.getState() as RootReducerState
        if (!state.site.currentSite) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: 'Site state has not been initialized',
            },
          }
        }
        const { storeID, defaultLanguageID, catalogID } = state.site.currentSite

        const result = await baseQuery({
          url: 'wcs/productinfo',
          params: {
            storeId: storeID,
            langId: defaultLanguageID,
            catalogId: catalogID,
            brand: config?.storePrefix,
            partnumber: args['partnumber'],
            profile: args['profile'],
          },
        })

        return (result.data as Record<string, TLightProduct>)
          ? { data: resultMapper(result.data as Record<string, TLightProductRes>) }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
  }),
})

const resultMapper = (data: Record<string, TLightProductRes>): Record<string, TLightProduct> => {
  return Object.keys(data).reduce((acc, key) => {
    acc[key] = lightHclDTO(data[key])
    return acc
  }, {})
}

export const { useGetProductsByPartnumberQuery } = bffProductApi
