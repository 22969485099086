import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import { GoogleAPIEnabledInterface } from '../../types/googleAPI'
import { ParsedServiceLinks, RootReducerState } from '@redux/rootReducer'

//Redux

// export const parseAddressFieldsArray = (jsonStringFields?: string): CheckoutAddressFormFields[] => {
//   if (!jsonStringFields || typeof jsonStringFields !== 'string') {
//     return []
//   }

//   const formattedJsonStringFields = jsonStringFields.replaceAll('\\', '\\\\')

//   let parsedAddressFields = {}

//   try {
//     parsedAddressFields = JSON.parse(formattedJsonStringFields)
//   } catch (e) {
//     Log.error('error in parsing address fields from BE xstoreconf: ' + jsonStringFields)
//   }

//   return Array.isArray(parsedAddressFields)
//     ? parsedAddressFields.map((f) => ({ ...f, mandatory: f.mandatory === 'true' }))
//     : []
// }

export const siteSelector = (state: RootReducerState) => state.site.currentSite

export const storeIdSelector = (state: RootReducerState) => state.site.currentSite?.storeId

export const hostnameUrlsSelector = (state: RootReducerState) => state.site.hostnameUrls

export const hostnameSelector = (state: RootReducerState) => state.site.hostname

export const pdpFrameImageOrderSelector = (state: RootReducerState) => {
  return state.site.currentSite?.xStoreCfg?.pdpFrameImageOrder
}

export const pdpAccImageOrderSelector = (state: RootReducerState) => {
  return state.site.currentSite?.xStoreCfg?.pdpAccImageOrder
}

export const pdpClImageOrderSelector = (state: RootReducerState) => {
  return state.site.currentSite?.xStoreCfg?.pdpClImageOrder
}

export const availablePaymentMethodsSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.availablePaymentMethods || []

export const defaultShippingMethodNameSelector = (state: RootReducerState) => {
  const sortBySequence = cloneDeep(state.site.currentSite?.xStoreCfg?.availableShippingMethods)?.sort(
    (a, b) => Number(a.sequence) - Number(b.sequence)
  )
  return get(sortBySequence, '[0].name')
}

export const sortOrderOptionsSelector = (state: RootReducerState) => state.site.currentSite?.xStoreCfg?.sortorderOptions

export const plpDashBtnSelector = (state: RootReducerState) => state.site.currentSite?.xStoreCfg?.plpDashButtonsFacet

export const plpRadioButtonFacetsSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.plpRadioButtonFacets

export const plpDashFacetSelector = (state: RootReducerState) => state.site.currentSite?.xStoreCfg?.plpDashfacet

export const plpFacetsSelector = (state: RootReducerState) => state.site.currentSite?.xStoreCfg?.plpFacet

export const plpBadgesSelector = (state: RootReducerState) => state.site.currentSite?.xStoreCfg?.badges

export const pdpTaxesSelector = (state: RootReducerState) => state.site.currentSite?.xStoreCfg?.calculateTaxEnabled

export const bookAnEyeUrlSelector = (state: RootReducerState) => state.site.currentSite?.xStoreCfg?.BookAnEyeUrl

export const paymentMethodsSelector = (state: RootReducerState) => state.site.currentSite?.xStoreCfg?.paymentMethods

export const shippingFormFieldsSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.shippingAddressFieldsList

export const billingFormFieldsSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.billingAddressFieldsList

export const countrySelector = (state: RootReducerState) => state.site.currentSite?.country

export const languageSelector = (state: RootReducerState) => state.site.currentSite?.locale

export const xStoresCfgSelector = (state: RootReducerState) => state.site.currentSite?.xStoresCfg

export const paypalReturnUrlSelector = (state: RootReducerState) => state.site.currentSite?.paypalReturnUrl

export const paypalCancelUrlSelector = (state: RootReducerState) => state.site.currentSite?.paypalCancelUrl

export const cartMaxItemsSelector = (state: RootReducerState) => state.site.currentSite?.cartMaxItems

export const damDomainSelector = (state: RootReducerState) => state.site.currentSite?.damDomain

export const availableShippingDaysSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.AVAIL_SHIP_DAYS_PLANO_Standard!

export const subscriptionFrequencyAllowedAmountSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.SUBSCRIPTION_FREQUENCY_ALLOWABLE_AMOUNT

export const subscriptionFrequencyAllowedUOMSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.SUBSCRIPTION_FREQUENCY_ALLOWABLE_UOM

export const RXManDaysSelector = (state: RootReducerState) => state.site.currentSite?.xStoreCfg?.MAN_DAYS_RX!

export const uploadRxFileOnOrderConfirmSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.uploadRXFileOnOrderConfirm!

export const standardRXShippingDaysSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.SHIPPING_DAYS_STANDARD_RX!

export const newsletterPromoCodeSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.WELCOME_SIGN_UP_PROMO_NAME!

export const manDaysRXSelector = (state: RootReducerState) => state.site.currentSite?.xStoreCfg?.MAN_DAYS_RX!

export const storeCfgServiceLinksSelector = (state: RootReducerState): ParsedServiceLinks | null => {
  const serviceLinks = state.site.currentSite?.xStoreCfg?.serviceLinks
  if (serviceLinks) {
    return JSON.parse(serviceLinks.replace('[', '{').replace(']', '}'))
  }

  return null
}

export const googleApiEnabledeSelector = (state: RootReducerState): GoogleAPIEnabledInterface => {
  if (!state.site.currentSite?.xStoreCfg?.GOOGLE_API_ENABLED) {
    return {
      isEnabled: false,
      isEnabledOnShipping: false,
      isEnabledOnBilling: false,
      isEnabledOnAddressBook: false,
    }
  }

  return state.site.currentSite?.xStoreCfg?.GOOGLE_API_ENABLED
}

export const googleApiKeySelector = (state: RootReducerState) => state.site.currentSite?.xStoreCfg?.googleApiKey

export const forterSiteIdSelector = (state: RootReducerState) => state.site.currentSite?.xStoreCfg?.FORTER_SITE_ID

export const monetateKeySelector = (state: RootReducerState) => state.site.currentSite?.xStoreCfg?.monetateEnabled

export const paypalSecureAcceptanceUrlSelector = (state: RootReducerState) =>
  state.site.currentSite?.paypalSecureAcceptanceUrl

export const is3dsEnabledSelector = (state: RootReducerState) => state.site.currentSite?.xStoreCfg?.is3dsEnabled

export const mostUsedEmailDomainsSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.MostUsedEmailDomains

export const algoliaBasedPlpSelector = (state: RootReducerState) =>
  state.site.currentSite?.storeCfg?.userData?.['algolia.based.plp']

export const customerSegmentsEnabledSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.CustomerSegments_Enabled

export const preloadRxDataSelector = (state: RootReducerState) =>
  state.site.currentSite?.storeCfg?.RX_PRELOAD_DATA_PDP === 'true'

export const isSocialLoginEnabledSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.isSocialLoginEnabled

export const forterWebIdSelector = (state: RootReducerState) => state.site.currentSite?.forterWebId

export const isVMMVEnabledSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.isVMMVEnabled || !!state.site.currentSite?.xStoreCfg?.VM_SCRIPT_SRC || false

export const storeLocatorEnabled = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.isStoreLocatorEnabled || false

export const forceLoggedCheckout = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.forceLoggedCheckout || false

export const shippingTaxesEnabled = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.shippingTaxesEnabled || false

export const frameAdvisorEnabled = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.frameGenius?.isFrameAdvisorEnabled || false

export const shortCodeContactPhoneNumberSelector = (state: RootReducerState) => {
  if (state.site.currentSite?.xStoreCfg?.shortCodes?.enableShortCodes)
    return state.site.currentSite?.xStoreCfg?.shortCodes?.CUSTOMER_CARE_PHONE
  else return ''
}

export const isOrderReminderEnabledSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.REORDER_REMINDER_SERVICE_ENABLED || false

export const isOrderReminderSMSServiceEnabledSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.ORDER_SMS_REMINDER_ENABLED || false

export const isOrderReminderEmailServiceEnabledSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.ORDER_EMAIL_REMINDER_ENABLED || false
export const paginationConfigSelector = (state: RootReducerState) => state.site.currentSite?.xStoreCfg?.paginationConfig
