import React, { FC } from 'react'
import { SVGIcon, SVGIconProps } from '@components/UI-CSS/SVGIcon/SVGIcon'

// TODO: refactor, so that there's no need to wrap SVGIcon in a functional component for each payment icon

export type PaymentIconProps = Partial<SVGIconProps>

export const AffirmIcon: FC<PaymentIconProps> = props => (
  <SVGIcon size={30} {...props} library="payment" name="affirm" />
)

export const AmExIcon: React.FC<PaymentIconProps> = props => (
  <SVGIcon size={30} {...props} library="payment" name="amex" />
)

export const KlarnaIcon: React.FC<PaymentIconProps> = props => (
  <SVGIcon size={30} {...props} library="payment" name="klarna" />
)

export const CreditCardPlaceHolderIcon: FC<PaymentIconProps> = props => (
  <SVGIcon size={30} {...props} library="payment" name="placeholder" />
)

export const ApplePayIcon: React.FC<PaymentIconProps> = props => (
  <SVGIcon size={30} {...props} library="payment" name="apple-pay" />
)

export const ApplePayTransparentIcon: React.FC<PaymentIconProps> = props => (
  <SVGIcon size={30} {...props} library="payment" name="apple-pay-transparent" />
)

export const DiscoverIcon: React.FC<PaymentIconProps> = props => (
  <SVGIcon size={30} {...props} library="payment" name="discover" />
)

export const JCBIcon: FC<PaymentIconProps> = props => <SVGIcon size={30} {...props} library="payment" name="jcb" />

export const MastercardIcon: React.FC<PaymentIconProps> = props => (
  <SVGIcon size={30} {...props} library="payment" name="mastercard" />
)

export const VisaIcon: React.FC<PaymentIconProps> = props => (
  <SVGIcon size={30} {...props} library="payment" name="visa" />
)

export const PayPalIcon: React.FC<PaymentIconProps> = props => (
  <SVGIcon size={30} {...props} library="payment" name="paypal" />
)

// NOTE: setting size in passed css selector is required for this icon
export const PaypalRegularIcon: React.FC<PaymentIconProps> = props => (
  <SVGIcon size={props.className ? undefined : 30} {...props} library="payment" name="paypal-regular" />
)

// NOTE: currently unused, commented for future use
// export const AfterPayIcon: FC<PaymentIconProps> = props => (
//   <SVGIcon size={30} {...props} library="payment" id="afterpay" />
// )

// NOTE: currently unused, commented for future use
// export const PaypalWhiteIcon: FC<PaymentIconProps> = props => (
//   <SVGIcon size={30} {...props} library="payment" id="paypal-white" />
// )
