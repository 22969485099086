import { mapStoresData } from '@utils/mapStoresData'
import config from '../../configs'
import { FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Store } from '@typesApp/store'
import { TabStore } from '@typesApp/tabStores'
import { withCredentialsEnabled } from '@utils/helpers'

export const tabApi = createApi({
  reducerPath: 'tabApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.tabApiUrl,
    ...(withCredentialsEnabled() ? { credentials: 'include' } : {}),
  }),
  endpoints: builder => ({
    getStores: builder.query({
      async queryFn(args, _queryApi, _extraOptions, baseQuery) {
        const { storeNumbers, storeName } = args

        const storeNumbersList = storeNumbers.map(id =>
          baseQuery({
            url: 'SV/office',
            method: 'get',
            params: {
              storeNumber: id,
            },
          })
        )

        const result = await Promise.all(storeNumbersList)

        const data = result.map(res => res.data).filter(element => element)

        const mappedData =
          data &&
          mapStoresData({
            storeName,
            stores: data as TabStore[],
          })
        return {
          data: mappedData as Store[],
        }
      },
    }),
  }),
})

export const { useGetStoresQuery } = tabApi
