//Standard libraries
import { createReducer, AnyAction } from '@reduxjs/toolkit'
//Redux
import initStates from './initStates'
import { ProductTypeFiltersState } from './reducerStateInterface'
import { SET_PRODUCT_TYPE_FILTERS_ACTION, SET_SEARCH_TERM_ACTION } from '../actions/productTypeFilters'
const productTypeFiltersReducer = createReducer(initStates.productTypeFilters, (builder) => {
  builder.addCase(
    SET_PRODUCT_TYPE_FILTERS_ACTION,
    (state: Pick<ProductTypeFiltersState, 'values'>, action: AnyAction) => {
      state.values = action.payload
    }
  )
  builder.addCase(SET_SEARCH_TERM_ACTION, (state: Pick<ProductTypeFiltersState, 'searchTerm'>, action: AnyAction) => {
    state.searchTerm = action.payload
  })
})
export default productTypeFiltersReducer
