import config from '@configs/index'

export const transactionHost = config?.transactionHost || ''
export const storePath = 'store/{storeId}'

export const urlList = {
  getHCLUserSession: `${storePath}/person/@self?forUserId={forUserId}`,
  getCart: `${storePath}/cart/@self?forUserId={forUserId}`,
  takeOver: `${storePath}/cart/{orderId}/lockOnBehalf?takeOverLock=Y`,
  lockCart: `${storePath}/cart/{orderId}/lockOnBehalf`,
  unlockCart: `${storePath}/cart/{orderId}/unlockOnBehalf`,
  setCookie: `${storePath}/behalf/setNewCookies`,
  runAsUserSetInSession: '/webapp/wcs/stores/servlet/RunAsUserSetInSession?runAsUserId={memberId}',
  restoreOriginalUserSetInSession:
    '/webapp/wcs/stores/servlet/RestoreOriginalUserSetInSession?storeId={storeId}&URL=AjaxLogonForm&myAcctMain=1',
}
