//Standard libraries
import { AnyAction, createReducer } from '@reduxjs/toolkit'
//Redux
import initStates from './initStates'
import {
  INIT_SITE_SUCCESS_ACTION,
  SET_ALGOLIA_BASED_PLP_ACTION,
  SET_HOSTNAME,
  SET_HOSTNAME_URLS,
} from '../actions/site'
import { HYDRATE } from 'next-redux-wrapper'
import { SET_FORTER_WEB_ID_ACTION } from '@redux/actions/forterWebId'

/**
 * Site reducer
 */
const siteReducer = createReducer(initStates.site, builder => {
  builder.addCase(INIT_SITE_SUCCESS_ACTION, (state: any | any, action: AnyAction) => {
    Object.assign(state, { currentSite: { ...action.payload } })
  })
  builder.addCase(SET_HOSTNAME, (state: any | any, action: AnyAction) => {
    Object.assign(state, {
      ...state,
      hostname: action.payload,
    })
  })
  builder.addCase(SET_HOSTNAME_URLS, (state: any | any, action: AnyAction) => {
    Object.assign(state, {
      ...state,
      hostnameUrls: action.payload,
    })
  })
  builder.addCase(HYDRATE, (state, action: AnyAction) => {
    Object.assign(state, {
      ...state,
      currentSite: { ...action.payload.site.currentSite },
    })
  })
  builder.addCase(SET_ALGOLIA_BASED_PLP_ACTION, (state: any | any, action: AnyAction) => {
    Object.assign(state, {
      ...state,
      currentSite: {
        ...state.currentSite,
        storeCfg: {
          ...state.currentSite.storeCfg,
          userData: {
            ...state.currentSite.storeCfg.userData,
            ['algolia.based.plp']: action.payload,
          },
        },
      },
    })
  })
  builder.addCase(SET_FORTER_WEB_ID_ACTION, (state: any | any, action: AnyAction) => {
    Object.assign(state, {
      ...state,
      currentSite: { ...state.currentSite, forterWebId: action.payload },
    })
  })
})

export default siteReducer
