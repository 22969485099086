export const DATE_FORMAT_PATTERN = {
  'MMMM d, yyyy': 'MMMM d, yyyy', // July 3, 2021,
  'yyyy-MM-dd': 'yyyy-MM-dd', // 2021-07-03
  'dd/MM/yyyy': 'dd/MM/yyyy', // 03/07/2021
  'MM/dd/yyyy': 'MM/dd/yyyy',
  'MMM dd, yyyy': 'MMM dd, yyyy',
} as const

export const DATE_FNS_LOCALE_MAP = {
  en_ca: 'en-ca',
  en_au: 'en-au',
  en_nz: 'en-nz',
  fr_ca: 'fr-ca',
}

export interface DATE_OPTIONS {
  locale: (typeof DATE_FNS_LOCALE_MAP)[keyof typeof DATE_FNS_LOCALE_MAP]
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined
  firstWeekContainsDate?: number | undefined
  useAdditionalWeekYearTokens?: boolean | undefined
  useAdditionalDayOfYearTokens?: boolean | undefined
}

export const ISO_DATE_FORMAT = 'yyyy-MM-dd'

// https://day.js.org/docs/en/display/format
export const ISO_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss'

export const COMMON_DATE_FORMATS = ['MM-dd-yyyy', 'yyyy-MM-dd', 'dd-MM-yyyy', 'MM/dd/yyyy', 'yyyy/MM/dd', 'dd/MM/yyyy']
