import { toNumber } from 'lodash-es'
import { PRODUCT_TYPES_KEYS } from '../../constants/product'
import DateService from '../../services/DateService'
import { Adjustment } from '../../types/order'
import { getBrand as getBrandAlgolia, getIsPolarized as getIsPolarizedAlgolia } from '@utils/productAttributesAlgolia'
import { getBrand, getIsPolarized } from '@utils/productAttributes'
import { IProduct } from '../../types/product'
import { Catentries } from '@redux/rootReducer'
import { ADJUSTMENT_USAGE } from '@constants/common'

export type AlgoliaPrice = {
  listPrice: number | string
  offerPrice: number | string
  percentageDiscount?: number
  amountOfDiscount?: number
  currency?: string
  startDate: string
  endDate: string
  segment?: string
  badge?: string
  precedence: number
  priceListPrecedence: number
  futurePrices?: AlgoliaPrice[]
}

export type PartNumberAlgoliaPrice = Record<string, AlgoliaPrice | undefined>

export interface IProductPrice {
  initialPrice: number
  offerPrice: number
  currency: string
  discountAbsolute: number
  discountPercentage: number
}

export const parsePriceAsNumber = (value: string | number | undefined): number => {
  if (typeof value === 'string') return parseFloat(value)
  if (typeof value === 'number') return value
  return 0
}

export const calculateOfferPrice = (offerPrice: number, adjustments: Adjustment[], quantity = 1): number => {
  if (!adjustments || adjustments.length === 0) {
    return offerPrice
  }
  const totalAdjustments = adjustments
    .filter(
      adjustment =>
        ['OrderItem', 'Order'].includes(adjustment?.displayLevel) &&
        adjustment?.usage?.toLocaleLowerCase() === ADJUSTMENT_USAGE.DISCOUNT
    )
    .reduce((accumulator, adjustment) => accumulator + parseFloat(adjustment?.amount ?? '0'), 0)
  return offerPrice + totalAdjustments / quantity
}

export const calculatePercentageDiscount = (initialPrice: number, offerPrice: number): number => {
  if (initialPrice === offerPrice) {
    return 0
  }

  const percentageDiscount = Math.round((1 - offerPrice / initialPrice) * 100)
  return percentageDiscount < 100 ? percentageDiscount : 100
}

export const calculateAbsoluteDiscount = (initialPrice: number, offerPrice: number): number => {
  if (initialPrice === offerPrice) {
    return 0
  }

  return initialPrice - offerPrice
}

export const getProductPrice = (
  price?: AlgoliaPrice,
  _quantity?: string[],
  adjustments?: Adjustment[]
): IProductPrice => {
  const productQuantity = _quantity?.length ? toNumber(_quantity[0] !== '0' ? _quantity[0] : '1') : 1
  const initialPrice = parsePriceAsNumber(price?.listPrice ?? 0)
  const offerPrice = calculateOfferPrice(parsePriceAsNumber(price?.offerPrice ?? 0), adjustments || [], productQuantity)
  const discountAbsolute = calculateAbsoluteDiscount(initialPrice, offerPrice) / productQuantity
  const discountPercentage = calculatePercentageDiscount(initialPrice, offerPrice)
  const currency = price?.currency || ''
  return {
    initialPrice,
    offerPrice,
    currency,
    discountAbsolute,
    discountPercentage,
  }
}

const sortByPriceListPrecedenceAndOfferPrice = (a: AlgoliaPrice | undefined, b: AlgoliaPrice | undefined) => {
  if (!a || !b) {
    return 0
  }

  if (a.priceListPrecedence === b.priceListPrecedence) {
    if (a.precedence === b.precedence) {
      return parsePriceAsNumber(a.offerPrice) - parsePriceAsNumber(b.offerPrice)
    }
    return b.precedence - a.precedence
  }

  return b.priceListPrecedence - a.priceListPrecedence
}

export const getCatEntriesAlgoliaPrices = (
  customerSegment: string[],
  catentries?: Catentries
): PartNumberAlgoliaPrice => {
  if (catentries) {
    return Object.keys(catentries)?.reduce(
      (map, id) => (
        (map[catentries[id].partNumber] = determineAlgoliaPrice(catentries?.[id].x_price!, customerSegment)), map
      ),
      {} as PartNumberAlgoliaPrice
    )
  } else {
    return {} as PartNumberAlgoliaPrice
  }
}

export const getProductAlgoliaPrice = (
  customerSegment: string[],
  product?: IProduct | null
): PartNumberAlgoliaPrice => {
  if (product && product.x_price) {
    return {
      [product.partNumber]: determineAlgoliaPrice(product.x_price, customerSegment),
    }
  } else {
    return {} as PartNumberAlgoliaPrice
  }
}

export const determineAlgoliaPrice = (
  prices:
    | {
      [key: string]: AlgoliaPrice
    }
    | undefined,
  customerSegments: string[],
  productType?: string,
  isPLP?: boolean,
  isRoxable?: boolean,
  isRxOrder?: boolean
): AlgoliaPrice | undefined => {
  const isAlgoliaPLP = isPLP ?? false
  if (!prices) {
    return undefined
  }

  let filteredPrices = prices
  if (
    productType === PRODUCT_TYPES_KEYS.FRAMES ||
    (productType === PRODUCT_TYPES_KEYS.OPTICAL && isRoxable && !isRxOrder) ||
    (productType === PRODUCT_TYPES_KEYS.SUN && isRoxable && !isRxOrder) ||
    isAlgoliaPLP
  ) {
    filteredPrices = Object.keys(prices)
      .filter(key => key.indexOf('RX') == -1)
      .reduce((price, key) => {
        price[key] = prices[key]
        return price
      }, {})
  }

  const pricesArray = Object.values(filteredPrices)
  if (!pricesArray.length) {
    return undefined
  }

  let validPrice: AlgoliaPrice | undefined

  customerSegments.forEach(segment => {
    if (!validPrice) {
      validPrice = getAllPricesByCustomerSegment(pricesArray, segment)
        .filter(
          (price: AlgoliaPrice) =>
            DateService.isISODateValid(price.startDate, price.endDate) && parsePriceAsNumber(price.offerPrice) > 0
        )
        .sort(sortByPriceListPrecedenceAndOfferPrice)[0]
    }
  })
  return validPrice
}

const getAllPricesByCustomerSegment = (pricesArray: AlgoliaPrice[], customerSegment: string): AlgoliaPrice[] => {
  const segmentPrices = pricesArray.reduce((acc, price) => {
    if (price.segment === customerSegment) {
      acc.push(price)
    }
    if (price.futurePrices?.length) {
      acc.push(...getAllPricesByCustomerSegment(price.futurePrices || [], customerSegment))
    }
    return acc
  }, [] as AlgoliaPrice[])

  // If no matched prices were found, return the first price from the original array
  if (segmentPrices.length === 0 && pricesArray.length > 0) {
    segmentPrices.push(pricesArray[0]);
  }

  return segmentPrices;
}

export const shouldShowAbsoluteDiscount = (product: IProduct): boolean => {
  /* CLY-3028 - Polarized Frames Promotion
    Show absolute discount when product:
    - is Rayban, Oakley, or Persol brand
    - has POLARIZED attribute set to true
  */

  /*
    CLY-3093 - as per ticket comment set always false till BE changes
  */
  const temporaryFalse = true
  if (temporaryFalse) {
    return false
  }

  const brand = getBrandAlgolia(product) || getBrand(product) || ''
  const isPolarized = getIsPolarizedAlgolia(product) || getIsPolarized(product)
  return isPolarized && ['ray-ban', 'oakley', 'persol'].includes(brand.toLowerCase())
}
