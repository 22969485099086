import { ARROW_ICONS } from './arrow'
import { CLOSE_ICONS } from './close'
import { FAVORITE_ICONS } from './favorite'
import { MAINTENANCE_ICONS } from './maintenance'
import { MEDIA_ICONS } from './media'
import { VALIDATION_ICONS } from './validation'
import { ACCOUNT_ICONS } from './account'
import { FEATURE_ICONS } from './feature'
import { FILTER_ICONS } from './filter'
import { FRAME_ICONS } from './frame'
import { GLOBAL_ICONS } from './global'
import { LENS_ICONS } from './lens'
import { PAYMENT_ICONS } from './payment'
import { SOCIAL_ICONS } from './social'
import { MISC_1_ICONS } from './misc1'
import { MISC_2_ICONS } from './misc2'
import { MISC_3_ICONS } from './misc3'

export const SVG_LIBRARY = {
  favorite: FAVORITE_ICONS,
  maintenance: MAINTENANCE_ICONS,
  media: MEDIA_ICONS,
  validation: VALIDATION_ICONS,
  close: CLOSE_ICONS,
  arrow: ARROW_ICONS,
  account: ACCOUNT_ICONS,
  feature: FEATURE_ICONS,
  filter: FILTER_ICONS,
  frame: FRAME_ICONS,
  global: GLOBAL_ICONS,
  lens: LENS_ICONS,
  payment: PAYMENT_ICONS,
  social: SOCIAL_ICONS,
  misc1: MISC_1_ICONS,
  misc2: MISC_2_ICONS,
  misc3: MISC_3_ICONS,
}
