//Standard libraries
import { createAction } from '@reduxjs/toolkit'
//Redux
import * as ACTIONTYPES from '../action-types/subscription'

export const SET_SUBSCRIPTION_CONFIG_ACTION = createAction<any, string>(ACTIONTYPES.SET_SUBSCRIPTION_CONFIG)
export const SET_SUBSCRIPTION_LAST_SUBSCRIBED_ORDER_ACTION = createAction<any, string>(
  ACTIONTYPES.SET_SUBSCRIPTION_LAST_SUBSCRIBED_ORDER
)
export const SET_SUBSCRIPTION_ORDER_COMPLETE_ACTION = createAction<boolean, string>(
  ACTIONTYPES.SET_SUBSCRIPTION_ORDER_COMPLETE
)
export const LOAD_SUBSCRIPTION_CONFIG_REQUEST_ACTION = createAction<any, string>(ACTIONTYPES.LOAD_SUBSCRIPTION_CONFIG)
