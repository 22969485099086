import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'
import { SeoResponse, ISeo } from '../../types/seo'
import { RootReducerState } from '@redux/rootReducer'

export const seoApi = createApi({
  reducerPath: 'seoApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/',
  }),
  endpoints: build => ({
    getSeo: build.query<
      SeoResponse<ISeo>,
      {
        identifier: string
      }
    >({
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        const state = queryApi.getState() as RootReducerState
        const result = await fetchWithBQ({
          url: 'api/v2/urls',
          method: 'get',
          queryParams: args || {},
          body: args['body'] || {},
          extraParams: { siteContextKey: 'search' },
          pathParams: { storeId: state.site.currentSite?.storeID! },
        })
        return (result.data as SeoResponse<ISeo>) ? { data: result.data as SeoResponse<ISeo> } : { error: result }
      },
    }),
    getAlternateUrl: build.query<
      any,
      {
        brand?: string
        pageId?: string
      }
    >({
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        const state = queryApi.getState() as RootReducerState
        const result = await fetchWithBQ({
          url: 'store/{storeId}/seo/alternateUrl',
          method: 'get',
          queryParams: args || {},
          pathParams: { storeId: state.site.currentSite?.storeID! },
        })

        return result ? { data: result.data } : { error: result }
      },
    }),
  }),
})

export const { useGetAlternateUrlQuery, useGetSeoQuery } = seoApi
