import { PAYMENT_METHODS } from '../constants/paymentMethods'
import differenceInDays from 'date-fns/differenceInDays'
import { ContactLensData, ContactLensesData, EyeContanctLensOption, ProductTypesEnum } from '../types/product'
import {
  OrderItemContactLensData,
  MIN_SLA_PLANO,
  SLA_BUFFER_PLANO,
  MIN_SLA_RX,
  SLA_BUFFER_RX,
  ShippingMethods,
  ShippingMethodsEnum,
  CUT_OVER_IN_STOCK_BUFFER,
  CUT_OVER_OUT_OF_STOCK_BUFFER,
  IS_CUT_OVER,
} from '../types/cart'
import {
  Order,
  PrescriptionItemType,
  ParsedOrderItemsMapByItemType,
  OrderItemWithRoxProps,
  IOrderDetails,
  OrderItem,
  Attribute,
  LiveStock,
  DedicatedShippingMode,
  UsableShippingMode,
  IReorderInfo,
  QuantityPerItemsInGroupedOrder,
} from '../types/order'
import { countBy, sum } from 'lodash-es'
import flattenDeep from 'lodash/flattenDeep'
import uniq from 'lodash/uniq'
import chunk from 'lodash/chunk'
import {
  isAccessoriesProduct,
  isCLAccessoriesProduct,
  isContactLensesProduct,
  isElectronicsProduct,
  isOpticalProduct,
  isSunProduct,
} from './product'
import { isRox, isRoxableProduct, isRxFrame, isRxProduct } from './isRxOrder'
import { getRxPrice, isRxCart, isRxLens, parseRxOrderItems } from './rx'
import Log from '../services/Log'
import {
  MAX_PURCHASABLE_QUANTITY_CL_ACCESSORY,
  ORDER_EXTEND_ATTRIBUTE_NAMES,
  ORDER_STATUS,
  STANDARD_ONLY_FSA_CODES,
} from '../constants/order'
import { CheckoutPayload, PrescriptionItemsMapByType, PrescriptionMacroGroup } from '../types/checkout'
import { IOrderSliceState } from '../features/order/IOrderSliceState'
import RequestService from '../services/RequestService'
import { PrescriptionDetailsResponse } from '../types/prescription'
import { getAllProductAttributes, getAnnualSupplyBadge, getNaturalAttribute } from './productAttributes'
import { PersonResponse } from '../types/user'
import { AddressFormData } from '../types/form'
import { CHECKOUT, EMPTY_STRING, EXCEPTION, HYPHEN, NEW_SHIPPINNG_ADDRESS } from '../constants/common'
import addressUtil from './addressUtil'
import getDisplayName from 'react-display-name'
import Axios, { Canceler } from 'axios'
import { Catentry, SiteInfo } from '@redux/rootReducer'
import { localStorageUtil } from '@foundation/utils/storageUtil'
import {
  IS_REORDER_SUCCESS,
  REORDER_BILLING_ADDRESS_ID,
  REORDER_OLD_ORDER_ID,
  REORDER_ORDER_ID,
} from '@foundation/constants/common'
import { BILLING_ADDRESS_ID } from '@constants/checkout'

export const cartHasContactLenses = (orderExtendAttribute: Order['orderExtendAttribute']): boolean => {
  return !!orderExtendAttribute?.find(a => a.attributeName === 'hasContactLens' && a?.attributeValue === 'true')
}

export const isClOrderItem = (orderExtendAttribute: OrderItem['orderItemExtendAttribute']): boolean => {
  return !!orderExtendAttribute?.find(
    a => a?.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES['IS_CONTACT_LENS'] && a?.attributeValue === 'true'
  )
}

export const isAccessoriesOrderItem = (attribute: OrderItem['attributes']): boolean => {
  return !!attribute?.find(
    a =>
      a?.identifier === 'PRODUCT_TYPE' &&
      a?.values?.[0]?.value?.toUpperCase() === ProductTypesEnum.Accessories.toUpperCase()
  )
}

export const isClAccessoriesOrderItem = (attribute: OrderItem['attributes']): boolean => {
  return !!attribute?.find(
    a =>
      a?.identifier === 'PRODUCT_TYPE' &&
      a?.values?.[0]?.value?.toUpperCase() === ProductTypesEnum.ContactLensesAccessories.toUpperCase()
  )
}

export const orderHasPrescriptionUploaded = (oi: OrderItem | OrderItemWithRoxProps): boolean => {
  try {
    return !!oi?.prescriptionDetails
  } catch (e) {
    return false
  }
}

export const orderHasPrescriptionPeriodExcedeed = (od: IOrderDetails, expirationPeriodDays: number): boolean => {
  try {
    const orderDate = od?.lastUpdateDate?.split('T')[0] || null
    const difference = differenceInDays(new Date(), new Date(orderDate || ''))
    return (
      difference > expirationPeriodDays &&
      (isRxCart(od['orderExtendAttribute']) || cartHasContactLenses(od['orderExtendAttribute']))
    )
  } catch (e) {
    return false
  }
}

export const getContactLensOrderItemData = (
  orderExtendAttribute: OrderItem['orderItemExtendAttribute'] | null
): ContactLensesData | {} => {
  try {
    return (
      JSON.parse(
        orderExtendAttribute?.find(a => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES['X_CONTACT_LENS'])
          ?.attributeValue || ''
      ) || null
    )
  } catch (e) {
    Log.error('Could not get contact lens order item attributes data', CHECKOUT)
    return {}
  }
}

export const generateQuantityOptions = (
  max = MAX_PURCHASABLE_QUANTITY_CL_ACCESSORY,
  start = 0,
  availability = MAX_PURCHASABLE_QUANTITY_CL_ACCESSORY
) => {
  const options: EyeContanctLensOption[] = []
  let i: number = start
  while (i < max) {
    options.push({
      text: `${i}`,
      value: `${i}`,
      index: i,
      notAvailable: availability < i,
    })
    i++
  }
  return options
}

/**
 * function to aggregate order items by part number
 * @param { OrderItem[] } orderItems current cart order items list
 */

export const getGroupedOrderItemsByPartNumber = (orderItems: OrderItem[]): OrderItem[] | null => {
  try {
    const orderItemsCountByPartNumber = countBy(orderItems, 'partNumber')
    const uniqueOrderItems = [...new Map(orderItems.map(item => [item['partNumber'], item])).values()]
    const orderItemWithQuantity = uniqueOrderItems.map(orderItem => ({
      ...orderItem,
      quantity: orderItemsCountByPartNumber[orderItem.partNumber].toString(),
    }))

    return orderItemWithQuantity
  } catch (e: any) {
    Log.error('Could not get grouped order items by partnumber: ' + e)
    return orderItems
  }
}

/**
 * function to parse order items in cart recap according to product type
 * @param { Cart } cart current cart session info
 * @param { OrderItem[] } orderItems current cart order items list
 */
export const getParsedOrderItems = (
  orderItems: OrderItem[] | null,
  updatedItemId?: string,
  updatedItemQuantity?: string
): OrderItem[] | null => {
  try {
    const orderItemsNew = mapAttributesIntoProductAttributes(orderItems)
    const defaultOrderItems: OrderItem[] | null = !!orderItemsNew
      ? orderItemsNew.filter(oi => isSunProduct(oi) && !isRox(oi['orderItemExtendAttribute']))
      : null
    const clOrderItems: OrderItem[] | null = !!orderItemsNew
      ? getGroupedOrderItemsByAttrCorrelation(
          orderItemsNew?.filter(oi => isContactLensesProduct(oi)),
          'xitem_field1'
        )
      : null

    const clAccessoriesOrderItems: OrderItem[] | null = !!orderItemsNew
      ? getClAccessoriesGroupedOrderItemsByPartNumber(
          orderItemsNew?.filter(oi => isCLAccessoriesProduct(oi)),
          updatedItemId,
          updatedItemQuantity
        )
      : null

    const rxItems: OrderItem[] | null = !!orderItemsNew
      ? parseRxOrderItems(orderItemsNew.filter(oi => isRox(oi['orderItemExtendAttribute'])))
      : null

    const rest = orderItemsNew?.filter(
      oi =>
        !isSunProduct(oi) &&
        !isContactLensesProduct(oi) &&
        !isCLAccessoriesProduct(oi) &&
        !isRox(oi['orderItemExtendAttribute'])
    )

    return [
      ...(clOrderItems || []),
      ...(clAccessoriesOrderItems || []),
      ...(defaultOrderItems || []),
      ...(rxItems || []),
      ...(rest || []),
    ]
  } catch (e: any) {
    Log.error('Could not parsed order items: ' + e)
    return null
  }
}

export const addProductAttributesToCatentry = (catentry: Catentry) => {
  return {
    ...catentry,
    productAttributes: getAllProductAttributes(catentry.attributes),
  }
}

const mapAttributesIntoProductAttributes = (orderItems: OrderItem[] | null): OrderItem[] | undefined => {
  return orderItems?.map(item => {
    return {
      ...item,
      productAttributes: getAllProductAttributes(item.attributes),
    }
  })
}

/**
 * function to parse order items in cart recap according to product type
 * @param { OrderItem[] } orderItems current cart order items list
 */
export const getParsedOrderRecapItems = (
  orderItems: OrderItem[],
  updatedItemId?: string,
  updatedItemQuantity?: string
): OrderItem[] => {
  try {
    const orderItemsNew = mapAttributesIntoProductAttributes(orderItems)
    const defaultOrderItems: OrderItem[] | null = !!orderItemsNew
      ? getGroupedOrderItemsByPartNumber(
          orderItemsNew?.filter(oi => isSunProduct(oi) && !isRox(oi['orderItemExtendAttribute']))
        )
      : null
    const clOrderItems: OrderItem[] | null = !!orderItemsNew
      ? getGroupedOrderItemsByAttrCorrelation(
          orderItemsNew?.filter(oi => isContactLensesProduct(oi)),
          'xitem_field1'
        )
      : null

    const clAccessoriesOrderItems: OrderItem[] | null = !!orderItemsNew
      ? getClAccessoriesGroupedOrderItemsByPartNumber(
          orderItemsNew?.filter(oi => isCLAccessoriesProduct(oi)),
          updatedItemId,
          updatedItemQuantity
        )
      : null

    const rxItems: OrderItem[] | null = !!orderItemsNew
      ? parseRxOrderItems(orderItemsNew.filter(oi => isRox(oi['orderItemExtendAttribute'])))
      : null

    const rest = orderItemsNew?.filter(
      oi =>
        !(isSunProduct(oi) && !isRox(oi['orderItemExtendAttribute'])) &&
        !isContactLensesProduct(oi) &&
        !isCLAccessoriesProduct(oi) &&
        !isRox(oi['orderItemExtendAttribute'])
    )

    const parsedItems = [
      ...(clOrderItems || []),
      ...(clAccessoriesOrderItems || []),
      ...(defaultOrderItems || []),
      ...(rxItems || []),
      ...(rest || []),
    ]
    return parsedItems.length === 0 ? orderItemsNew ?? orderItems : parsedItems
  } catch (e: any) {
    Log.error('Could not parsed order recap items: ' + e)
    return orderItems
  }
}

/**
 * Get order items for return: Items are not grouped by part number
 * to enable users to select single or multiple quantities of same product.
 * @param { OrderItem[] } orderItems order items list
 */
export const getOrderReturnsItems = (orderItems: OrderItem[]): OrderItem[] | null => {
  try {
    const defaultOrderItems: OrderItem[] = orderItems?.filter(
      oi => isSunProduct(oi) && !isRox(oi['orderItemExtendAttribute'])
    )
    const clOrderItems: OrderItem[] = orderItems?.filter(oi => isContactLensesProduct(oi))

    const clAccessoriesOrderItems: OrderItem[] = orderItems?.filter(oi => isCLAccessoriesProduct(oi))

    const rxItems: OrderItem[] = parseRxOrderItems(orderItems.filter(oi => isRox(oi['orderItemExtendAttribute'])))

    const rest = orderItems?.filter(
      oi =>
        !(isSunProduct(oi) && !isRox(oi['orderItemExtendAttribute'])) &&
        !isContactLensesProduct(oi) &&
        !isCLAccessoriesProduct(oi) &&
        !isRox(oi['orderItemExtendAttribute'])
    )

    return [
      ...(clOrderItems || []),
      ...(clAccessoriesOrderItems || []),
      ...(defaultOrderItems || []),
      ...(rxItems || []),
      ...(rest || []),
    ]
  } catch (e: any) {
    Log.error('Could not parse order return items: ' + e)
    return null
  }
}

/**
 * function to aggregate order items by attribute correlation
 * @param { OrderItem[] } orderItems current cart order items list
 * @param { string } attribute attribute string to look for in other items
 */
export const getGroupedOrderItemsByAttrCorrelation = (
  orderItems: OrderItem[],
  attribute: string
): OrderItem[] | null => {
  try {
    const filteredOrderItems = orderItems.filter(orderItem => !!orderItem[attribute])
    const foundAsPair: string[] = []
    const groupedOrderItems: OrderItem[] = []
    filteredOrderItems?.map((filteredItem, i) => {
      const found = filteredOrderItems.find(
        item => item?.orderItemId !== filteredItem?.orderItemId && item?.orderItemId === filteredItem?.[attribute]
      )

      if (!!found) {
        foundAsPair.push(found.orderItemId)
        filteredItem = {
          ...filteredItem,
          groupedItem: true,
          sibilingOrderItem: found,
          groupedOrderItemsId: [filteredItem.orderItemId, found.orderItemId],
        }
        groupedOrderItems.push(filteredItem)
        delete filteredOrderItems[i]
      } else if (!foundAsPair.includes(filteredItem.orderItemId)) {
        //when one of the eyes is removed , we wont have a pair to group
        groupedOrderItems.push(filteredItem)
        delete filteredOrderItems[i]
      }
    })
    return [...groupedOrderItems, ...orderItems.filter(orderItem => !orderItem[attribute])]
  } catch (e: any) {
    Log.error('Could not get grouped order items: ' + e)
    return null
  }
}

export const getClAccessoriesGroupedOrderItemsByPartNumber = (
  orderItems: OrderItem[],
  updatedId?: string,
  updatedQuantity?: string
): OrderItem[] | null => {
  try {
    const ClOrderItems = orderItems
    const filteredOrderItems: OrderItem[] = []

    // grouped items by partNumber
    const groupByPartNumber = ClOrderItems.reduce((group, product) => {
      const { partNumber } = product
      group[partNumber] = group[partNumber] ?? []
      group[partNumber].push(product)
      return group
    }, {})

    Object.keys(groupByPartNumber).forEach(function (partNumber) {
      // if there are sibilings, sum quantity and price on each item and group the ids
      if (groupByPartNumber[partNumber].length > 1) {
        let filteredItem: any = {}
        const orderItemsId: string[] = []
        const quantityPerItemsInGroupedOrder: QuantityPerItemsInGroupedOrder[] = []
        let itemQuantity = 0
        let itemPrice = 0

        groupByPartNumber[partNumber].map((item, i) => {
          itemQuantity = parseInt(item.quantity) + itemQuantity
          itemPrice = parseFloat(item.orderItemPrice) + itemPrice
          if (i === 0) {
            filteredItem = item
          }
          const foundSameOrderId = orderItemsId.includes(item?.orderItemId)
          if (!foundSameOrderId) {
            orderItemsId.push(item?.orderItemId)
            quantityPerItemsInGroupedOrder.push({ orderItemId: item?.orderItemId, quantity: item?.quantity })
          }
        })

        const found = orderItemsId.find(id => id === updatedId)
        // if i'm updating from the select label
        if (!!found && updatedQuantity) {
          itemQuantity = parseInt(updatedQuantity)
          itemPrice = itemQuantity * parseFloat(filteredItem.unitPrice)
        }

        filteredItem = {
          ...filteredItem,
          quantity: itemQuantity.toString(),
          orderItemPrice: itemPrice.toString(),
          groupedOrderItemsId: orderItemsId,
          quantityPerItemsInGrouped: quantityPerItemsInGroupedOrder,
        }
        filteredOrderItems.push(filteredItem)
      } else {
        const singleItem = groupByPartNumber[partNumber][0]
        filteredOrderItems.push(singleItem)
      }
    })

    return [...filteredOrderItems]
  } catch (e: any) {
    Log.error('Could not get grouped order items: ' + e)
    return null
  }
}

export const filterClData = (
  data: ContactLensData | null,
  isDominanceFieldActive: boolean,
  attributeKeys?: string[]
): ContactLensData | null => {
  try {
    !!data &&
      Object.keys(data)
        ?.filter(clAttr => attributeKeys?.includes(clAttr))
        .map(filteredAttr => {
          if (filteredAttr !== 'x_dominance' || !isDominanceFieldActive) {
            delete data[filteredAttr]
          }
          return data
        })
    return data
  } catch (e: any) {
    Log.error('Could not filter contact lens order item data: ' + e)
    return null
  }
}

export const getOrderItemClData = (orderItem: OrderItem): ContactLensData | null => {
  try {
    const contactLensData = !!orderItem?.orderItemExtendAttribute
      ? getContactLensOrderItemData(orderItem?.orderItemExtendAttribute)
      : {}
    return contactLensData
  } catch (e) {
    Log.error('Could not find contact lens order item data')
    return null
  }
}

export const formatOrderRecapItemPrices = (orderItem: OrderItem) => {
  let productUnitPrice: number | null = null
  let productOrderItemPrice: number | null = null
  let productOrderItemPriceWithRx: number | undefined | null = null
  let x_offerpriceRx: number | null = null
  let x_offerDiscountpriceRx: number | null = null

  try {
    productOrderItemPrice = orderItem?.groupedItem
      ? parseFloat(
          sum([Number(orderItem.orderItemPrice), Number(orderItem.sibilingOrderItem?.orderItemPrice)]).toFixed(2)
        ) || null
      : parseFloat(Number(orderItem.orderItemPrice).toFixed(2)) || null

    productOrderItemPriceWithRx = getRxPrice(orderItem.roxableServices, orderItem.unitPrice)

    productUnitPrice = orderItem.unitPrice ? parseFloat(orderItem.unitPrice) : null
    x_offerpriceRx = orderItem?.x_offerpriceRx ? parseFloat(orderItem.x_offerpriceRx) : null
    x_offerDiscountpriceRx = orderItem?.x_offerDiscountpriceRx ? parseFloat(orderItem?.x_offerDiscountpriceRx) : null
  } catch (e) {
    Log.error('Could not parse order item price')
  }

  return {
    productUnitPrice,
    productOrderItemPrice,
    productOrderItemPriceWithRx,
    x_offerpriceRx,
    x_offerDiscountpriceRx,
  }
}

export const formatOrderRecapItemClquantity = (quantity: string): string | null => {
  let contactLensquantity: string | null = null

  try {
    contactLensquantity = parseInt(quantity).toString()
  } catch (e) {
    contactLensquantity = null
    Log.error('Could not parse order item quantity')
  }

  return contactLensquantity
}

export const getOrderItemContactLensesData = (
  orderItem: OrderItem,
  filteredKeys?: string[]
): OrderItemContactLensData | null => {
  let leftEyeContactLensData: {
    data: ContactLensData | null
    quantity: string | null
    orderItemId: string | null
  } | null = null

  let rightEyeContactLensData: {
    data: ContactLensData | null
    quantity: string | null
    orderItemId: string | null
  } | null = null

  try {
    const getDominanceFieldOptions: string[] = JSON.parse(getNaturalAttribute(orderItem, 'CL_DOMINANCE'))
    const isDominanceFieldActive = getDominanceFieldOptions.includes('YES')
    let contactLensCurrentOrderItemData = !!orderItem?.orderItemExtendAttribute
      ? getContactLensOrderItemData(orderItem?.orderItemExtendAttribute)
      : null

    if (contactLensCurrentOrderItemData) contactLensCurrentOrderItemData['x_productId'] = orderItem.productId

    let contactLensSibilingOrderItemData = !!orderItem?.sibilingOrderItem?.orderItemExtendAttribute
      ? getContactLensOrderItemData(orderItem?.sibilingOrderItem?.orderItemExtendAttribute)
      : null

    if (contactLensSibilingOrderItemData) contactLensSibilingOrderItemData['x_productId'] = orderItem.productId

    leftEyeContactLensData =
      !!contactLensCurrentOrderItemData && Object.values(contactLensCurrentOrderItemData)?.includes('LCON')
        ? {
            data: contactLensCurrentOrderItemData,
            quantity: orderItem.quantity,
            orderItemId: orderItem.orderItemId || null,
          }
        : {
            data: contactLensSibilingOrderItemData,
            quantity: orderItem?.sibilingOrderItem?.quantity || null,
            orderItemId: orderItem?.sibilingOrderItem?.orderItemId || null,
          }

    rightEyeContactLensData =
      !!contactLensCurrentOrderItemData && Object.values(contactLensCurrentOrderItemData)?.includes('RCON')
        ? {
            data: filterClData({ ...contactLensCurrentOrderItemData }, isDominanceFieldActive, filteredKeys),
            quantity: orderItem.quantity || null,
            orderItemId: orderItem.orderItemId || null,
          }
        : {
            data: filterClData({ ...contactLensSibilingOrderItemData }, isDominanceFieldActive, filteredKeys),
            quantity: orderItem.sibilingOrderItem?.quantity || null,
            orderItemId: orderItem.sibilingOrderItem?.orderItemId || null,
          }

    return {
      left: {
        data: filterClData({ ...leftEyeContactLensData.data }, isDominanceFieldActive, filteredKeys),
        quantity: leftEyeContactLensData?.quantity,
        orderItemId: leftEyeContactLensData?.orderItemId,
      },
      right: {
        data: filterClData({ ...rightEyeContactLensData.data }, isDominanceFieldActive, filteredKeys),
        quantity: rightEyeContactLensData?.quantity,
        orderItemId: rightEyeContactLensData?.orderItemId,
      },
    }
  } catch (e) {
    Log.error('Could not parse contact lenses data')
    return null
  }
}

export const getAllCartItemsOrderIds = (data?: OrderItem[] | OrderItemWithRoxProps[]): string[] | null => {
  try {
    return flattenDeep(
      uniq(
        data?.map((oi: OrderItem | OrderItemWithRoxProps) => {
          return isContactLensesProduct(oi)
            ? [oi.orderItemId, oi.sibilingOrderItem?.orderItemId || '']
            : [
                oi.roxableServices
                  ?.filter(rs => {
                    return isRxLens(rs.orderItemExtendAttribute)
                  })
                  ?.map((rs: OrderItem) => {
                    return rs.orderItemId
                  }) || '',
              ]
        })
      )
    ).filter(id => id !== '')
  } catch (e) {
    Log.error('Could not get order item ids')
    return null
  }
}

export const getSingleItemsOrderIds = (oi?: OrderItem | OrderItemWithRoxProps): string[] | null => {
  try {
    return flattenDeep(
      uniq(
        isContactLensesProduct(oi)
          ? [oi?.orderItemId || '', oi?.sibilingOrderItem?.orderItemId || '']
          : [
              oi?.roxableServices
                ?.filter(rs => {
                  return isRxLens(rs.orderItemExtendAttribute)
                })
                ?.map((rs: OrderItem) => {
                  return rs.orderItemId
                }) || '',
            ]
      )
    ).filter(id => id !== '')
  } catch (e) {
    Log.error('Could not get single order item ids')
    return null
  }
}

export const getOrderItemsMap = (orderItems: OrderItem[]): ParsedOrderItemsMapByItemType | null => {
  try {
    const parsedOrderItems = getParsedOrderRecapItems(orderItems)
    const clOrderItems: OrderItem[] | null = parsedOrderItems?.filter(oi => isContactLensesProduct(oi)) || []
    const rxOrderItems: OrderItem[] | null = parsedOrderItems?.filter(oi => isRox(oi['orderItemExtendAttribute'])) || []
    const sunOrderItems: OrderItem[] | null =
      parsedOrderItems?.filter(oi => isSunProduct(oi) && !isRox(oi['orderItemExtendAttribute'])) || []
    const clAccessoriesOrderItems: OrderItem[] | null = parsedOrderItems?.filter(oi => isCLAccessoriesProduct(oi)) || []
    const defaultItems = orderItems?.filter(
      oi =>
        !isSunProduct(oi) &&
        !isContactLensesProduct(oi) &&
        !isCLAccessoriesProduct(oi) &&
        !isRox(oi['orderItemExtendAttribute'])
    )

    return {
      rx: rxOrderItems,
      cl: clOrderItems,
      'cl-acc': clAccessoriesOrderItems,
      sun: sunOrderItems,
      default: defaultItems,
    }
  } catch (e) {
    Log.error('Could not parse order items')
    return null
  }
}

/**
 * @returns count of products in cart, i.e. CL, Accessories, SGL, and RX.
 * This is the count of items presented to the customer and not the actual
 * number of cart items.
 */
export const getCartProductCount = (orderItems: OrderItem[]): number => {
  const parsedItems = getParsedOrderItems(orderItems) || []

  return parsedItems?.filter(item => {
    return (
      isContactLensesProduct(item) ||
      isCLAccessoriesProduct(item) ||
      isAccessoriesProduct(item) ||
      isElectronicsProduct(item) ||
      isOpticalProduct(item) ||
      (isSunProduct(item) && !isRxFrame(item['orderItemExtendAttribute'])) ||
      isRxFrame(item['orderItemExtendAttribute'])
    )
  }).length
}

export const getPrescriptionItemsMap = (
  orderItems: OrderItem[] | OrderItemWithRoxProps[],
  filterPrescriptionNeededItems?: boolean,
  filterPrescriptionItemType?: PrescriptionItemType,
  isDesktop?: boolean
): PrescriptionMacroGroup[] | null => {
  let prescriptionMacroGroups: PrescriptionMacroGroup[] = []
  let orderItemsPrescriptionMap: PrescriptionItemsMapByType = []
  try {
    const parsedOrderItems = getParsedOrderRecapItems(orderItems)
    const clOrderItems: OrderItem[] | null = filterPrescriptionNeededItems
      ? parsedOrderItems?.filter(oi => isContactLensesProduct(oi) && !oi.prescriptionUploaded) || null
      : parsedOrderItems?.filter(oi => isContactLensesProduct(oi)) || null

    const rxOrderItems: OrderItem[] | null = filterPrescriptionNeededItems
      ? parsedOrderItems?.filter(oi => isRox(oi['orderItemExtendAttribute']) && !oi.prescriptionUploaded) || null
      : parsedOrderItems?.filter(oi => isRox(oi['orderItemExtendAttribute'])) || null

    rxOrderItems &&
      rxOrderItems.length > 0 &&
      orderItemsPrescriptionMap.push({
        data: {
          type: 'rx',
          items: rxOrderItems || [],
        },
      })

    clOrderItems &&
      clOrderItems.length > 0 &&
      orderItemsPrescriptionMap.push({
        data: {
          type: 'cl',
          items: clOrderItems || [],
        },
      })

    orderItemsPrescriptionMap = !!filterPrescriptionItemType
      ? orderItemsPrescriptionMap.filter(group => group.data.type === filterPrescriptionItemType)
      : orderItemsPrescriptionMap
    prescriptionMacroGroups = orderItemsPrescriptionMap.map((group, i) => {
      return {
        id: i,
        active: i === 0 ? true : false,
        selectedItemIndex: 0,
        isSamePrescriptionSelected: isDesktop ? true : false,
        orderItems: group.data.items || [],
        skippedrPrescriptionItems: [],
        prescriptionData: {
          orderItemId: !!group.data.items ? getAllCartItemsOrderIds(group.data.items || [])?.join(',') : '',
        },
        completedPrescriptionItems: [],
        itemType: group.data.type,
      }
    })
    return prescriptionMacroGroups
  } catch (e) {
    Log.error('Could not parse prescription order items')
    return null
  }
}

export const getFlattenParsedOrderItemsList = (
  obj: ParsedOrderItemsMapByItemType
): OrderItem[] | OrderItemWithRoxProps[] | null => {
  let flattenItemsMap: OrderItem[] | OrderItemWithRoxProps[] = []
  try {
    for (let key in obj) {
      if (!!obj[key]) {
        const orderItems = obj[key] || []
        flattenItemsMap.push(...orderItems)
      }
    }
    return flattenItemsMap
  } catch (e) {
    return null
  }
}

export const isOrderComplete = (orderStatus: string, orderPaymentMethodId): boolean => {
  try {
    switch (true) {
      case [ORDER_STATUS.Created, ORDER_STATUS.PendingPrescription, ORDER_STATUS.Hold].includes(orderStatus) &&
        orderPaymentMethodId === PAYMENT_METHODS.CHECKOUT_NAMES.APPLE_PAY:
        return true

      case [ORDER_STATUS.Created, ORDER_STATUS.PendingPrescription, ORDER_STATUS.Hold].includes(orderStatus):
        return true
      default:
        return false
    }
  } catch (e) {
    return false
  }
}

export const fetchOrderItemDetailsByIds = (catentryIdList: string[]) => {
  const promiseArray: Promise<any>[] = []
  const ids = chunk(catentryIdList, 50)
  ids.forEach(id => {
    const params = {
      id,
      profileName: 'LX_findItemByIds_Details',
    }
    promiseArray.push(
      RequestService.request({
        extraParams: { siteContextKey: 'search' },
        method: 'GET',
        path: '/api/v2/products',
        queryParams: params,
      })
    )
  })
  return Promise.all(promiseArray).then(rs => {
    let contents = []
    rs.forEach(r => {
      if (r?.contents) {
        contents = contents.concat(r.contents)
      }
    })
    return contents
  })
}

export const fetchOrderItemsPrescriptionData = async (
  orderItems: OrderItem[] | OrderItemWithRoxProps[],
  storeId: string,
  orderId: string
): Promise<OrderItem[] | OrderItemWithRoxProps[]> => {
  const orderItemsPromises = orderItems?.map(async (oi: OrderItem | OrderItemWithRoxProps) => {
    let orderItemObj: OrderItem | OrderItemWithRoxProps = {
      ...oi,
    }

    if (isRxLens(oi.orderItemExtendAttribute) || isClOrderItem(oi.orderItemExtendAttribute)) {
      try {
        let orderPrescriptionDetailsRes: PrescriptionDetailsResponse | null
        let orderPrescriptionDetailsImage: any | null

        try {
          orderPrescriptionDetailsRes = isRxLens(oi.orderItemExtendAttribute)
            ? await RequestService.request({
                method: 'GET',
                path: `/store/${storeId}/prescription/orderId/${orderId}/orderItemId/${oi.orderItemId}`,
              })
            : null
          !!orderPrescriptionDetailsRes?.results
            ? (orderItemObj = {
                ...orderItemObj,
                prescriptionDetails: {
                  data: orderPrescriptionDetailsRes.results || null,
                },
              })
            : (orderItemObj = oi)
        } catch (error: any) {
          Log.error(`Could not retrieve order details prescription details: ${error}`)
          throw new Error(error)
        }
        try {
          //only rx have prescription details
          //cl only show prescription image
          const shouldGetPrescriptionFile: boolean =
            (isRxLens(oi.orderItemExtendAttribute) &&
              !!oi.orderItemExtendAttribute.find(
                a => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES['RX_FILE_UPLOAD_DATE']
              ) &&
              !!orderPrescriptionDetailsRes?.results.fileName) ||
            (isClOrderItem(oi.orderItemExtendAttribute) &&
              !!oi.orderItemExtendAttribute.find(
                a => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES['RX_FILE_UPLOAD_DATE']
              )) ||
            false

          orderPrescriptionDetailsImage =
            (shouldGetPrescriptionFile &&
              (await RequestService.requestForRtk({
                method: 'GET',
                path: `/store/${storeId}/contactLens/rxUploadedFile/orderId/${orderId}/orderItemId/${oi.orderItemId}`,
                extraParams: {
                  responseType: 'arraybuffer',
                },
              }))) ||
            null

          !!orderPrescriptionDetailsImage
            ? (orderItemObj = {
                ...orderItemObj,
                prescriptionDetails: {
                  ...orderItemObj.prescriptionDetails,
                  file: {
                    content: orderPrescriptionDetailsImage.data || null,
                    type: orderPrescriptionDetailsImage.headers['content-type'] || null,
                  },
                },
              })
            : (orderItemObj = orderItemObj)
          return orderItemObj
        } catch (error: any) {
          Log.error(`Could not retrieve order details prescription image: ${error}`)
          throw new Error(error)
        }
      } catch (error: any) {
        Log.error(`Could not retrieve order details prescription data: ${error}`)
        //Cannot retrieve prescription details; return order items as-is
        return orderItemObj
      }
    } else {
      //Order item has no prescription; return order items as-is
      return orderItemObj
    }
  })
  const res = await Promise.all(orderItemsPromises)
  return res
}

export const getOrderItemCatEntries = async (
  catentriesIdArray: string[]
): Promise<IOrderSliceState['catentries'] | undefined> => {
  try {
    let catentries: IOrderSliceState['catentries']
    const catentryIdList = [...new Set(catentriesIdArray)]
    const contents = await fetchOrderItemDetailsByIds(catentryIdList)
    if (contents) {
      catentries = contents.reduce((acc, p: any) => {
        acc[p.id] = p
        return acc
      }, {})
    }
    return catentries
  } catch (error: any) {
    Log.error('Could not retrieve products: ' + error)
    //Cannot retrieve catentry details; return order items as-is
    return undefined
  }
}

/**
 * function to retrive returned status of order.
 * It takes first status, but if order has more then one statuses and one of is `CAN` we have to return other status.
 * Example states = [CAN, CLO] => CLO
 * @param { Order } order current order
 */
export const getOrderReturnedStatus = (order: Order | OrderItem): string | null => {
  const isOrderItem = (order: Order | OrderItem): order is Order => 'orderExtendAttribute' in order

  let orderStatuses: Attribute<string>[] = []
  if (isOrderItem(order)) {
    orderStatuses = order.orderExtendAttribute.filter(s => s.attributeName.includes('RMAStatus'))
  } else {
    orderStatuses = order?.orderItemExtendAttribute?.filter(s => s.attributeName.includes('RMAItemStatus'))
  }

  const orderCANstatus = orderStatuses?.find(s => s.attributeValue === 'CAN')

  const status =
    orderStatuses?.length > 1 && orderCANstatus
      ? orderStatuses.find(s => s.attributeValue !== 'CAN')?.attributeValue
      : orderStatuses?.[0]?.attributeValue

  return status || null
}

const getDiscounts = (orderItem?: OrderItem | null) => {
  const discounts = orderItem?.adjustment?.filter(({ usage }) => usage !== 'Shipping Adjustment') || []

  return discounts
}

export const getPromoCodeDiscount = (orderItem?: OrderItem | null) => {
  if (!orderItem) return 0
  const discounts = getDiscounts(orderItem)

  const promoCode = discounts.find(({ code }) => !code.includes('CL_CUSTOM_PROMO'))

  return promoCode ? Number(promoCode?.amount.replace('-', '')) : 0
}

export const getAnnualDiscounts = (orderItem?: OrderItem | null) => {
  if (!orderItem) return 0
  const discounts = getDiscounts(orderItem)

  const hasSupplyData = getAnnualSupplyBadge(orderItem) || ''

  const hasDiscount = hasSupplyData && discounts.find(({ code }) => code.includes('CL_CUSTOM_PROMO'))

  return hasDiscount ? Number(hasDiscount.amount.replace('-', '')) : 0
}

export const isDiscountOnItemLevel = (orderItem?: OrderItem | null) => {
  if (!orderItem) return false
  const discounts = getDiscounts(orderItem)

  const promoCode = discounts.find(({ code }) => !code.includes('CL_CUSTOM_PROMO'))

  return promoCode?.displayLevel === 'OrderItem'
}

export const getShipModeWithLongestLeadTime = (usableShippingModes: UsableShippingMode[]) => {
  const MAX_LEAD_TIME = 'maxLeadTime'
  const nonRoxLensUsableShippingModes = usableShippingModes?.filter(
    shipMode => shipMode.productType !== ProductTypesEnum.RoxLens
  )
  // Using 'Express' below as that's the common ship method across all locales.
  const longestLeadTime =
    nonRoxLensUsableShippingModes?.sort(
      (shipModeA, shipModeB) =>
        Number(
          shipModeB?.dedicatedShippingMode?.filter(sm => sm.shipModeCode === ShippingMethodsEnum.Express)?.[0]?.[
            MAX_LEAD_TIME
          ]
        ) -
        Number(
          shipModeA?.dedicatedShippingMode?.filter(sm => sm.shipModeCode === ShippingMethodsEnum.Express)?.[0]?.[
            MAX_LEAD_TIME
          ]
        )
    )?.[0]?.dedicatedShippingMode || usableShippingModes?.[0]?.dedicatedShippingMode
  return longestLeadTime
}

const getLeadTime = (liveStock: LiveStock[], items: OrderItem[]): number => {
  /*
    Number returned by live stock checker is lead time (assembly SLA).
  */
  return Number(liveStock.find(ls => ls.shipModeCode === items[0].shipModeCode)?.maxLeadTime)
}

const getClLiveStockMaxLeadTime = (items: OrderItem[], mySite: SiteInfo, liveStock?: LiveStock[]): number => {
  if (items == null || items.length === 0) {
    return -1
  }
  if (IS_CUT_OVER) {
    if (liveStock) {
      const leadTime = getLeadTime(liveStock, items)
      if (leadTime === 0) {
        return CUT_OVER_IN_STOCK_BUFFER[mySite.country]
      } else {
        return leadTime + CUT_OVER_OUT_OF_STOCK_BUFFER[mySite.country]
      }
    } else {
      return -1
    }
  } else {
    return (liveStock && getLeadTime(liveStock, items)) || -1
  }
}

export const getAssemblyDaysRange = (productSlaMinDays: number, orderItems: OrderItem[]) => {
  if (isClOrClAccessoryExclusiveOrder(orderItems)) {
    return `${productSlaMinDays}`
  }
  return `${productSlaMinDays} - ${
    productSlaMinDays + (isRxItemInOrder(orderItems) ? SLA_BUFFER_RX : SLA_BUFFER_PLANO)
  }`
}

export const getShippingAssemblyDaysRange = (
  shippingModes: DedicatedShippingMode[],
  shipModeCode: ShippingMethods,
  orderItems: OrderItem[]
) => {
  const shippingModeList = shippingModes.find(s => s.shipModeCode === shipModeCode)
  if (shippingModeList) {
    if (isClOrClAccessoryExclusiveOrder(orderItems) || shippingModeList.minLeadTime === shippingModeList.maxLeadTime) {
      return `${shippingModeList.minLeadTime}`
    }
    return `${shippingModeList.minLeadTime} ${HYPHEN} ${shippingModeList.maxLeadTime}`
  }
  return ''
}

export const getProductSlaMinDays = (
  orderItems: OrderItem[],
  country: string,
  mySite: SiteInfo,
  liveStock?: LiveStock[]
) => {
  const clAssemblyDays = getClLiveStockMaxLeadTime(
    orderItems.filter(item => isContactLensesProduct(item) || isCLAccessoriesProduct(item)),
    mySite,
    liveStock
  )
  const rxAssemblyDays = isRxItemInOrder(orderItems) ? MIN_SLA_RX[country] : 0
  const planoAssemblyDays = isPlanoItemInOrder(orderItems) ? MIN_SLA_PLANO[country] : 0

  const minDayCount = Math.max(clAssemblyDays, rxAssemblyDays, planoAssemblyDays)
  return minDayCount
}

export const isRxItemInOrder = (orderItems: OrderItem[]): boolean => {
  return !!orderItems.find(item => isRoxableProduct(item))
}

export const isRxFrameInOrder = (orderItems: OrderItem[]): boolean => {
  return orderItems.filter(item => isRxFrame(item['orderItemExtendAttribute'])).length > 0
}

export const isContactLensInOrder = (orderItems: OrderItem[]): boolean => {
  return orderItems.filter(item => isContactLensesProduct(item)).length > 0
}

export const isClAccessoryInOrder = (orderItems: OrderItem[]): boolean => {
  return orderItems.filter(item => isCLAccessoriesProduct(item)).length > 0
}

export const isPlanoItemInOrder = (orderItems: OrderItem[]): boolean => {
  if (
    orderItems.find(
      item =>
        !(isRxProduct(item.orderItemExtendAttribute) || isContactLensesProduct(item) || isCLAccessoriesProduct(item))
    )
  ) {
    return true
  }
  return false
}

/**
 * Checks whether order has only CL or CL accessory
 *
 * @param orderItems
 * @returns true if order has only CL or CL accessory
 */
export const isClOrClAccessoryExclusiveOrder = (orderItems: OrderItem[]): boolean => {
  return !orderItems.find(item => !(isContactLensesProduct(item) || isCLAccessoriesProduct(item)))
}

export const getShippingCode = (orderItem: OrderItem): ShippingMethods => {
  const shippingName = orderItem?.shipModeCode
  /* Non-standard data has a trailing ' Shipping'
    in some cases which should be removed. */
  return shippingName ? (shippingName?.replace(' Shipping', '') as ShippingMethods) : ShippingMethodsEnum.Standard
}

export const isExceptionalAddress = (
  addressId: string,
  shippingAddressData?: AddressFormData,
  userDetails?: PersonResponse
): boolean => {
  if (!addressId || addressId === NEW_SHIPPINNG_ADDRESS) {
    return (
      shippingAddressData != null &&
      shippingAddressData.apartmentCheck === true &&
      (shippingAddressData?.buzzerCode == undefined || shippingAddressData?.buzzerCode === EMPTY_STRING)
    )
  } else {
    const selectedAddress = userDetails?.contact?.find(address => address.addressId === addressId)
    const { apartmentCheck, buzzerCode } = addressUtil.getAddressWithDelimiter(selectedAddress?.addressLine?.[1])
    return apartmentCheck && (buzzerCode == null || buzzerCode === EMPTY_STRING)
  }
}

export const getExceptionShipMethod = (
  shippingName: string,
  shippingModes?: DedicatedShippingMode[]
): DedicatedShippingMode | undefined => {
  return shippingModes?.find(shipMode => shipMode.shipModeCode === `${shippingName}${EXCEPTION}`)
}

export const getNonExceptionShipMethod = (
  shippingName: string,
  shippingModes?: DedicatedShippingMode[]
): DedicatedShippingMode | undefined => {
  return shippingModes?.find(shipMode => shipMode.shipModeCode === shippingName.replace(EXCEPTION, EMPTY_STRING))
}

export const getStandardShipMethod = (
  shippingName: string,
  shippingMode?: DedicatedShippingMode[]
): DedicatedShippingMode | undefined => {
  return shippingMode?.find(shipMode =>
    shippingName.endsWith(EXCEPTION)
      ? shipMode.shipModeCode === ShippingMethodsEnum.StandardException
      : shipMode.shipModeCode === ShippingMethodsEnum.Standard
  )
}

export const isAddressEligibleForMoreThanStandard = (zipCode?: string): boolean => {
  if (zipCode) {
    // FSA is always the first three characters of zip code
    return !STANDARD_ONLY_FSA_CODES.includes(zipCode.substring(0, 3))
  }
  return true
}

export const getPayload = (mySite: SiteInfo, displayName: React.ComponentType<any> | string): CheckoutPayload => {
  const widgetName = getDisplayName(displayName)
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const payloadBase: CheckoutPayload = {
    currency: mySite.defaultCurrencyID,
    contractId: mySite.contractId,
    widget: widgetName,
    storeId: mySite.storeID,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }
  return payloadBase
}

export const getPartNumberQueryString = (orderItems: OrderItem[]): string => {
  return orderItems.flatMap(item => `partnumber=${item.partNumber}`).join('&')
}

export const isValidOrderItemsInReorder = (reorderInfo?: IReorderInfo, orderItems?: OrderItem[]): boolean => {
  return (
    (reorderInfo?.orderItems &&
      reorderInfo?.orderItems.length > 0 &&
      isValidOrderItems(orderItems) &&
      localStorageUtil.get(IS_REORDER_SUCCESS) === true) ||
    false
  )
}

export const isValidOrderItems = (orderItems?: OrderItem[]): boolean => {
  return (orderItems && orderItems.length > 0) || false
}

export const clearReorderLocalStorage = (): void => {
  localStorageUtil.remove(REORDER_ORDER_ID)
  localStorageUtil.remove(REORDER_OLD_ORDER_ID)
  localStorageUtil.remove(REORDER_BILLING_ADDRESS_ID)
  localStorageUtil.remove(BILLING_ADDRESS_ID)
  localStorageUtil.remove(IS_REORDER_SUCCESS)
}

export const orderHasPrescriptionUploadedV2 = (oi: OrderItem | OrderItemWithRoxProps): boolean => {
  try {
    return !!oi.prescriptionDetails
  } catch (e) {
    return false
  }
}

export const isMtoOrderItem = (orderItem: OrderItem) => orderItem.productAttributes?.['CL_MTO_ENABLED'] === 'True'
export const computeUpdateQuantityOnGroupedOrderItems = ({
  parsedOrderItems,
  orderItemId,
  quantity,
}: {
  parsedOrderItems: OrderItem[] | null
  orderItemId: string
  quantity: string
}) => {
  const orderItemGroupedData = parsedOrderItems?.find(item => item.orderItemId === orderItemId)
  const otherItemsTotalQuantityInGroup =
    orderItemGroupedData?.quantityPerItemsInGrouped
      ?.filter(item => item.orderItemId !== orderItemId)
      .reduce((total, item) => total + Number(item.quantity), 0) || 0
  return (Number(quantity) - otherItemsTotalQuantityInGroup).toString()
}
