import { HASH_NAVIGATION_TOP_OFFSET } from '@constants/navigation'
import { useWindowWidth } from '@hooks/useWindowWidth'
import { generateAnchorID, waitForLazyElementToLoad } from '@utils/page'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'

export const useCheckForHashInUrl = () => {
  const windowWidth = useWindowWidth()
  const { asPath } = useRouter()
  const urlHash = asPath.split('#')[1]

  const checkHashInUrl = useCallback(
    async (hash: string) => {
      if (hash && windowWidth > 0) {
        const targetElement = await waitForLazyElementToLoad(`#${generateAnchorID(decodeURI(hash).replace('#', ''))}`)
        if (targetElement) {
          let stickyElementsHeight = 0
          const hasStickyAnchorModule = await waitForLazyElementToLoad('.anchor-buttons', 1000)
          if (hasStickyAnchorModule) {
            stickyElementsHeight += hasStickyAnchorModule.clientHeight
          }

          const targetTop =
            targetElement.getBoundingClientRect().top -
            (windowWidth >= 1440 ? HASH_NAVIGATION_TOP_OFFSET : 0) -
            stickyElementsHeight

          const mainElement = document.querySelector('main')
          if (mainElement) mainElement.scrollTo({ top: targetTop, behavior: 'smooth' })
        }
      }
    },
    [windowWidth]
  )

  useEffect(() => {
    checkHashInUrl(urlHash)
  }, [checkHashInUrl, urlHash])
}
