export const ACCOUNT_ICONS = {
  fileName: '/images/icons/account-sprite.svg',
  icons: [
    'glasses-cross',
    'insurance-check',
    'return',
    'shipping',
    'edit',
    'trash-bin',
    'download',
    'upload',
    'triangle-danger',
    'copy',
    'add',
    'show',
    'hide',
  ],
} as const
