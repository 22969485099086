import {
  PRODUCT_PRICE_USAGE_NAMES,
  PriceModel,
  ProductType,
  IProduct,
  ServerProductXPrice,
  ServerProductPrice,
} from '../types/product'
import {
  getAlreadyDisplayed,
  getFrontColor,
  getLensesColor,
  getLensesColorFacet,
  getNew,
  getProductType,
  getSoldOut,
  Product,
} from '@utils/productAttributes'
import {
  getFrontColor as getFrontColorAlgolia,
  getLensesColor as getLensesColorAlgolia,
  getLensesColorFacet as getLensesColorFacetAlgolia,
  getProductType as getProductTypeAlgolia,
} from './productAttributesAlgolia'

import CurrencyService from '../services/CurrencyService'
import { IFacet } from '../features/plp/query'
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_MAP } from '../constants/product'
import { isOptical } from './product'

class ProductUtils {
  getCurrentPrice(prices: ServerProductPrice[]): number {
    const priceModel = this.getCurrentPriceModel(prices)

    return priceModel?.value ? +priceModel.value : NaN
  }

  getCurrentPriceModel(prices: ServerProductPrice[]): ServerProductPrice | null {
    return prices?.find(p => !!p && p.usage === PRODUCT_PRICE_USAGE_NAMES.CURRENT) || null
  }

  getInitialPrice(prices: ServerProductPrice[]): number {
    const priceModel = this.getInitialPriceModel(prices)

    return priceModel?.value ? +priceModel.value : NaN
  }

  getInitialPriceModel(prices: ServerProductPrice[]): ServerProductPrice | null {
    return prices?.find(p => !!p && p.usage === PRODUCT_PRICE_USAGE_NAMES.INITIAL) || null
  }

  genPriceWithoutValue(prices: ServerProductPrice[]) {
    return prices.map(price => {
      if (!price.value) {
        const priceWithBaseValue = prices.find(price => !!price.value)

        return {
          ...price,
          value: priceWithBaseValue?.value || '0',
        }
      }

      return price
    })
  }

  calculateTotalPriceModel(prices: ServerProductPrice[]): ServerProductPrice {
    return prices.reduce((total, price) => {
      return { ...total, ...price, value: String(+total.value + +price.value) }
    })
  }

  getProductType(product?: IProduct): ProductType {
    return product ? PRODUCT_TYPES_MAP[getProductType(product).toLowerCase()] : PRODUCT_TYPES_MAP.frames
  }

  getAlgoliaProductType(product): ProductType {
    return product ? PRODUCT_TYPES_MAP[getProductTypeAlgolia(product).toLowerCase()] : PRODUCT_TYPES_MAP.frames
  }

  /** Used in ProductTile in MoCos slider */
  getProductColorLabel(product: IProduct): string {
    const productType = this.getProductType(product)

    switch (productType) {
      case PRODUCT_TYPES_MAP.accessories:
        return getFrontColor(product)
      case PRODUCT_TYPES_MAP.optical:
        return getFrontColor(product)
      case PRODUCT_TYPES_MAP.sun:
        const frontColor = getFrontColor(product)
        const lensColor = getLensesColor(product) || getLensesColorFacet(product)

        return frontColor === lensColor ? frontColor : `${frontColor} ${lensColor}`.trim()
      default:
        return ''
    }
  }

  /** Used in ProductTile in MoCos slider */
  getAlgoliaProductColorLabel(product: IProduct): string {
    const productType = this.getAlgoliaProductType(product)

    switch (productType) {
      case PRODUCT_TYPES_MAP.accessories:
        return getFrontColorAlgolia(product)
      case PRODUCT_TYPES_MAP.optical:
        return getFrontColorAlgolia(product)
      case PRODUCT_TYPES_MAP.sun:
        const frontColor = getFrontColorAlgolia(product)
        const lensColor = getLensesColorAlgolia(product) || getLensesColorFacetAlgolia(product)

        return frontColor === lensColor ? frontColor : `${frontColor} ${lensColor}`.trim()
      default:
        return ''
    }
  }

  combineAlgoliaOpticalPrices = (frameCatEntries, algoliaPrices) => {
    let combinedAlgoliaPrices = { ...algoliaPrices }

    const opticalProducts = frameCatEntries.filter(p => {
      const productype = getProductType(p)
      return isOptical(productype)
    })
    opticalProducts.forEach(product => {
      let currentPrice = combinedAlgoliaPrices[product.partNumber]
      if (currentPrice) {
        combinedAlgoliaPrices[product.partNumber] = {
          ...currentPrice,
          offerPrice: product.rxOfferPrice || currentPrice.offerPrice,
          listPrice: product.rxPrice || currentPrice.listPrice,
        }
      }
    })

    return combinedAlgoliaPrices
  }

  findPriceFacetByName(name: string) {
    return name.startsWith('OfferPrice_')
  }

  formatPriceFacet(priceFacet: IFacet, rangeStartPrefix: string, rangeEndPrefix: string): IFacet {
    const priceCurrencyName = priceFacet.value.replace('price_', '').toUpperCase()
    const currencySymbol = CurrencyService.getSymbolByName(priceCurrencyName)

    return {
      ...priceFacet,
      entry: priceFacet.entry.map(v => ({
        ...v,
        count: `${v.count}`,
        label: v.label.replace(/\({([\*\d.]*)\sTO\s([\*\d.\s]*)]\)/g, (_match, rangeStart, rangeEnd) => {
          if (rangeStart.trim() === '*') {
            return `${rangeStartPrefix} ${currencySymbol + rangeEnd}`
          } else if (rangeEnd.trim() === '*') {
            return `${rangeEndPrefix} ${currencySymbol + rangeStart}`
          } else {
            return `${currencySymbol + rangeStart} - ${currencySymbol + rangeEnd}`
          }
        }),
      })),
    }
  }
  isValidXPriceDate(price: ServerProductXPrice) {
    // fix for Safari https://luxotticaretail.atlassian.net/browse/DC-1751
    // & Firefox https://luxotticaretail.atlassian.net/browse/DC-2863

    const fixDate = dateString =>
      dateString
        .replace(/-/g, '/')
        .slice(0, -2)
        .replace(/\..{4}/, '')

    const startDate = price?.startDate && fixDate(price?.startDate)
    const endDate = price?.endDate && fixDate(price?.endDate)

    const isValidStartDate = startDate && new Date(startDate) < new Date()
    const isValidEndDate = endDate === undefined || (endDate && new Date(endDate) > new Date())

    return isValidStartDate && isValidEndDate
  }

  getXPriceModel(xprice: ServerProductXPrice): PriceModel | null {
    const checkDates = this.isValidXPriceDate(xprice)
    if (!checkDates) return null

    return { currency: xprice.currency, price: xprice.price }
  }

  isProductDiscontinued(currentProduct: Product) {
    const alreadyDisplayed = getAlreadyDisplayed(currentProduct)
    const isNew = getNew(currentProduct)
    const soldOut = getSoldOut(currentProduct)

    return soldOut === PRODUCT_SOLDOUT_STATUS.SOLDOUT && (Boolean(alreadyDisplayed) || !Boolean(isNew))
  }
}
const productUtils = new ProductUtils()
export default productUtils
