import { VMInitParams } from '@typesApp/virtualMirror'
import dynamicConfigs from '../../configuration/config.json'

const boolean = (value: string | undefined) => {
  return Boolean(value && value.toLowerCase && value.toLowerCase() === 'true')
}

const enableAnalytics = () => {
  return boolean(process.env.NEXT_PUBLIC_ENABLE_ANALYTICS) || true
}

const enableMonetateHistoryTracker = () => {
  /**
   * NOTE: The history tracker script is loaded via Monetate on production.
   * So we only need to load it manually on lower environments.
   */
  return boolean(process.env.NEXT_PUBLIC_HISTORY_TRACKER_ENABLED) ?? false
}

const enableAlgoliaRecommendations = () => {
  return boolean(process.env.NEXT_PUBLIC_ALGOLIA_RECOMMENDATIONS_ENABLED) || false
}

const env = process.env

const baseConfig = {
  publicUrl: env['NEXT_PUBLIC_URL'] ?? '/',
  routerBaseName: env['NEXT_PUBLIC_ROUTER_BASENAME'] as string,
  name: env['NEXT_PUBLIC_STORENAME'] as string,
  storeGroup: env['NEXT_PUBLIC_STOREGROUP'] as string,
  env: 'production',
  log2api: true, // used to trace logs to api server.
  cmsApiUrl: env['NEXT_PUBLIC_API_URL'] as string,
  cmsApiUrlInternal: env['NEXT_PUBLIC_API_URL_INTERNAL'] as string,
  logServerUrl: env['NEXT_PUBLIC_LOG_SERVER_URL'] as string,
  tabApiUrl: env['NEXT_PUBLIC_API_TAB_URL'] as string,
  maintenanceServiceUrl: env['NEXT_PUBLIC_MAINTENANCE_CHECK_URL'] as string,
  maintenanceStatus: 418, // https://luxotticaretail.atlassian.net/browse/ARNETTE-1935
  cmsImageServerUrlPreconnect: env['NEXT_PUBLIC_CMS_IMAGES_URL_PRECONNECT'] as string,
  cmsImageServerUrl: env['NEXT_PUBLIC_CMS_IMAGES_URL'] as string,
  defaultCountry: env['NEXT_PUBLIC_DEFAULT_COUNTRY'] as string,
  defaultLocale: env['NEXT_PUBLIC_DEFAULT_LOCALE'] as string,
  availableLocales: env['NEXT_PUBLIC_LOCALES']?.split(',') || [],
  storePrefix: env['NEXT_PUBLIC_STOREPREFIX'] as string,
  defaultDamDomain: env['NEXT_PUBLIC_DAM_DOMAIN'] as string,
  useMockedAttachments: env['NEXT_PUBLIC_USE_MOCKED_ATTACHMENTS'] as string,
  isCmsPreviewEnabled: env['NEXT_PUBLIC_PREVIEW_ENABLED'] as string,
  socialLogin: env['NEXT_PUBLIC_SOCIAL_LOGIN_REDIRECT_URL'] as string,
  isSubscriptionEnabled: env['NEXT_PUBLIC_SUBSCRIPTION_ENABLED'] === 'true',
  isSoldOutFeatureEnabled: env['NEXT_PUBLIC_ENABLE_SOLD_OUT_FEATURE'] === 'true',
  isEarlyAccessFeatureEnabled: env['NEXT_PUBLIC_ENABLE_EARLY_ACCESS_FEATURE'] === 'true',
  isOneClickReorderEnabled: env['NEXT_PUBLIC_ONE_CLICK_REORDER_ENABLED'] === 'true',
  isClAccessoriesStockCheckEnabled: env['NEXT_PUBLIC_CL_ACCESSORIES_STOCK_CHECK_ENABLED'] === 'true',
  subscriptionAllowedProducts: env['NEXT_PUBLIC_SUBSCRIPTION_ALLOWED_PRODUCTS']?.split(',') || [],
  permamentStoreDays: 30,
  apiCalltimeout: 500000,
  catalog: {
    topCategoriesDepthLimit: '11,11',
    productionListFields: 'facetView',
    keywordLimit: 4,
  },
  pagination: {
    pageLimit: 12,
    pageDefaultOffset: 0,
  },
  orders: {
    calculationUsage: '-1,-2,-3,-4,-5,-6,-7',
    calculateOrder: '1',
    inventoryValidation: true,
    allocate: '***',
    backOrder: '***',
    remerge: '***',
    check: '*n',
    sortOrderItemBy: 'orderItemID',
    pagination: {
      pageLimit: 10,
      pageSizeOptions: [10, 25, 50],
    },
  },
  enableAnalytics: enableAnalytics(),
  tealiumAnalytics: {
    profile: '',
    shopperSlug: '',
    gtagId: '',
    isEnabled: false,
  },
  googleAnalytics: {
    id: '',
    digitalCatalogId: '',
    shopperSlug: '',
    gtagId: '',
  },
  vmmv: {
    fallbackScript: env['NEXT_PUBLIC_VMMV_FALLBACK_SCRIPT'] as string,
    source: env['NEXT_PUBLIC_VMMV_SOURCE'] as string,
    key: env['NEXT_PUBLIC_VMMV_KEY'] as string,
    channel: env['NEXT_PUBLIC_VMMV_CHANNEL'] as string,
    brand: env['NEXT_PUBLIC_VMMV_BRAND'] as string,
    type: env['NEXT_PUBLIC_VMMV_TYPE'] as string,
    fromStore: env['NEXT_PUBLIC_VMMV_FROM_STORE'] === 'true',
    isTryOnEnabled: env['NEXT_PUBLIC_VMMV_TRY_ON_ENABLED'] === 'true',
    isTakeScreenshotEnabled: env['NEXT_PUBLIC_VMMV_TAKE_SCREENSHOT_ENABLED'] === 'true',
    isPictureModeEnabled: env['NEXT_PUBLIC_VMMV_PICTURE_MODE_ENABLED'] === 'true',
    isUploadPictureEnabled: env['NEXT_PUBLIC_VMMV_UPLOAD_PICTURE_ENABLED'] === 'true',
    isTakePictureEnabled: env['NEXT_PUBLIC_VMMV_TAKE_PICTURE_ENABLED'] === 'true',
    isVideoModeEnabled: env['NEXT_PUBLIC_VMMV_VIDEO_MODE_ENABLED'] === 'true',
    isQrCodeEnabled: env['NEXT_PUBLIC_VMMV_QR_CODE_ENABLED'] === 'true',
    glassesEnv: env['NEXT_PUBLIC_VMMV_GLASSES_ENV'] as VMInitParams['glassesEnv'],
  },
  rxc: {
    enableRxcV3: env['NEXT_PUBLIC_RXC3_ENABLE'] === 'true',
    rxcV3Src: env['NEXT_PUBLIC_RXC3_SRC'] as string,
    enableGrayout: env['NEXT_PUBLIC_RXC_ENABLE_GRAYOUT'] === 'true',
    enableLargeIcons: env['NEXT_PUBLIC_RXC_ENABLE_LARGE_ICONS'] === 'true',
  },
  frameGenius: {
    script: 'https://frameadvisor-uat.luxottica.com/v/2.5/index.umd.js?a=1',
    frameAdvKey: env['NEXT_PUBLIC_FASA_KEY'] as string,
    //TODO: Move this in a storeconf
    isFrameAdvisorEnabled: true,
    //TODO: Move this in a storeconf
    isSizeAdvisorEnabled: true,
    //TODO: Move this in a storeconf
    facescanPrivacy: {
      privacyPolicy: 'https://www.ray-ban.com/italy/c/politica-privacy',
      termsAndConditions: 'https://www.ray-ban.com/italy/c/termini-utilizzo',
    },
    frameAdvisor: {
      initPositionFloatingBtn: {
        x: 50,
        y: 150,
      },
      multibrand: true,
      enableVideoMode: false,
      productRequestRowsLimit: 24,
      isDebuggingModeEnabled: false,
    },
    source: env['NEXT_PUBLIC_FASA_SOURCE'] as string,
  },
  searchContext: env['NEXT_PUBLIC_SEARCH_CONTEXT'] as string,
  transactionContext: env['NEXT_PUBLIC_TRANSACTION_CONTEXT'] as string,
  directBilling: {
    host: env['NEXT_PUBLIC_DIRECT_BILLING_HOST'] as string,
  },
  envInstance: env['NEXT_PUBLIC_ENV_INSTANCE'] as string,
  monetate: {
    historyTrackerSrc: env['NEXT_PUBLIC_HISTORY_TRACKER_SOURCE'] as string,
    isHistoryTrackerEnabled: enableMonetateHistoryTracker(),
    source: env['NEXT_PUBLIC_MONETATE_SOURCE'] as string,
  },
  storeLocator: {
    server: env['NEXT_PUBLIC_STORE_LOCATOR_SERVER'] as string,
    gmap: env['NEXT_PUBLIC_STORE_LOCATOR_GMAP'] as string,
  },
  virtualMirror: {
    thumbnailServer: env['NEXT_PUBLIC_VIRTUALMIRROR_THUMBNAIL_SERVER'],
    logoServer: env['NEXT_PUBLIC_VIRTUALMIRROR_LOGO_SERVER'],
  },
  trustPilot: {
    apiKey: env['NEXT_PUBLIC_TRUST_PILOT_API_KEY'] as string,
  },
  algolia: {
    id: env['NEXT_PUBLIC_ALGOLIA_APP_ID'] as string,
    indexName: env['NEXT_PUBLIC_ALGOLIA_INDEX_NAME'] as string,
    apiKey: env['NEXT_PUBLIC_ALGOLIA_API_KEY'] as string,
    groupedSufix: env['NEXT_PUBLIC_ALGOLIA_GROUPED_SUFIX'] as string,
    ungroupedSufix: env['NEXT_PUBLIC_ALGOLIA_UNGROUPED_SUFIX'] as string,
    newestSufix: env['NEXT_PUBLIC_ALGOLIA_NEWEST_SUFIX'] as string,
    querySuggestionsSufix: env['NEXT_PUBLIC_ALGOLIA_QUERY_SUGGESTIONS_SUFIX'] as string,
    ascSufix: env['NEXT_PUBLIC_ALGOLIA_ASC_SUFIX'] as string,
    descSufix: env['NEXT_PUBLIC_ALGOLIA_DESC_SUFIX'] as string,
    guestSegment: env['NEXT_PUBLIC_ALGOLIA_GUEST_SEGMENT'] as string,
    basePriceListSufix: env['NEXT_PUBLIC_ALGOLIA_BASE_PRICE_LIST_SUFIX'] as string,
    isRecommendationsEnabled: enableAlgoliaRecommendations(),
    recommendationsIndexName: env['NEXT_PUBLIC_ALGOLIA_RECOMMENDATIONS_INDEX_NAME'] as string,
  },
  genesys: {
    loadDelay: env['NEXT_PUBLIC_GENESYS_LOAD_DELAY'] as string,
    key: {
      en_au: env['NEXT_PUBLIC_GENESYS_WIDGET_KEY_EN_AU'] as string,
      en_ca: env['NEXT_PUBLIC_GENESYS_WIDGET_KEY_EN_CA'] as string,
      en_nz: env['NEXT_PUBLIC_GENESYS_WIDGET_KEY_EN_NZ'] as string,
      fr_ca: env['NEXT_PUBLIC_GENESYS_WIDGET_KEY_FR_CA'] as string,
    },
    url: {
      en_au: env['NEXT_PUBLIC_GENESYS_WIDGET_URL_EN_AU'] as string,
      en_ca: env['NEXT_PUBLIC_GENESYS_WIDGET_URL_EN_CA'] as string,
      en_nz: env['NEXT_PUBLIC_GENESYS_WIDGET_URL_EN_NZ'] as string,
      fr_ca: env['NEXT_PUBLIC_GENESYS_WIDGET_URL_FR_CA'] as string,
    },
  },

  transactionHost: env['NEXT_PUBLIC_TRANSACTION_HOST'],
  transactionHostInternal: env['NEXT_PUBLIC_TRANSACTION_HOST_INTERNAL'],
  forterSiteId: env['NEXT_PUBLIC_FORTER_SITE_ID'] as string,
  showShippingEstimanedDeliveryDate: env['NEXT_PUBLIC_SHOW_SHIPPING_ESTIMATED_DELIVERY_DATE'] === 'true',
  appBookEyeBenefitBarEnabled: env['NEXT_PUBLIC_APP_BOOK_EYE_BENEFIT_BAR_ENABLED'] === 'true',
  trustPilotEnabled: env['NEXT_PUBLIC_APP_TRUST_PILOT_ENABLED'],

  /* If we have the Early acess feature disabled, we filter out the locales that are available to Wunderkind */
  newsletterDrawerEnabledLocales:
    env['NEXT_PUBLIC_ENABLE_EARLY_ACCESS_FEATURE'] === 'true'
      ? env['NEXT_PUBLIC_APP_NEWSLETTER_DRAWER_POPUP_LOCALES']?.split(',') || []
      : env['NEXT_PUBLIC_APP_NEWSLETTER_DRAWER_POPUP_LOCALES']
          ?.split(',')
          ?.filter(loc => !env['NEXT_PUBLIC_WUNDERKIND_AVAILABLE_LOCALES']?.includes(loc)) || [],

  /* Wunderkind is automatically disabled if the early access feature is set to enabled */
  wunderkindEnabled:
    env['NEXT_PUBLIC_WUNDERKIND_INTEGRATION_ENABLED'] === 'true' &&
    env['NEXT_PUBLIC_ENABLE_EARLY_ACCESS_FEATURE'] !== 'true',
  wunderkindAvailableLocales: env['NEXT_PUBLIC_WUNDERKIND_AVAILABLE_LOCALES']?.split(',') || [],
  wunderkindNewsletterPrivacyLocales: env['NEXT_PUBLIC_WUNDERKIND_NEWSLETTER_PRIVACY_LOCALES']?.split(',') || [],
  wunderkindStoreKeys: {
    en_au: env['NEXT_PUBLIC_WUNDERKIND_EN_AU'] as string,
    en_ca: env['NEXT_PUBLIC_WUNDERKIND_EN_CA'] as string,
    en_nz: env['NEXT_PUBLIC_WUNDERKIND_EN_NZ'] as string,
    fr_ca: env['NEXT_PUBLIC_WUNDERKIND_FR_CA'] as string,
  },

  cacheContentMaxAge: Number(env['NEXT_CACHE_CONTENT_MAX_AGE'] || 300),
  cacheCategoryMaxAge: Number(env['NEXT_CACHE_CATEGORY_MAX_AGE'] || 300),
  cacheProductMaxAge: Number(env['NEXT_CACHE_PRODUCT_MAX_AGE'] || 600),
  cacheSwrFactor: Number(env['NEXT_CACHE_SWR_FACTOR'] || 2),
  applePayEnabled: false,
  klarnaPayEnabled: false,
  productImageAkamaiPolicy: env['NEXT_PUBLIC_PRODUCT_IMAGE_AKAMAI_POLICY'] as string,
  productImageAkamaiDefaultWidth: env['NEXT_PUBLIC_PRODUCT_IMAGE_AKAMAI_DEFAULT_WIDTH'] as string,
  productImageAkamaiDefaultBgColor: env['NEXT_PUBLIC_PRODUCT_IMAGE_AKAMAI_DEFAULT_BACKGROUND_COLOR'] as string,
  productImageRatioWidth: env['NEXT_PUBLIC_PRODUCT_IMAGE_RATIO_WIDTH'] as string,
  productImageRatioHeight: env['NEXT_PUBLIC_PRODUCT_IMAGE_RATIO_HEIGHT'] as string,
  dynatraceScriptSrc: env['NEXT_DYNATRACE_SCRIPT_SRC'] as string,
}

/* update config object with values for public-facing environments */
let configDynamic = {}
if (typeof window !== 'undefined') {
  const hostname = window.location.hostname

  /* check if not local env AND dynamicConfigs object contains mapping for current host */
  if ('localhost' !== hostname && Object.keys(dynamicConfigs).includes(hostname)) {
    configDynamic = dynamicConfigs[hostname]
  }
}

const config = { ...baseConfig, ...configDynamic }
export type AppConfig = typeof config

export default config
