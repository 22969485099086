//Standard libraries
import { createReducer, AnyAction } from '@reduxjs/toolkit'
import { ORDER_STATUS } from '../../constants/order'
//Redux
import initStates from './initStates'
import { OrderDetailsReducerState } from './reducerStateInterface'

import {
  FETCH_ORDER_DETAILS_SUCCESS_ACTION,
  FETCH_ORDER_ITEM_DETAILS_SUCCESS_ACTION,
  FETCH_ORDER_DETAILS_FAIL_ACTION,
  SET_ORDER_DETAILS_LOADINGS_ACTION,
  FETCH_ORDER_ITEM_DETAILS_FAIL_ACTION,
  RESET_ORDER_DETAILS_ACTION,
  RESET_INVALID_ORDERS_ACTION,
  ADD_FRAME_ROX_ORDER_DETAILS_ACTION,
} from '../actions/orderDetails'

/**
 * Order details reducer. Order details state store all order details per user session.
 * handles states used by order details components
 * @param state State object managed by OderDetails reducer
 * @param action The dispatched action
 */
const orderDetailsReducer = createReducer(initStates.orderDetails, builder => {
  builder.addCase(FETCH_ORDER_DETAILS_SUCCESS_ACTION, (state: OrderDetailsReducerState, action: AnyAction) => {
    const { orderId } = action.payload
    state.data[orderId] = action.payload
    state.data[orderId].orderComplete = action.payload.orderStatus === ORDER_STATUS.Created
  })

  builder.addCase(FETCH_ORDER_ITEM_DETAILS_SUCCESS_ACTION, (state: OrderDetailsReducerState, action: AnyAction) => {
    const { orderId, items } = action.payload
    const orderItems = state.data[orderId].orderItem

    if (items.length && items.length > 0) {
      const detailedOrderItems = orderItems.map(item => {
        const obj = {
          ...item,
          name: '',
          thumbnail: '',
          attributes: [],
          seo: { href: '' },
        }
        const filteredItem = (items as any[]).filter(i => String(i.id) === String(item.productId))[0] || {}
        const { id, name, thumbnail, attributes, seo } = filteredItem
        Object.assign(obj, { id, name, thumbnail, attributes, seo })
        return obj
      })
      Object.assign(state.data[orderId], { detailedOrderItems })
    }
    state.loadings = state.loadings.filter(id => id !== orderId)
  })
  builder.addCase(FETCH_ORDER_DETAILS_FAIL_ACTION, (state: OrderDetailsReducerState, action: AnyAction) => {
    const { orderId } = action.payload
    state.data[orderId] = action.payload
    state.loadings = state.loadings.filter(id => id !== orderId)
  })
  builder.addCase(FETCH_ORDER_ITEM_DETAILS_FAIL_ACTION, (state: OrderDetailsReducerState, action: AnyAction) => {
    state.loadings = state.loadings.filter(id => id !== action.payload.orderId)
  })
  builder.addCase(SET_ORDER_DETAILS_LOADINGS_ACTION, (state: OrderDetailsReducerState, { payload }) => {
    if (typeof payload === 'string' && !state.loadings.includes(payload)) {
      state.loadings = state.loadings.concat(payload)
    } else if (Array.isArray(payload)) {
      state.loadings = payload
    }
  })
  builder.addCase(RESET_ORDER_DETAILS_ACTION, (state: OrderDetailsReducerState) => {
    state.data = {}
  })
  builder.addCase(ADD_FRAME_ROX_ORDER_DETAILS_ACTION, (state: OrderDetailsReducerState, { payload }) => {
    state.invalidOrders = [...Object.values(payload?.['lenseItemId'] || {})]
  })
  builder.addCase(RESET_INVALID_ORDERS_ACTION, (state: OrderDetailsReducerState) => {
    state.invalidOrders = []
  })
})

export default orderDetailsReducer
