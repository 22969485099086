export const VALIDATION_ICONS = {
  fileName: '/images/icons/validation-sprite.svg',
  icons: [
    'alert',
    'alert-rounded',
    'validate',
    'checkmark-filled-mc',
    'checkmark',
    'checkmark-rounded',
    'checkmark-rounded-error',
    'info',
    'warning',
  ],
} as const
