import { WunderkindDataLayer } from './types'

declare global {
  interface Window {
    wunderkind: WunderkindDataLayer
  }
}

const initializeWunderkindDataLayer = () => {
  if (typeof window !== 'undefined' && !window?.wunderkind) {
    const wunderkindDataLayer: WunderkindDataLayer = {
      product: {
        exclusion: false,
        id: '',
        sku: '',
        name: '',
        url: '',
        imgUrl: '',
        price: 0,
        inStock: false,
        itemCategory: '',
      },
      category: {
        categoryIds: [],
        title: '',
      },
      cart: {
        quantity: 0,
        value: 0,
      },
      user: {
        email: '',
      },
    }

    window.wunderkind = { ...wunderkindDataLayer }
  }
}

export default initializeWunderkindDataLayer
