import { ErrorForAnalytics } from '../interfaces'
import get from 'lodash/get'

export const getFormattedError = (error): ErrorForAnalytics => {
  const statusCode = get(error, 'response.status', '500')
  const errorKey = get(error, 'response.data.errors[0].errorKey', '')
  const errorMessage = get(error, 'response.data.errors[0].errorMessage', '')
  const AUTHENTICATION_ERROR = '_ERR_AUTHENTICATION_ERROR'
  if (AUTHENTICATION_ERROR === errorKey) {
    return {
      id: 'Event',
      Events_Error: '1',
      Error_Code: 'Login',
      Error_Source: 'Server',
      Error_Message: errorMessage,
      Error_Details: errorKey,
    }
  }

  return {
    id: 'Event',
    Events_Error: '1',
    Error_Source: 'Server',
    Error_Code: String(statusCode),
    Error_Details: errorKey,
    Error_Message: errorMessage,
  }
}
