import { IStoreManagerSSR } from './types'

export class StateManagerSSR<T> implements IStoreManagerSSR<T> {
  private siteData: T | null = null

  constructor() {}

  public getData = (): T | null => {
    return this.siteData
  }

  public setData = (data: T): void => {
    this.siteData = data
  }
}
