//Standard libraries
import { createReducer, AnyAction } from '@reduxjs/toolkit'
//Foundation libraries
import { localStorageUtil, storageSessionHandler } from '../../foundation/utils/storageUtil'
import { PERSONALIZATION_ID, INITIATED_FROM_STORAGE, IS_LOGOUT_IN_PROGRESS } from '../../foundation/constants/user'
//Custom libraries
import { PERMANENT_STORE_DAYS } from '../../configs/common'
//Redux
import initStates from './initStates'
import { UserReducerState } from './reducerStateInterface'
import {
  LOGIN_SUCCESS_ACTION,
  LOGOUT_SUCCESS_ACTION,
  GUEST_LOGIN_SUCCESS_ACTION,
  loginErrorAction,
  REGISTRATION_SUCCESS_ACTION,
  REGISTRATION_PENDING_ACTION,
  registrationErrorAction,
  INIT_USER_FROM_STORAGE_SUCCESS_ACTION,
  FETCH_USER_DETAILS_SUCCESS_ACTION,
  FETCH_USER_DETAILS_REQUESTED_ACTION,
  NEWSLETTER_SUBSCRIBED_UNSUBSCRIBED_ACTION,
  LOGIN_REQUESTED_ACTION,
  FETCH_USER_WALLET_REQUESTED_ACTION,
  FETCH_USER_WALLET_SUCCESS_ACTION,
  REGISTRATION_PASSWORD_VALID_ACTION,
  USER_DETAILS_RESPONSE_STATUS_ACTION,
  LOGOUT_IN_PROGRESS_ACTION,
} from '../actions/user'
import { USER_SEGMENT_COOKIE_NAME, USER_SEGMENT_GUEST } from '@constants/common'
import Cookies from 'js-cookie'
import { fiscalCodeApi } from '@features/user/query'
import { getInsuranceEventModule } from '@components/DirectBilling/utils/insuranceModule'

const clearUserState = (userState: any) => {
  for (const variableKey in userState) {
    if (
      variableKey !== PERSONALIZATION_ID &&
      variableKey !== INITIATED_FROM_STORAGE &&
      variableKey !== IS_LOGOUT_IN_PROGRESS &&
      userState.hasOwnProperty(variableKey)
    ) {
      delete userState[variableKey]
    }
  }
}
/**
 * User reducer
 * handles states used by user related components
 * @param state State object managed by user reducer
 * @param action The dispatched action
 */
const userReducer = createReducer(initStates.user, builder => {
  builder.addCase(LOGIN_SUCCESS_ACTION, (state: UserReducerState | any, action: AnyAction) => {
    if (action.payload) {
      Object.assign(state, action.payload, {
        userLoggedIn: true,
        isGuest: false,
        //lastUpdated is not needed here, since we will fetch details right after.
      })
      storageSessionHandler.saveCurrentUser(state)
      //set personalizationID to localStorage
      const { personalizationID } = action.payload
      localStorageUtil.set(PERSONALIZATION_ID, personalizationID, PERMANENT_STORE_DAYS)
      state.isLoading = false

      // notify direct billing
      getInsuranceEventModule().onLogin()
    }
    //else is init from storage.
  })

  builder.addCase(LOGIN_REQUESTED_ACTION, (state: UserReducerState) => {
    state.isLoading = true
  })

  builder.addCase(INIT_USER_FROM_STORAGE_SUCCESS_ACTION, (state: UserReducerState | any, action: AnyAction) => {
    clearUserState(state)
    if (action.payload !== null) {
      Object.assign(state, action.payload, {
        [INITIATED_FROM_STORAGE]: true,
      })
    } else {
      Object.assign(state, {
        [INITIATED_FROM_STORAGE]: true,
      })
    }
  })

  builder.addCase(loginErrorAction, (state: UserReducerState | any) => {
    // state.userLoginErrorMsg = Object.assign({}, action.error.response);
    Object.assign(state, {
      userLoggedIn: false,
      isGuest: false,
      isLoading: false,
    })
    return state
  })

  builder.addCase(LOGOUT_SUCCESS_ACTION, (state: UserReducerState | any) => {
    clearUserState(state)
    storageSessionHandler.removeCurrentUser()
    state.lastUpdated = Date.now()
    Cookies.set(USER_SEGMENT_COOKIE_NAME, USER_SEGMENT_GUEST)
  })

  builder.addCase(LOGOUT_IN_PROGRESS_ACTION, (state: UserReducerState, action: AnyAction) => {
    state.isLogoutInProgress = action.payload
  })

  builder.addCase(GUEST_LOGIN_SUCCESS_ACTION, (state: UserReducerState | any, action: AnyAction) => {
    if (action.payload) {
      Object.assign(state, action.payload)
      state.userLoggedIn = false
      state.isGuest = true
      state.lastUpdated = Date.now()
      const { personalizationID } = action.payload
      localStorageUtil.set(PERSONALIZATION_ID, personalizationID, PERMANENT_STORE_DAYS)
      storageSessionHandler.saveCurrentUser(state)
    }
    //else is init from storage.
  })
  builder.addCase(REGISTRATION_SUCCESS_ACTION, (state: UserReducerState | any, action: AnyAction) => {
    Object.assign(state, action.payload)
    state.userRegistration = true
    state.pendingRegistration = false
    state.userLoggedIn = true
    state.isGuest = false
    state.lastUpdated = Date.now()
    storageSessionHandler.saveCurrentUser(state)
    const { personalizationID } = state
    localStorageUtil.set(PERSONALIZATION_ID, personalizationID, PERMANENT_STORE_DAYS)
  })
  builder.addCase(REGISTRATION_PENDING_ACTION, (state: UserReducerState, action: AnyAction) => {
    state.pendingRegistration = action.payload.isRegistrationPending
  })
  builder.addCase(REGISTRATION_PASSWORD_VALID_ACTION, (state: UserReducerState, action: AnyAction) => {
    state.isNewPasswordValid = action.payload.isValid
  })
  builder.addCase(NEWSLETTER_SUBSCRIBED_UNSUBSCRIBED_ACTION, (state: UserReducerState, action: AnyAction) => {
    if (state.details) {
      state.details.x_data.hasNewsletter = action.payload.subscribed
    }
  })
  builder.addCase(registrationErrorAction, (state: UserReducerState | any) => {
    // state.userRegistrationErrorMsg = Object.assign({}, action.error.response);
    state.pendingRegistration = false
    state.userRegistration = false
    state.isGuest = true
    return state
  })

  builder.addCase(FETCH_USER_DETAILS_REQUESTED_ACTION, state => {
    state.areDetailsLoading = true
  })

  builder.addCase(FETCH_USER_DETAILS_SUCCESS_ACTION, (state, action) => {
    state.details = action.payload
    state.lastUpdated = Date.now()
    state.areDetailsLoading = false
    storageSessionHandler.saveCurrentUser(state)
  })

  builder.addCase(USER_DETAILS_RESPONSE_STATUS_ACTION, (state, action) => {
    state.isUserDetailsResponseStatusOk = action.payload
  })

  builder.addCase(FETCH_USER_WALLET_REQUESTED_ACTION, state => {
    state.areDetailsLoading = true
  })
  builder.addCase(FETCH_USER_WALLET_SUCCESS_ACTION, (state, action) => {
    state.wallet = action.payload
    state.lastUpdated = Date.now()
    state.areDetailsLoading = false
    storageSessionHandler.saveCurrentUser(state)
  })

  builder.addMatcher(fiscalCodeApi.endpoints.getFiscalCode.matchFulfilled, (state, action) => {
    if (state.details) {
      const lastContact = action.payload.contact?.reduce((last: any, contact: any) => {
        if (contact.attributes && contact.attributes.some(attribute => attribute.value !== '')) {
          return contact
        }
        return last
      }, null)

      const fiscalCode = lastContact?.attributes?.find(i => i.key === 'addressField1')?.value || ''

      state.details = {
        ...state.details,
        fiscalCode: fiscalCode,
      }
    }
  })
})

export default userReducer
