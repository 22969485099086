//Redux
import * as ACTIONTYPES from '../action-types/error'

import { ErrorReducerState } from '../reducers/reducerStateInterface'
//Standard libraries
import { createAction } from '@reduxjs/toolkit'

const HANDLE_SESSION_ERROR_ACTION = createAction<ErrorReducerState, string>(ACTIONTYPES.HANDLE_SESSION_ERROR)
const HANDLE_CONNECTION_TIMEDOUT_ERROR_ACTION = createAction<ErrorReducerState, string>(
  ACTIONTYPES.CONNECTION_TIMEDOUT_ERROR_ACTION
)
const RESET_ERROR_ACTION = createAction(ACTIONTYPES.RESET_ERROR)
const RESET_SESSION_ERROR_ACTION = createAction(ACTIONTYPES.RESET_SESSION_ERROR)
const CANCEL_SESSION_ERROR_ACTION = createAction<any, string>(ACTIONTYPES.CANCEL_SESSION_ERROR)
const RESET_ERROR_SUCCESS_ACTION = createAction<ErrorReducerState | any, string>(
  ACTIONTYPES.RESET_SESSION_ERROR_SUCCESS
)
const RESET_SESSION_POPUP_LOGON_ERROR_ACTION = createAction(ACTIONTYPES.RESET_SESSION_POPUP_LOGON_ERROR)
const WATCH_AXIOS_ERROR_ACTION = createAction<any, string>(ACTIONTYPES.WATCH_AXIOS_ERROR)
const VALIDATION_ERROR_ACTION = createAction<ErrorReducerState, string>(ACTIONTYPES.VALIDATION_ERROR)

const CMC_SESSION_ERROR_ACTION = createAction<ErrorReducerState, string>(ACTIONTYPES.CMC_SESSION_ERROR)

const CMS_API_ERROR_ACTION = createAction<ErrorReducerState, string>(ACTIONTYPES.CMS_API_ERROR)

const HANDLE_ADD_CC_ERROR_ACTION = createAction<ErrorReducerState, string>(ACTIONTYPES.HANDLE_ADD_CC_ERROR_ACTION)

const HANDLE_CREATE_RETURN_ERROR_ACTION = createAction<ErrorReducerState, string>(
  ACTIONTYPES.HANDLE_CREATE_RETURN_ERROR_ACTION
)

export {
  HANDLE_SESSION_ERROR_ACTION,
  RESET_ERROR_ACTION,
  RESET_ERROR_SUCCESS_ACTION,
  RESET_SESSION_ERROR_ACTION,
  WATCH_AXIOS_ERROR_ACTION,
  VALIDATION_ERROR_ACTION,
  RESET_SESSION_POPUP_LOGON_ERROR_ACTION,
  CANCEL_SESSION_ERROR_ACTION,
  CMC_SESSION_ERROR_ACTION,
  HANDLE_CONNECTION_TIMEDOUT_ERROR_ACTION,
  CMS_API_ERROR_ACTION,
  HANDLE_ADD_CC_ERROR_ACTION,
  HANDLE_CREATE_RETURN_ERROR_ACTION,
}
