import { ReactElement, ReactNode } from 'react'
import { InputBaseProps, InputProps } from '@mui/material'

export interface FormControlProps {
  id?: string
  name: string
  rules?: object
  label?: string
  placeholder?: string
  description?: string | ReactElement
  autoComplete?: string
  disabled?: boolean
  select?: boolean
  children?: ReactNode
  
  /*
  * Optional regex to restrict input, has no effect on field validation.
  * For field validation, specify a pattern within rules object.
  */
  inputRegex?: RegExp
  inputProps?: InputBaseProps['inputProps']
  customInputProps?: Partial<InputProps>
}
export interface SelectOption {
  value: string,
  label: string,
  description?: string,
}

export const EMAIL_VALIDATION_PATTERN = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
export const PHONE_NUMBER_VALIDATION_PATTERN = /^(?:[0-9]{1,3})(?:[0-9\-\(\)]\s?){5}(?:[0-9\-\s?]){8}$/
