import { Maybe, PlacementCounter } from './common'
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_MAP } from '../constants/product'
import React from 'react'
import { Adjustment, DedicatedShippingMode, OrderItem } from './order'

import { AlgoliaPrice } from '@foundation/algolia/algoliaPrice'
import { Hit, BaseHit } from 'instantsearch.js'
import { IPlacement, IPlacementItem } from './cmsPlacement/Placement'
import { OrderItemContactLensData } from './cart'
import { SubscriptionInfo } from './subscription'
import { PDPPageData } from '@foundation/analytics/tealium/lib'

export type BooleanString = 'true' | 'false'

export type ProductSeo = {
  href?: Maybe<string>
}

export const PRODUCT_PRICE_USAGE_NAMES = {
  CURRENT: 'Offer', // current price of product, can be initial or discounted
  INITIAL: 'Display', // initial price of product, before applied discount
} as const

type UsageNames = keyof typeof PRODUCT_PRICE_USAGE_NAMES
type ProductPriceUsageName = (typeof PRODUCT_PRICE_USAGE_NAMES)[UsageNames]

export interface ServerProductPrice {
  contractId?: string
  currency: string
  description?: string
  usage: ProductPriceUsageName
  value: string
}

export interface DetailedRxPrice {
  type: string
  initialPrice: ServerProductPrice
  currentPrice: ServerProductPrice
}

export interface ServerProductXPrice {
  endDate?: string
  startDate?: string
  price: string
  currency: string
  priority?: string
}

export type ProductImage = {
  name: string
  sequence?: string
}

export type Attachment = {
  usage: string
  sequence: string
  name: string
  attachmentAssetPathRaw: string
  image?: string
  identifier?: string
  catentry_id?: string
  shortdesc?: string
  attachmentAssetID?: string
  mimeType: string
  attachmentAssetPath: string
  rule?: string
}

export interface ServerProductAttribute {
  identifier: string
  'attribute.natural': string
  usage: string
  values: [
    {
      sequence: string
      identifier: string
      value: string
      uniqueID: string
      unitOfMeasure?: string
      unitID?: string
    },
  ]
  displayable: BooleanString
  merchandisable: BooleanString
  searchable: BooleanString
  sequence: string
  storeDisplay?: BooleanString
  name: string
  facetable: BooleanString
  comparable: BooleanString
  key?: string
  uniqueID: string
  swatchable: BooleanString
}

export interface IProductAttribute {
  identifier: string
  'attribute.natural': string
  usage: string
  values: [
    {
      sequence: string
      identifier: string
      value: string
      uniqueID: string
      unitOfMeasure?: string
      unitID?: string
    },
  ]
  displayable: BooleanString
  merchandisable: BooleanString
  searchable: BooleanString
  sequence: string
  storeDisplay?: BooleanString
  name: string
  facetable: BooleanString
  comparable: BooleanString
  key?: string
  uniqueID: string
  swatchable: BooleanString
}

export interface ProductAnalyticsRX extends IProduct {
  rxPrice?: number
  rxOfferPrice?: number
}

export type ProductSoldOutStatus = (typeof PRODUCT_SOLDOUT_STATUS)[keyof typeof PRODUCT_SOLDOUT_STATUS]

export type DefaultProductCtaStatus = Extract<ProductSoldOutStatus, typeof PRODUCT_SOLDOUT_STATUS.NONE>
export type SoldOutProductCtaStatus = Exclude<ProductSoldOutStatus, typeof PRODUCT_SOLDOUT_STATUS.NONE>

export interface ServerProductAttachment {
  image: string
  identifier: string
  sequence: string
  catentry_id: string
  usage: string
  name: string
  shortdesc: string
  attachmentAssetPathRaw: string
  attachmentAssetID: string
  mimeType: string
  attachmentAssetPath: string
}

export interface AssociatedProduct extends IServerProduct {
  associationType: string
}

export interface ClusterProduct extends IProduct {
  items?: IProduct[] // sKUs?
}

export interface ClusteredProduct {
  // NO PRODUCT
  cluster: IProduct[]
}

export interface Price {
  usage: string
  contractId: string
  description: string
  currency: string
  value: string
}

export interface IProductAttributes {
  [key: string]: string
}

export type ProductType = (typeof PRODUCT_TYPES_MAP)[keyof typeof PRODUCT_TYPES_MAP]
export interface ProductRightColumnProps {
  pdpData?: IProduct | null
  contactLensData?: OrderItemContactLensData
  type: string | null
  partNumber: IProduct['partNumber']
  currentProduct: IProduct
  productItems: IProduct['items']
  isLoading: boolean
  ctaRef: React.RefObject<HTMLDivElement>
  stickyBarCtaRef: React.RefObject<HTMLDivElement>
  onClusterProductClick?: (p: IProduct) => void
  soldOutStatus: ProductSoldOutStatus
  addToCartButtonFail: boolean
  productInCart?: OrderItem
  productQuantity?: string[]
  setProductQuantity: React.Dispatch<string[]>
  attachments?: Attachment[]
  attributes?: ServerProductAttribute[]
  placements?: IPlacement<IPlacementItem>[]
  isSoldout?: boolean
  isComingBackSoon?: boolean
  isRoxable?: boolean
  customerSegments: string[]
  onlyFewPieces: boolean
  shipInfos?: DedicatedShippingMode | undefined
  cluster: IProduct['cluster']
  isEditing?: boolean
  adjustments?: Adjustment[]
  setAdjustments?: (adjustments: Adjustment[]) => void
  availableQuantity?: number
  analyticsPdpData?: PDPPageData
  onAddToCartClick?: () => void
  liveStockError?: boolean
}

export interface EyeContanctLensOption {
  text: string
  value: string | null
  index: number | null
  notAvailable?: boolean
}

export interface EyeClFieldConfig {
  id: EyeContactLensAttribute
  select: boolean
  label: string
  options: EyeContanctLensOption[]
  defaultValue: string
  active?: boolean
  visible?: boolean
  required?: boolean
  multifield?: boolean
}

export type EyeClFieldConfigMap = Record<string, EyeClFieldConfig[]>

export type EyeContactLensAttribute =
  | 'x_baseCurve'
  | 'x_spherePower'
  | 'x_diameter'
  | 'x_axis'
  | 'x_color'
  | 'x_addition'
  | 'x_dominance'
  | 'x_cylinder'
  | 'quantity'

export interface EyeContanctLensStatusData {
  enabled?: boolean
  errors?: string[]
  dirtyFields?: string[]
  emptyFields?: string[]
  valid?: boolean
  touched?: boolean
}

export type Facet = {
  name: string
  entry: any[]
}

export interface ContactLensData {
  x_productId?: string
  x_eye?: LensesEyesAttributes
  x_baseCurve?: string
  x_spherePower?: string
  x_diameter?: string
  x_axis?: string
  x_cylinder?: string
  x_dominance?: string
  x_addition?: string
  x_color?: string
  quantity?: string
  fieldsStatus?: EyeContanctLensStatusData | null
}

export type ContactLensPayload = Omit<ContactLensData, 'fieldsStatus'>

export type ContactLensesData = Record<string, ContactLensData>

export interface SupplyData {
  quantity?: string
  discountAmount?: string
  originalBoxPrice?: string
  discountedBoxPrice?: string
  timePeriod?: string
}

export enum ProductTypesEnum {
  ContactLensesAccessories = 'Contact Lenses Accessories',
  Accessories = 'Accessories',
  ContactLenses = 'Contact Lenses',
  RoxLens = 'Rox_lens',
}

export type Attribute = {
  attributeName: string
  attributeType: string
  attributeValue: string
}

export type LensesEyesAttributes = 'RCON' | 'LCON'

export interface EyeContactLensOverride {
  attribute: EyeContactLensAttribute
  value: string
}

export type EyeContactLensOverrideMap = Record<'left' | 'right', EyeContactLensOverride[]>

export type EyeContactLensAttributeMapping = {
  parsedValue: number
  orginalValue: string
}

export interface SizeOption {
  size: string
  label: string
  href?: string
}
export interface Size {
  size: string
  frameSize: string
  bridgeWidth: string
  sizeOrder: Sizes
  uniqueID: string
  hingeDistance: string
}

export enum Sizes {
  XXS,
  XS,
  S,
  M,
  L,
  XL,
  XXL,
}

export type PictureType =
  | 'quarter'
  | 'front'
  | 'lateral'
  | 'closed front'
  | 'back'
  | 'alternative'
  | 'folding'
  | 'folding group'
  | 'group'
  | 'OnModel'
  | 'adv'

export type ProductImageUsage = 'PDP' | 'PLP' | 'Thumbnail'

export interface PriceModel {
  price: string
  currency: string
}

export type IAlgoliaHit = Hit<{
  x_groupkey: string
  price?: AlgoliaPrice | null
  prices: {
    [key: string]: AlgoliaPrice
  }
  partnumberId: string
  parentProductId: string
  productId: string
  clusters?: IAlgoliaHit[]
  massoc?: unknown
  category_ids?: unknown
  categories_tree?: unknown
  categories_tree_translated?: unknown
}> &
  Omit<IProduct, 'price' | 'cluster'>

export type IAlgoliaBaseHit = BaseHit

export interface IProduct {
  attachments: Attachment[]
  attributes: any
  images: {
    sequence: string
    name: string
  }[]
  buyable: string
  cluster?: IProduct[]
  hasSingleSKU: boolean
  id?: string
  items?: IProduct[]
  longDescription?: string
  merchandisingAssociations?: AssociatedProduct[]
  catalogEntryTypeCode?: string
  name: string
  parentCatalogGroupID: string | string[]
  parentCatalogEntryID?: string
  partNumber: string
  productId?: string
  x_price: {
    [key: string]: AlgoliaPrice
  }
  seo?: ProductSeo
  shortDescription?: string
  numberOfSKUs?: number
  sKUs?: IProduct[]
  storeID: string
  type?: string
  uniqueID?: string
  x_offerpriceRx?: string
  resourceId?: string
  manufacturer?: string
  'relationship.item.id'?: string
  'relationship.item.sequence'?: string
  _ignored?: string
  quantity?: string
  orderItemExtendAttribute: Attribute[]
  associationType?: string
  productAttributes: IProductAttributes
  displayable?: boolean
  placementCounter?: PlacementCounter
}

export type IProductUnavailableRaw = Omit<IProduct, 'orderItemExtendAttribute'>

export interface IServerProduct extends IProduct {
  attributes: IProductAttribute[]
  prices: Price[]
  price: IProductPrice[]
  x_prices: {
    offer?: IProductXPrice
    list?: IProductXPrice
  }
  cluster: IServerProduct[]
  items: IServerProduct[]
}

/**
 * Fields as defined by API.
 * Reference:
 * https://luxotticaretail.atlassian.net/wiki/spaces/EECOM/pages/2639037036/Contact+Lens+Accessory+Solutions+Not+to+Be+Sold+if+0+Stock+TECHNICAL+ANALYSIS+-+NIT-4400#HCL-Commerce-Wrapper-available-for-Live-Stock-Checker-API-details
 */
export interface IProductLiveStockUPCItem {
  UPC: string
  Quantity: string
}

export interface IProductLiveStockRequest {
  upcList: IProductLiveStockUPCItem[]
}

/**
 * Fields as defined by API.
 * Reference:
 * https://luxotticaretail.atlassian.net/wiki/spaces/EECOM/pages/2639037036/Contact+Lens+Accessory+Solutions+Not+to+Be+Sold+if+0+Stock+TECHNICAL+ANALYSIS+-+NIT-4400#HCL-Commerce-Wrapper-available-for-Live-Stock-Checker-API-details
 */
export interface IProductLiveStockResultItem {
  Requested: string
  Availability: string
  Plant: string
  UPC: string
  Available: string
  PlantLevel: string
  Location: string
}

export interface IProductLiveStockRawResponse {
  code: number
  message: string
  errorCode: number
  errorMessage: string
  results: IProductLiveStockResultItem[]
}

export interface IProductPrice {
  contractId?: string
  currency: string
  description?: string
  usage: ProductPriceUsageName
  value: string
}

export interface DetailedRxPrice {
  type: string
  initialPrice: IProductPrice
  currentPrice: IProductPrice
}

export interface IProductXPrice {
  endDate?: string
  startDate?: string
  price: string
  currency: string
  priority?: string
}

export const isProduct = (item?: object): item is IProduct =>
  !!item && ('attributes' in item || 'productAttributes' in item || 'uniqueID' in item)
