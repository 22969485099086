import { MAX_PURCHASABLE_QUANTITY_CL_ACCESSORY } from '@constants/order'
import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '@services/AxiosBaseQuery'
import { IProductLiveStockRequest, IProductLiveStockRawResponse } from '@typesApp/product'

export interface IProductLiveStockResponse extends IProductLiveStockRawResponse {
  liveStockOutOfStockProducts: string[]
}

export const liveStockCheckApi = createApi({
  reducerPath: 'liveStockCheckApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/store/{storeId}/',
  }),
  endpoints: builder => ({
    getLiveStock: builder.query<IProductLiveStockResponse, { storeId: string; body: IProductLiveStockRequest }>({
      query: ({ storeId, body }) => ({
        url: 'livestockchecker/elios',
        pathParams: { storeId },
        method: 'POST',
        body,
      }),
      transformResponse: (liveStockResponse: IProductLiveStockRawResponse) => {
        const liveStockOutOfStockProducts: string[] = []

        liveStockResponse.results?.map(resultItem => {
          const availableQty = resultItem.Available
            ? Number(resultItem.Available)
            : MAX_PURCHASABLE_QUANTITY_CL_ACCESSORY

          if (availableQty === 0) {
            liveStockOutOfStockProducts.push(resultItem.UPC)
          }
        })
        return { ...liveStockResponse, liveStockOutOfStockProducts: liveStockOutOfStockProducts }
      },
    }),
  }),
})

export const { useGetLiveStockQuery } = liveStockCheckApi
