import { PaginationResponse } from '../../services/RequestService'
import { IServerProduct } from '../../types/product'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'
import { fromServerToClientProduct } from '@utils/fromServerToClientEntity'
import { RootReducerState } from '@redux/rootReducer'

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/v2/',
  }),
  tagTypes: ['Product'],
  endpoints: build => ({
    getProductDetails: build.query<any, any>({
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        const state = queryApi.getState() as RootReducerState
        const result = await fetchWithBQ({
          url: 'products',
          method: 'get',
          queryParams: args['queryParams'] || {},
          body: args['body'] || {},
          extraParams: { siteContextKey: 'search' },
          pathParams: { storeId: state.site.currentSite?.storeID || '' },
        })

        const data = result?.data
        if (!data) return { error: result.error }

        if (data.contents.length > 1) {
          return {
            data: data.contents?.map(item => fromServerToClientProduct(item)),
          }
        }

        return {
          data: transformResponse(data),
        }
      },
      providesTags: (result, error, arg) => {
        return error ? [] : [{ type: 'Product', id: arg.queryParams.id }]
      },
    }),
  }),
})
const transformResponse = (data: PaginationResponse<IServerProduct & { type: 'item' }>) => {
  return fromServerToClientProduct(data.contents[0])
}
export const { useGetProductDetailsQuery, useLazyGetProductDetailsQuery } = productApi
