export const FEATURE_ICONS = {
  fileName: '/images/icons/feature-sprite.svg',
  icons: [
    'oneday-lenses',
    'twoweeks-lenses',
    'monthly-lenses',
    'toric-lenses',
    'antistatic',
    'water-repellent',
    'smudge-repellent',
    'scratch-resistant',
    'anti-reflection',
    'extended-durability',
  ],
} as const
