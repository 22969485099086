import { createSlice } from '@reduxjs/toolkit'
import { CountryStates, StatesProvinces } from '../../types/common'
import { fetchCountryListDetails } from './thunks'

export interface IAccountState {
  isFetching?: boolean
  error?: any
  address?: any
  orders?: any
  countries?: CountryStates[]
  statesProvinces?: StatesProvinces[]
}

const initialState: IAccountState = {
  isFetching: false,
}

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountryListDetails.rejected, (state, action) => {
      state.error = action.payload
      state.isFetching = false
    })
    builder.addCase(fetchCountryListDetails.fulfilled, (state, action) => {
      state.isFetching = false
      if (action.payload.countries) {
        state.countries = action.payload.countries
      }

      if (action.payload.countryCodeStates) {
        state.statesProvinces = action.payload.countryCodeStates
      }
    })
    builder.addCase(fetchCountryListDetails.pending, (state) => {
      state.isFetching = true
      state.error = undefined
    })
  },
})

export default accountSlice.reducer
