import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { CommerceEnvironment } from '../../constants/common'

export interface FundProviderRequestBody {
  orderId: string
  key: string
  value: string
}

export interface FundItem {
  label: string
  value: string
}

export interface FundItemServer {
  displayLabel: string
  provider_number: string
}

export interface FundItemsServerResponse {
  langId: string
  storeId: string
  values: FundItemServer[]
}

export const healthFundApi = createApi({
  reducerPath: 'healthFundApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/store/{storeId}',
  }),
  tagTypes: ['HealthFunds'],
  endpoints: builder => ({
    getFundProviders: builder.query<FundItem[], void>({
      query: () => ({
        url: `/healthProviders?langId=${CommerceEnvironment.reverseLanguageMap.en_au}`,
        method: 'GET',
      }),
      providesTags: [{ type: 'HealthFunds' }],
      transformResponse: (fundsResponse: FundItemsServerResponse) => {
        return fundsResponse.values.map(fund => ({
          value: fund.provider_number,
          label: fund.displayLabel,
        }))
      },
    }),
    setFundProvider: builder.mutation<void, FundProviderRequestBody>({
      query: body => ({
        url: '/order_ext_attr/update',
        method: 'POST',
        body: { ...body },
      }),
    }),
  }),
})

export const { useGetFundProvidersQuery, useSetFundProviderMutation } = healthFundApi
