import { IFormField } from '../types/form'
import * as yup from 'yup'
import { PrescriptionFormData, PrescriptionFormField } from '../types/prescription'
import { PRESCRIPTION_FLOW_MAP } from './common'
export const PRESCRIPTION_FORM_I18N_INVALID_MSG_BASE = 'UploadPrescription.Msgs.'
export const PRESCRIPTION_FORM_I18N_FORM_FIELDS_INVALID_MSGS: Partial<
  Record<PrescriptionFormField['fieldName'], string>
> = {
  firstName: 'InvalidFirstName',
  nickName: 'InvalidPrescriptionName',
  file: 'InvalidFile',
}
export const RX_CONFIGURATOR_ENABLED_FLOWS = [PRESCRIPTION_FLOW_MAP.MANUAL, PRESCRIPTION_FLOW_MAP.ACCOUNT]

export const PRESCRIPTION_FORM_FIELDS: IFormField<keyof PrescriptionFormData>[] = [
  { fieldName: 'id', mandatory: false },
  { fieldName: 'nickName', mandatory: true },
  { fieldName: 'type', mandatory: false },
  { fieldName: 'rightSphere', mandatory: true },
  { fieldName: 'rightAdd', mandatory: true },
  { fieldName: 'rightAxis', mandatory: true },
  { fieldName: 'rightCyl', mandatory: true },
  { fieldName: 'leftSphere', mandatory: true },
  { fieldName: 'leftAdd', mandatory: true },
  { fieldName: 'leftAxis', mandatory: true },
  { fieldName: 'leftCyl', mandatory: true },
  { fieldName: 'lPupDistance', mandatory: true },
  { fieldName: 'rPupDistance', mandatory: true },
  { fieldName: 'prescriptionUrl', mandatory: false },
]

export const RX_PRESCRIPTION_FORM_FIELDS: IFormField<keyof PrescriptionFormData>[] = [
  {
    fieldName: 'file',
    mandatory: true,
    validation: new RegExp(/^\s*\S+.*/),
  },
]

export const RX_UPLOAD_SUPPORTED_FORMATS = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'image/gif']

export const MAXSIZEFILE = 10485760

export const fileValidationSchema = yup
  .mixed()
  .nullable()
  .required('A file is required')
  .test('fileSize', 'The file is too large', file => {
    //if u want to allow only certain file sizes
    return file && file.size <= MAXSIZEFILE
  })
  .test('format', 'upload file', value => !value || (value && RX_UPLOAD_SUPPORTED_FORMATS.includes(value.type)))

export const getSphereRanges = () => {
  const negativeValues: number[] = []
  const positiveValues: number[] = []
  const MIN_NEGATIVE_SPH_VALUE = -0.25
  const MAX_NEGATIVE_SPH_VALUE = -15
  const MIN_POSITIVE_SPH_VALUE = 0.25
  const MAX_POSITIVE_SPH_VALUE = 8

  for (let i = MIN_NEGATIVE_SPH_VALUE; i >= MAX_NEGATIVE_SPH_VALUE; i -= 0.25) {
    negativeValues.push(i)
  }
  for (let i = MIN_POSITIVE_SPH_VALUE; i <= MAX_POSITIVE_SPH_VALUE; i += 0.25) {
    positiveValues.push(i)
  }
  return {
    negativeValues,
    positiveValues,
  }
}

export const getCylinderRanges = () => {
  const negativeValues: number[] = []
  const positiveValues: number[] = []
  const MIN_NEGATIVE_CYL_VALUE = -0.25
  const MAX_NEGATIVE_CYL_VALUE = -6
  const MIN_POSITIVE_CYL_VALUE = 0.25
  const MAX_POSITIVE_CYL_VALUE = 6

  for (let i = MIN_NEGATIVE_CYL_VALUE; i >= MAX_NEGATIVE_CYL_VALUE; i -= 0.25) {
    negativeValues.push(i)
  }
  for (let i = MIN_POSITIVE_CYL_VALUE; i <= MAX_POSITIVE_CYL_VALUE; i += 0.25) {
    positiveValues.push(i)
  }
  return {
    negativeValues,
    positiveValues,
  }
}

export const getAxisRange = () => {
  const rangeArray: number[] = []
  const MIN_AXIS_VALUE = 0
  const MAX_AXIS_VALUE = 180

  for (let i = MIN_AXIS_VALUE; i <= MAX_AXIS_VALUE; i++) {
    rangeArray.push(i)
  }
  return rangeArray
}

export const getAddRange = () => {
  const rangeArray: string[] = ['0.00']
  const MIN_ADD_VALUE = 0.75
  const MAX_ADD_VALUE = 3

  for (let i = MIN_ADD_VALUE; i <= MAX_ADD_VALUE; i += 0.25) {
    rangeArray.push(`${i.toFixed(2)}`)
  }
  return rangeArray
}

export const getPDRange = (isDoublePd?: boolean) => {
  //For double PD 0 is not acceptable
  const rangeArray: number[] = isDoublePd ? [] : [0]
  const MIN_PD_VALUE = isDoublePd ? 20 : 40
  const MAX_PD_VALUE = isDoublePd ? 37.5 : 75

  for (let i = MIN_PD_VALUE; i <= MAX_PD_VALUE; i += 0.5) {
    rangeArray.push(i)
  }
  return rangeArray
}
