import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type CheckoutState = {
  isNewsletterSubscriptionRequested: boolean
}

const initialState: CheckoutState = {
  isNewsletterSubscriptionRequested: false,
}

/**
 * TODO: put all state related to checkout in this slice
 */
export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setIsNewsletterSubscriptionRequested: (state, action: PayloadAction<boolean>) => {
      state.isNewsletterSubscriptionRequested = action.payload
    },
  },
  extraReducers: builder => {
    // TODO: add matchers and cases, left blank for now
  },
})

const { actions, reducer } = checkoutSlice
export const { setIsNewsletterSubscriptionRequested } = actions
export { reducer as checkoutReducer }
