//Standard libraries
import { takeLatest, takeLeading } from 'redux-saga/effects'
//Redux
import { ADD_FRAME_ROX_ORDER_DETAILS_ACTION, FETCH_ORDER_DETAILS_ACTION, RESET_INVALID_ORDERS_ACTION } from '../../actions/orderDetails'
import * as WORKERS from '../workers/orderDetails'

/**
 *
 */
export function* watchSaga() {
  yield takeLatest(FETCH_ORDER_DETAILS_ACTION, WORKERS.getOrderDetails)
  yield takeLeading(ADD_FRAME_ROX_ORDER_DETAILS_ACTION, WORKERS.getInvalidOrderDetails)
  yield takeLeading(RESET_INVALID_ORDERS_ACTION, WORKERS.resetInvalidOrders)
}
