//Redux
import * as ERRORS from '../../../constants/errors'

import {
  CMS_API_ERROR_ACTION,
  HANDLE_CONNECTION_TIMEDOUT_ERROR_ACTION,
  HANDLE_SESSION_ERROR_ACTION,
  RESET_ERROR_SUCCESS_ACTION,
  RESET_SESSION_POPUP_LOGON_ERROR_ACTION,
  VALIDATION_ERROR_ACTION,
} from '../../actions/error'
//Standard libraries
import { put, select } from 'redux-saga/effects'

import { defaultStates } from '../../reducers/initStates'
//Foundation libraries
import { sessionErrorSelector } from '../../selectors/error'
import { INTERNAL_SERVER_ERROR } from 'http-status-codes'
import { NEWSLETTER_DUPLICATE_EMAIL, VM_AVAILABILITY_SERVER } from '@constants/common'

let handlingError = false

/**
 * Saga worker to invoke get orders API
 */
export function* handleAxiosErrors(action: any) {
  const { payload: error } = action
  const isLogoff =
    error.config && error.config.url.endsWith('loginidentity/@self') && error.config.method.toLowerCase() === 'delete'
  //ignore logoff error

  if (error.isAxiosError && !handlingError && !isLogoff) {
    handlingError = true
    const errorResponse = error.response
    const isNewsletterDuplicateError =
      errorResponse?.data?.errors?.filter(err => err.errorKey === NEWSLETTER_DUPLICATE_EMAIL).length > 0
    const isCmsError = errorResponse?.data?.name === 'CMS'
    const isVtoError = errorResponse?.config?.url?.includes(VM_AVAILABILITY_SERVER)
    const isDirectBillingTokenError = errorResponse?.config?.url?.includes('direct-billing/auth/token')
    if (isDirectBillingTokenError) {
      return
    } else if (isNewsletterDuplicateError) {
      return
    } else if (errorResponse && errorResponse.data && errorResponse.data.errors) {
      const e = errorResponse.data.errors[0]
      // handle expired session
      if (
        e.errorCode === ERRORS.EXPIRED_ACTIVITY_TOKEN_ERROR ||
        e.errorKey === ERRORS.EXPIRED_ACTIVITY_TOKEN_ERROR ||
        e.errorCode === ERRORS.PARTIAL_AUTHENTICATION_ERROR_CODE ||
        e.errorKey === ERRORS.PARTIAL_AUTHENTICATION_ERROR_KEY ||
        e.errorCode === ERRORS.ACTIVITY_TOKEN_ERROR_CODE ||
        e.errorKey === ERRORS.ACTIVITY_TOKEN_ERROR_KEY
      ) {
        const payload = {
          ...e,
          handled: false,
          errorTitleKey: 'SessionError.TimeoutTitle',
          errorMsgKey: 'SessionError.TimeoutMsg',
        }

        // eslint-disable-next-line no-console
        console.log('%c[SESSION ERROR] USER LOGGED OUT - ', 'color: red; font-weight: bold;', e.errorMessage)
        yield put(HANDLE_SESSION_ERROR_ACTION(payload))
      }
      // handle invalid session where another user logged in from different location
      else if (e.errorCode === ERRORS.INVALID_COOKIE_ERROR_CODE && e.errorKey === ERRORS.INVALID_COOKIE_ERROR_KEY) {
        const payload = {
          ...e,
          handled: false,
          errorTitleKey: 'SessionError.InvalidTitle',
          errorMsgKey: 'SessionError.InvalidMsgB2C',
        }
        // eslint-disable-next-line no-console
        console.log('%c[SESSION ERROR] USER LOGGED OUT - ', 'color: red; font-weight: bold;', e.errorMessage)
        yield put(HANDLE_SESSION_ERROR_ACTION(payload))
      }
      // handle password expired issue.
      else if (e.errorCode === ERRORS.PASSWORD_EXPIRED_ERR_CODE || e.errorKey === ERRORS.PASSWORD_EXPIRED) {
        //reset password dialog
        const payload = {
          ...e,
          handled: false,
          errorTitleKey: 'reset.title',
          errorMsgKey: 'reset.errorMsg',
        }
        // eslint-disable-next-line no-console
        console.log('%c[SESSION ERROR] USER LOGGED OUT - ', 'color: red; font-weight: bold;', e.errorMessage)
        yield put(HANDLE_SESSION_ERROR_ACTION(payload))
      } else if (
        ![
          ERRORS.INVALID_CURRENT_PASSWORD_ERROR_CODE,
          ERRORS.GENERIC_REMOTE_ERROR_CODE,
          ERRORS.FORGOT_PASSWORD_INVALID_ID_ERROR,
          ERRORS.FINDER_ERROR_CODE,
          ERRORS.INVALID_INPUT,
        ].includes(e.errorCode) &&
        ![ERRORS.SHIPPING_ADDRESS_COUNTRY_INVALID].includes(e.errorKey)
      ) {
        const payload = {
          ...e,
          handled: false,
          errorTitleKey: 'SessionError.GenericTitle',
        }
        if (errorResponse.status < INTERNAL_SERVER_ERROR) yield put(HANDLE_SESSION_ERROR_ACTION(payload))
      } else {
        // eslint-disable-next-line no-console
        console.error('[REQUEST ERROR]', e)
      }
    } else {
      if (error.code && error.code === ERRORS.CONNECTION_TIMEDOUT_ERROR) {
        const payload = {
          errorCode: error.code,
          errorMessage: error.toLocaleString(),
          errorParameters: {
            url: error.config?.url,
          },
          handled: false,
        }
        // eslint-disable-next-line no-console
        yield put(HANDLE_CONNECTION_TIMEDOUT_ERROR_ACTION(payload))
      } else if (isCmsError) {
        const payload = {
          errorMessage: `CMS ERROR - ${errorResponse.data?.message.toLocaleString()}`,
          handled: false,
        }
        yield put(CMS_API_ERROR_ACTION(payload))
      } else if (isVtoError) {
        const payload = {
          errorMessage: error.toLocaleString(),
          errorParameters: {
            url: error.config?.url,
          },
          handled: true,
        }
        yield put(HANDLE_SESSION_ERROR_ACTION(payload))
      } else if (isDirectBillingTokenError) {
        return
      } else {
        const payload = {
          errorMessage: error.toLocaleString(),
          errorParameters: {
            url: error.config?.url,
          },
          handled: false,
        }
        // eslint-disable-next-line no-console
        console.log('%c[SESSION ERROR] USER LOGGED OUT - ', 'color: red; font-weight: bold;')
        yield put(HANDLE_SESSION_ERROR_ACTION(payload))
      }
    }
    setTimeout(() => {
      if (handlingError) {
        handlingError = false
      }
    }, 1000)
  }
}

/**
 *
 */
export function* handleCMCSessionError() {
  const payload = {
    errorKey: ERRORS.CMC_SESSION_ERROR_KEY,
    handled: false,
    errorTitleKey: 'SessionError.InvalidTitle',
    errorMsgKey: 'SessionError.InvalidMsg',
  }
  yield put(HANDLE_SESSION_ERROR_ACTION(payload))
}

/**
 *
 */
export function* resetError() {
  const sessionErrorObject = yield select(sessionErrorSelector)
  if (!sessionErrorObject.errorKey && !sessionErrorObject.errorCode) {
    //do not reset session error
    const sessionError = { ...defaultStates.error }
    yield put(RESET_ERROR_SUCCESS_ACTION(sessionError))
  } else {
    yield put(RESET_SESSION_POPUP_LOGON_ERROR_ACTION())
  }
}

/**
 *
 */
export function* resetSessionError() {
  const sessionErrorObject = yield select(sessionErrorSelector)
  if (sessionErrorObject.errorKey || sessionErrorObject.errorCode) {
    //reset session error
    const sessionError = { ...defaultStates.error }
    yield put(RESET_ERROR_SUCCESS_ACTION(sessionError))
  }
}

/**
 *
 */
export function* handleValidationError(action: any) {
  const payload = action.payload
  yield put(VALIDATION_ERROR_ACTION(payload))
}
