import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'
import { RootReducerState } from '@redux/rootReducer'
import { LensDataResponse } from '../../types/rxConfigurator'

export const roxApi = createApi({
  reducerPath: 'roxApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/store/{storeId}',
  }),
  tagTypes: ['LensData'],

  endpoints: (build) => ({
    roxLensData: build.query<LensDataResponse, { productId: string, langId:string }>({
      queryFn: async (args, queryApi, _extraOptions, fetchWithBQ) => {
        const state = queryApi.getState() as RootReducerState
        const storeId = state.site.currentSite?.storeID
        const langId = args.langId
        const result = await fetchWithBQ({
          url: `/rox/lensesData/${args.productId}`,
          method: 'get',
          queryParams: { storeId , langId}
        })

        return (result?.data)
          ? { data: result.data as LensDataResponse}
          : { error: result.error as FetchBaseQueryError }
      },
      providesTags: [{ type: 'LensData' }],
    })
  }),
})

export const {
  useRoxLensDataQuery,
} = roxApi
