import CurrencyService from './CurrencyService'
import { PRODUCT_TYPES_MAP } from '../constants/product'
import { IFacet } from '../features/plp/query'
import {
  ServerProductPrice,
  PRODUCT_PRICE_USAGE_NAMES,
  IProduct,
  ProductType,
  ServerProductXPrice,
  PriceModel,
  Attachment,
} from '../types/product'
import { getFrontColor, getLensesColor, getLensesColorFacet, getProductType } from '../utils/productAttributes'
import { OrderItem } from '../types/order'
import DateService from './DateService'
import { determineAlgoliaPrice } from '../foundation/algolia/algoliaPrice'

class ProductService {
  getCurrentPrice(prices: ServerProductPrice[]): number {
    const priceModel = this.getCurrentPriceModel(prices)

    return priceModel?.value ? +priceModel.value : NaN
  }

  getCurrentPriceModel(prices: ServerProductPrice[]): ServerProductPrice | null {
    return prices?.find(p => !!p && p.usage === PRODUCT_PRICE_USAGE_NAMES.CURRENT) || null
  }

  getInitialPrice(prices: ServerProductPrice[]): number {
    const priceModel = this.getInitialPriceModel(prices)

    return priceModel?.value ? +priceModel.value : NaN
  }

  getInitialPriceModel(prices: ServerProductPrice[]): ServerProductPrice | null {
    return prices?.find(p => !!p && p.usage === PRODUCT_PRICE_USAGE_NAMES.INITIAL) || null
  }

  genPriceWithoutValue(prices: ServerProductPrice[]) {
    return prices.map(price => {
      if (!price.value) {
        const priceWithBaseValue = prices.find(price => !!price.value)

        return {
          ...price,
          value: priceWithBaseValue?.value || '0',
        }
      }

      return price
    })
  }

  calculateTotalPriceModel(prices: ServerProductPrice[]): ServerProductPrice {
    return prices.reduce((total, price) => {
      return { ...total, ...price, value: String(+total.value + +price.value) }
    })
  }

  getProductType(product?: IProduct): ProductType {
    return product ? PRODUCT_TYPES_MAP[getProductType(product)?.toLowerCase()] : PRODUCT_TYPES_MAP.frames
  }

  /** Used in ProductTile in MoCos slider */
  getProductColorLabel(product: IProduct): string {
    const productType = this.getProductType(product)

    switch (productType) {
      case PRODUCT_TYPES_MAP.accessories:
        return getFrontColor(product)
      case PRODUCT_TYPES_MAP.optical:
        return getFrontColor(product)
      case PRODUCT_TYPES_MAP.sun:
        const frontColor = getFrontColor(product)
        const lensColor = getLensesColor(product) || getLensesColorFacet(product)

        return frontColor === lensColor ? frontColor : `${frontColor} ${lensColor}`.trim()
      default:
        return ''
    }
  }

  findPriceFacetByName(name: string) {
    return name.startsWith('OfferPrice_')
  }

  formatPriceFacet(priceFacet: IFacet, rangeStartPrefix: string, rangeEndPrefix: string): IFacet {
    const priceCurrencyName = priceFacet.value.replace('price_', '').toUpperCase()
    const currencySymbol = CurrencyService.getSymbolByName(priceCurrencyName)

    return {
      ...priceFacet,
      entry: priceFacet.entry.map(v => ({
        ...v,
        label: v.label.replace(/\({([\*\d.]*)\sTO\s([\*\d.\s]*)]\)/g, (_match, rangeStart, rangeEnd) => {
          if (rangeStart.trim() === '*') {
            return `${rangeStartPrefix} ${currencySymbol + rangeEnd}`
          } else if (rangeEnd.trim() === '*') {
            return `${rangeEndPrefix} ${currencySymbol + rangeStart}`
          } else {
            return `${currencySymbol + rangeStart} - ${currencySymbol + rangeEnd}`
          }
        }),
      })),
    }
  }
  isValidXPriceDate(price: ServerProductXPrice) {
    const startDate = DateService.convertPriceDate(price?.startDate)
    const endDate = DateService.convertPriceDate(price?.endDate)

    const startDateObj = new Date(startDate)
    const endDateObj = new Date(endDate)

    if (endDateObj < startDateObj) {
      endDateObj.setFullYear(endDateObj.getFullYear() + 100)
    }
    const isValidStartDate = startDate && startDateObj < new Date()
    const isValidEndDate = endDate === undefined || (endDate && endDateObj > new Date())

    return isValidStartDate && isValidEndDate
  }

  getXPriceModel(xprice: ServerProductXPrice): PriceModel | null {
    const checkDates = this.isValidXPriceDate(xprice)
    if (!checkDates) return null

    return { currency: xprice.currency, price: xprice.price }
  }

  getXPriceModelFromOrderItems(
    xprice: ServerProductXPrice,
    orderItems: OrderItem[],
    currentProductId: string | undefined,
    isCart: boolean | undefined
  ): PriceModel | null {
    const checkDates = this.isValidXPriceDate(xprice)

    if (!checkDates) return null

    const unitPrice = orderItems.find(item => item.productId === currentProductId)?.unitPrice.toString() || ''
    const price = isCart ? unitPrice : xprice.price

    return { currency: xprice.currency, price }
  }

  getPricingForVirtualMirror(x_price: any, customerSegments: string[]) {
    // if no x_price use product price logic, else use product price algolia
    let currentPrice: any
    let previousPrice: any
    let currency: string | undefined

    const algoliaPricing = determineAlgoliaPrice(x_price, customerSegments)

    currentPrice = algoliaPricing?.offerPrice
    previousPrice = algoliaPricing?.listPrice
    currency = algoliaPricing?.currency
    if (currentPrice === previousPrice) {
      previousPrice = undefined
    }
    return {
      currentPrice,
      previousPrice,
      currency,
    }
  }
  createAttachmentFromUrl(url) {
    return {
      // using high value on sequence so will be set as last
      sequence: '99.0',
      name: 'Case',
      usage: 'PDP',
      attachmentAssetPathRaw: url,
    }
  }

  getProductImages(caseImageUrl, product?: IProduct): Attachment[] {
    const attachments = product?.attachments || []
    if (!attachments.length) return []

    // filter images array to get array with PDP images
    const PDPImagesArray: Attachment[] = attachments.filter(d => d.usage === 'PDP')
    if (!PDPImagesArray.length) {
      return []
    }
    if (caseImageUrl) {
      PDPImagesArray.push(this.createAttachmentFromUrl(caseImageUrl) as Attachment)
    }
    // order images by "sequence" attribute
    PDPImagesArray.sort((a, b) => parseInt(a.sequence, 10) - parseInt(b.sequence, 10))

    return PDPImagesArray
  }
}

export default new ProductService()
