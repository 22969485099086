//Standard libraries
import { AxiosPromise, AxiosRequestConfig, Method } from 'axios'
import { PRODUCTION, SHOW_API_FLOW } from '../../constants/common'
import { PersonWalletResponse } from '../../../types/user'
//Redux
import { API_CALL_ACTION } from '../../../redux/actions/api'
//Foundation libraries
import { executeRequest } from '../../axios/axiosConfig'
import { getSite } from '../../hooks/useSite'
import { localStorageUtil } from '../../utils/storageUtil'
import { site as siteConstants } from '@foundation/constants/site'

const apiCallAction = (method: string, url: string): void => {
  const showAPIFlow = process.env.NODE_ENV !== PRODUCTION ? localStorageUtil.get(SHOW_API_FLOW) === 'true' : false
  if (showAPIFlow) {
    const store = require('../../../redux/store').default
    if (store) {
      store.dispatch(API_CALL_ACTION(`Transaction: ${method} ${url}`))
    }
  }
}

const setHeaders = (): {
  Accept: string
  'Content-Type': string
} => {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  }
}

const personWalletService = {
  /**
   * Gets the wallet for a registered user.
   * `@method`
   * `@name PersonWallet#getWalletBySelf`
   * `@param {{widget: string}} payload` have following properties:
   **  `@property {string} widget (required)`
   **  `@property {string} cancelToken (required)`
   */
  getWalletBySelf(payload: { widget: string }): AxiosPromise<PersonWalletResponse> {
    const method: Method = 'GET'
    const site = getSite()
    const siteContext: string = siteConstants.transactionContextUrl || ''
    if (site?.storeID === undefined) {
      throw new Error('Request /store/{storeId}/person/@self/wallet missing path parameter storeId')
    }
    const url = `${siteContext}/store/${site?.storeID}/person/@self/wallet`
    const request: AxiosRequestConfig = Object.assign(
      {
        method,
        headers: setHeaders(),
        url,
      },
      { ...payload }
    )
    apiCallAction(method, url)
    return executeRequest(request)
  },

  /**
   * Add a card to user's wallet.
   * `@method`
   * `@name PersonWallet#addCard`
   * `@param {any} data ` Card data.
   */
  addCard(data: {
    expire_month: string
    expire_year: string
    cc_account?: string
    cc_cvc?: string
    cc_nameoncard?: string
    billingAddressId: string
    shippingAddressId: string
  }): AxiosPromise<any> {
    const method: Method = 'POST'
    const site = getSite()
    const siteContext: string = siteConstants.transactionContextUrl || ''
    if (site?.storeID === undefined) {
      throw new Error('Request /store/{storeId}/person/@self/wallet missing path parameter storeId')
    }
    const url = `${siteContext}/store/${site?.storeID}/person/@self/wallet`
    const request: AxiosRequestConfig = Object.assign({
      method,
      headers: setHeaders(),
      data,
      url,
    })
    apiCallAction(method, url)
    return executeRequest(request)
  },

  /**
   * Set a card as default card. No other updates are supported.
   * `@method`
   * `@name PersonWallet#setCardAsDefault`
   * `@param {string} cardId ` Card ID.
   */
  setCardAsDefault(cardId: string): AxiosPromise<any> {
    const method: Method = 'PUT'
    const site = getSite()
    const siteContext: string = siteConstants.transactionContextUrl || ''
    if (site?.storeID === undefined) {
      throw new Error('Request /store/{storeId}/person/@self/wallet/cardId/default missing path parameter storeId')
    }
    const url = `${siteContext}/store/${site?.storeID}/person/@self/wallet/${cardId}/default`
    const request: AxiosRequestConfig = Object.assign({
      method,
      headers: setHeaders(),
      cardId,
      url,
    })
    apiCallAction(method, url)
    return executeRequest(request)
  },

  /**
   * Delete a card from user's wallet.
   * `@method`
   * `@name PersonWallet#deleteCard`
   * `@param {string} walletId ` Wallet ID.
   */
  deleteCard(walletId: string): AxiosPromise<any> {
    const method: Method = 'DELETE'
    const site = getSite()
    const siteContext: string = siteConstants.transactionContextUrl || ''
    if (site?.storeID === undefined) {
      throw new Error('Request /store/{storeId}/person/@self/wallet missing path parameter storeId')
    }
    const url = `${siteContext}/store/${site?.storeID}/person/@self/wallet/${walletId}`
    const request: AxiosRequestConfig = Object.assign({
      method,
      headers: setHeaders(),
      url,
    })
    apiCallAction(method, url)
    return executeRequest(request)
  },
}

export default personWalletService
