import { CartBundle } from '../types'

type InterceptCallback = () => void

interface EventModule {
  onOrderComplete: (payload?: unknown) => void
  onLogin: (payload?: unknown) => void
  onSessionError: (payload?: unknown) => void
  onCartBundleChange: (bundle: CartBundle) => void

  interceptAddCartItem: (originalMethod: InterceptCallback) => void
  interceptRemoveCartItem: (originalMethod: InterceptCallback) => void
  interceptChangeQuantity: (originalMethod: InterceptCallback) => void
  interceptEditCartItem: (originalMethod: InterceptCallback) => void
  interceptApplyPromoCode: (originalMethod: InterceptCallback) => void
  interceptApplySubscription: (originalMethod: InterceptCallback) => void
  interceptLogout: (logoutCallback: InterceptCallback) => void
}

export const hasInsuranceModule = (): boolean => {
  return typeof window !== 'undefined'
    && typeof window?.directBilling === 'object'
    && Object.keys(window?.directBilling).length > 0
}

export const getInsuranceEventModule = (): EventModule => {
  const hasModule = hasInsuranceModule()

  return {
    onOrderComplete: (payload?: unknown) => {
      hasModule &&
        document.dispatchEvent(
          new CustomEvent('db:insuranceEvent', {
            detail: { type: 'onOrderComplete', payload },
          })
        )
    },

    onLogin: (payload?: unknown) => {
      hasModule &&
        document.dispatchEvent(
          new CustomEvent('db:insuranceEvent', {
            detail: { type: 'onLogin', payload },
          })
        )
    },

    onSessionError: (payload?: unknown) => {
      hasModule &&
        document.dispatchEvent(
          new CustomEvent('db:insuranceEvent', {
            detail: { type: 'onSessionError', payload },
          })
        )
    },

    onCartBundleChange: (payload: CartBundle) => {
      hasModule &&
        document.dispatchEvent(
          new CustomEvent('db:insuranceEvent', {
            detail: { type: 'onCartBundleChange', payload },
          })
        )
    },

    interceptAddCartItem: callback => {
      hasModule
        ? document.dispatchEvent(
            new CustomEvent('db:insuranceEvent', {
              detail: { type: 'onCartAddItem', payload: callback },
            })
          )
        : callback?.()
    },

    interceptRemoveCartItem: callback => {
      hasModule
        ? document.dispatchEvent(
            new CustomEvent('db:insuranceEvent', {
              detail: { type: 'onCartRemoveItem', payload: callback },
            })
          )
        : callback?.()
    },

    interceptChangeQuantity: callback => {
      hasModule
        ? document.dispatchEvent(
            new CustomEvent('db:insuranceEvent', {
              detail: { type: 'onCartChangeQuantity', payload: callback },
            })
          )
        : callback?.()
    },

    interceptEditCartItem: callback => {
      hasModule
        ? document.dispatchEvent(
            new CustomEvent('db:insuranceEvent', {
              detail: { type: 'onCartEditItem', payload: callback },
            })
          )
        : callback?.()
    },

    interceptApplyPromoCode: callback => {
      hasModule
        ? document.dispatchEvent(
            new CustomEvent('db:insuranceEvent', {
              detail: { type: 'onCartApplyPromoCode', payload: callback },
            })
          )
        : callback?.()
    },

    interceptApplySubscription: callback => {
      hasModule
        ? document.dispatchEvent(
            new CustomEvent('db:insuranceEvent', {
              detail: { type: 'onCartApplySubscription', payload: callback },
            })
          )
        : callback?.()
    },

    interceptLogout: callback => {
      hasModule
        ? document.dispatchEvent(
            new CustomEvent('db:insuranceEvent', {
              detail: { type: 'onLogout', payload: callback },
            })
          )
        : callback?.()
    },
  }
}