import { CART } from '../../../constants/routes'
import { ORDER_CONFIGS } from '../../../configs/order'
import { HTTP_CODE_OK, PRESCRIPTION_FLOW_MAP, SUCCESS_MSG_PREFIX } from '../../../constants/common'
import cartService from '../../../foundation/apis/transaction/cart.service'
import { createAsyncThunk } from '@reduxjs/toolkit'
import fetchCart from './fetchCart'
import { localStorageUtil } from '../../../foundation/utils/storageUtil'
import rxService from '../../../foundation/apis/rx-config/rx.service'
import { sendSuccessMessage } from '../../success/slice'
import { doneEditingCartItem } from '../../cartui/cartuiSlice'
import { PrescriptionFlow, PrescriptionObject, RXFileLink } from '../../../types/rxConfigurator'
import { getRxValuesFromRxLens } from '../../../components/PrescriptionLenses/RxUtils'
import { SiteInfo } from '@redux/rootReducer'
import { ImageryType } from '@components/PrescriptionLenses/PrescriptionLenses'
import { Attribute, OrderItem } from '@typesApp/order'
import { RX_PRESCRIPTION_OBJECT_KEY } from '@constants/rxConfigurator'
import Log from '@services/Log'

export interface IAddLensArgs {
  params: {
    partnumber: string | string[]
    quantity: string | string[]
    catentryId?: string | string[]
    contractId?: string
    widget?: string
    langId: string
    product?: any
    fromRxPanel?: boolean
    userInfo?: any
    images?: ImageryType
  }
  callback?: any
  siteInfo: SiteInfo
}

export interface ICommonArgs {
  partnumbers: string[]
  quantities: any[]
  langId: string
  widget?: string
  contractId?: string
}

export interface IAddSingleLensArgs extends ICommonArgs {
  catentryIds: string[]
}

export interface IAddRoxableArgs extends ICommonArgs {
  catentryIds: string[]
}

export interface IAddFrameArgs extends ICommonArgs {
  orderItemId: string
}

const SAVE_PRESCRIPTION_FLOWS: PrescriptionFlow[] = ['MANUAL', 'ACCOUNT']

const retrieveRoxLensOrderItemId = (orderItems: OrderItem[], currentOrderItemIds: string[]) => {
  const filteredCurrentOrderItems = orderItems.filter((item: OrderItem) =>
    currentOrderItemIds.includes(item.orderItemId)
  )
  const currentRoxLensOrderItem = filteredCurrentOrderItems.find((item: OrderItem) => {
    return Boolean(
      item.orderItemExtendAttribute.find((attribute: Attribute<string>) => {
        return attribute.attributeName === 'IsRoxLens' && attribute.attributeValue === 'true'
      })
    )
  })
  return currentRoxLensOrderItem?.orderItemId
}

const addFrame = createAsyncThunk<any, IAddFrameArgs>('order/addFrame', async args => {
  const { orderItemId, partnumbers, quantities, langId, widget, contractId } = args
  const _orderFrameExtendAttribute: any[] = []
  const _orderFrameExtendAttributes: any[] = []
  const _orderFrameItems: any[] = []

  for (const i in partnumbers) {
    if (orderItemId) {
      _orderFrameExtendAttribute.push(
        ...[
          {
            attributeName: 'IsRox',
            attributeType: 'String',
            attributeValue: 'true',
          },
          {
            attributeName: 'RxLensId',
            attributeType: 'String',
            attributeValue: orderItemId,
          },
        ]
      )
    }
    _orderFrameItems[i] = {
      quantity: quantities[i].toString(),
      partNumber: partnumbers[i],
      contractId,
      orderItemExtendAttribute: orderItemId ? _orderFrameExtendAttribute : undefined,
    }
    _orderFrameExtendAttributes[i] = {
      attributeName: 'LanguageId',
      attributeType: 'string',
      attributeValue: langId,
    }
  }

  let bodyFrame = {
    body: {
      orderId: '.',
      x_calculateOrder: ORDER_CONFIGS.calculateOrder,
      orderItem: _orderFrameItems,
      x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
      orderExtendAttribute: _orderFrameExtendAttributes,
    },
    widget,
  }

  return (await cartService.addOrderItem(bodyFrame)).data
})

const addLens = createAsyncThunk<any, IAddSingleLensArgs>('order/addSingleLens', async (args, { rejectWithValue }) => {
  try {
    const { quantities, catentryIds, langId, widget, contractId } = args

    const _orderLensExtendAttribute: any[] = []
    const _orderLensExtendAttributes: any[] = []
    const _orderLensItems: any[] = []

    for (const i in catentryIds) {
      _orderLensExtendAttribute.push(
        ...[
          {
            attributeName: 'IsRox',
            attributeType: 'String',
            attributeValue: 'true',
          },
          {
            attributeName: 'IsRoxLens',
            attributeType: 'String',
            attributeValue: 'true',
          },
        ]
      )
      _orderLensItems[i] = {
        quantity: quantities[i].toString(),
        productId: catentryIds[i],
        contractId: contractId,
        orderItemExtendAttribute: _orderLensExtendAttribute,
      }
      _orderLensExtendAttributes[i] = {
        attributeName: 'LanguageId',
        attributeType: 'string',
        attributeValue: langId,
      }
    }

    let bodyLens = {
      body: {
        orderId: '.',
        x_calculateOrder: ORDER_CONFIGS.calculateOrder,
        orderItem: _orderLensItems,
        x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
        orderExtendAttribute: _orderLensExtendAttributes,
      },
      widget,
    }
    Log.info(`AddLensItem: 2.1 -> Submitting BodyLens:  ${JSON.stringify(bodyLens)}`)
    return (await cartService.addOrderItem(bodyLens)).data
  } catch (e) {
    return rejectWithValue(e)
  }
})

const addRoxable = createAsyncThunk<any, IAddRoxableArgs>('order/addRoxable', async (args, { rejectWithValue }) => {
  try {
    const { quantities, catentryIds, langId, widget, contractId, partnumbers } = args
    const _orderItem: any[] = []

    catentryIds.forEach((catentryId, index) => {
      _orderItem.push({
        quantity: quantities[index].toString(),
        productId: catentryId,
        contractId: contractId,
        orderItemExtendAttribute: [
          {
            attributeName: 'IsRox',
            attributeType: 'String',
            attributeValue: 'true',
          },
          {
            attributeName: 'IsRoxLens',
            attributeType: 'String',
            attributeValue: 'true',
          },
        ],
      })
    })

    partnumbers.forEach((partnumber, index) => {
      _orderItem.push({
        quantity: quantities[index].toString(),
        partNumber: partnumber,
        contractId,
        orderItemExtendAttribute: [
          {
            attributeName: 'IsRox',
            attributeType: 'String',
            attributeValue: 'true',
          },
          {
            attributeName: 'IsRoxFrame',
            attributeType: 'String',
            attributeValue: 'true',
          },
        ],
      })
    })

    let bodyLens = {
      body: {
        orderId: '.',
        x_calculateOrder: ORDER_CONFIGS.calculateOrder,
        orderItem: _orderItem,
        x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
        orderExtendAttribute: [
          {
            attributeName: 'LanguageId',
            attributeType: 'string',
            attributeValue: langId,
          },
        ],
      },
      widget,
    }

    return (await cartService.addOrderItem(bodyLens)).data
  } catch (e) {
    return rejectWithValue(e)
  }
})

const addLensItem = createAsyncThunk<any, IAddLensArgs>(
  'order/addLens',
  async (args, { dispatch, rejectWithValue }) => {
    const { partnumber, catentryId, quantity, langId, contractId, widget, fromRxPanel, product, userInfo } = args.params
    try {
      const addToCartSuccessCallback = args.callback
      const mySite = args.siteInfo
      const rxObject =
        product && product?.roxableServices?.[0] && !fromRxPanel
          ? getRxValuesFromRxLens(product?.roxableServices[0], { fallbackValue: '' })
          : null

      const prescriptionFromLocalStorage: PrescriptionObject = localStorageUtil.get(RX_PRESCRIPTION_OBJECT_KEY)

      let catentryIds: string[] = []
      let partnumbers: string[] = []
      let quantities: any[] = []

      if (partnumber) {
        partnumbers = partnumber instanceof Array ? partnumber : [partnumber]
      }

      if (catentryId) {
        catentryIds = catentryId instanceof Array ? catentryId : [catentryId]
      }

      quantities = quantity instanceof Array ? quantity : [quantity]

      const common: ICommonArgs = {
        langId,
        partnumbers,
        quantities,
        contractId,
        widget,
      }

      return dispatch(addRoxable({ ...common, catentryIds }))
        .unwrap()
        .then(responseLens => {
          const currentOrderItemIds = responseLens.orderItem.map((item: { orderItemId: string }) => item.orderItemId)

          return dispatch(
            fetchCart({
              ...args,
              widget,
              contractId,
            })
          )
            .unwrap()
            .then(cartResponse => {
              // Cart reponse contains an array of orderItems, we need to find the orderItemId of the lens we just added
              // to be able to link the prescription to it
              const orderItems = cartResponse?.data.orderItem

              dispatch(
                sendSuccessMessage({
                  key: SUCCESS_MSG_PREFIX + 'ITEM_ADD_SUCCESS',
                  link: {
                    url: CART,
                    textKey: SUCCESS_MSG_PREFIX + 'ViewCart',
                  },
                })
              )

              const currentRoxLensOrderItemId = retrieveRoxLensOrderItemId(orderItems ?? [], currentOrderItemIds)

              const getUserNames = {
                firstName: userInfo?.firstName ?? product?.firstName ?? '',
                lastName: userInfo?.lastName ?? product?.lastName ?? '',
              }

              if (!fromRxPanel && !!rxObject) {
                const prescriptionObject = {
                  orderId: responseLens.orderId,
                  orderItemId: currentRoxLensOrderItemId,
                  prescription: {
                    productType: rxObject?.type ? String(rxObject?.type) : '',
                    nickName: rxObject?.nickName ? String(rxObject?.nickName) : '',
                    firstName: getUserNames?.firstName ?? '',
                    lastName: getUserNames?.lastName ?? '',
                    telephone: userInfo?.phone1 ? String(userInfo.phone1) : '',
                    dateOfBirth: userInfo?.dateOfBirth ? String(userInfo.dateOfBirth) : '',
                    prescriptionState: userInfo?.prescriptionState ? String(userInfo.prescriptionState) : '',
                    pupillaryDistance: rxObject?.pupillaryDistance ? String(rxObject?.pupillaryDistance) : '',
                    rightSphere: rxObject?.rightSphere ? String(rxObject?.rightSphere) : '0.00',
                    rightAdd: rxObject?.rightAdd ? String(rxObject?.rightAdd) : '',
                    rightAxis: rxObject?.rightAxis ? String(rxObject?.rightAxis) : '0',
                    rightCyl: rxObject?.rightCylinder ? String(rxObject?.rightCylinder) : '0.00',
                    leftSphere: rxObject?.leftSphere ? String(rxObject?.leftSphere) : '0.00',
                    leftAdd: rxObject?.leftAdd ? String(rxObject?.leftAdd) : '',
                    leftAxis: rxObject?.leftAxis ? String(rxObject?.leftAxis) : '0',
                    leftCyl: rxObject?.leftCylinder ? String(rxObject?.leftCylinder) : '0.00',
                    lPupDistance: rxObject?.lPupDistance ? String(rxObject?.lPupDistance) : '',
                    rPupDistance: rxObject?.rPupDistance ? String(rxObject?.rPupDistance) : '',
                  },
                }
                return rxService.addPrescriptionDetails(mySite, prescriptionObject).then(responsePrescription => {
                  if (responsePrescription.data?.successCode !== HTTP_CODE_OK) {
                    throw new Error(responsePrescription.data?.successMessage)
                  }

                  return executeCallBack(dispatch, addToCartSuccessCallback)
                })
              } else if (prescriptionFromLocalStorage && fromRxPanel) {
                if (
                  prescriptionFromLocalStorage?.prescriptionFlow &&
                  SAVE_PRESCRIPTION_FLOWS.includes(prescriptionFromLocalStorage?.prescriptionFlow)
                ) {
                  const prescriptionObject = {
                    orderId: responseLens.orderId,
                    orderItemId: currentRoxLensOrderItemId,
                    prescription: {
                      productType: String(rxObject?.type ?? ''),
                      nickName: String(rxObject?.nickName ?? ''),
                      firstName: getUserNames.firstName,
                      lastName: getUserNames.lastName,
                      telephone: String(userInfo?.phone1 ?? ''),
                      dateOfBirth: String(userInfo?.dateOfBirth ?? ''),
                      prescriptionState: String(userInfo?.prescriptionState ?? ''),
                      pupillaryDistance: String(prescriptionFromLocalStorage?.PD?.OD ?? ''),
                      rightSphere: String(prescriptionFromLocalStorage?.SPH?.OD ?? '0.00'),
                      rightAdd: String(prescriptionFromLocalStorage?.ADD?.OD ?? ''),
                      rightAxis: String(prescriptionFromLocalStorage?.AX?.OD ?? '0'),
                      rightCyl: String(prescriptionFromLocalStorage?.CYL?.OD ?? '0.00'),
                      leftSphere: String(prescriptionFromLocalStorage?.SPH?.OS ?? '0.00'),
                      leftAdd: String(prescriptionFromLocalStorage?.ADD?.OS ?? ''),
                      leftAxis: String(prescriptionFromLocalStorage?.AX?.OS ?? '0'),
                      leftCyl: String(prescriptionFromLocalStorage?.CYL?.OS ?? '0.00'),
                      lPupDistance: String(prescriptionFromLocalStorage?.PD?.OS ?? ''),
                      rPupDistance: String(prescriptionFromLocalStorage?.PD?.OD ?? ''),
                    },
                  }

                  return rxService.addPrescriptionDetails(mySite, prescriptionObject).then(responsePrescription => {
                    if (responsePrescription.data?.successCode !== HTTP_CODE_OK) {
                      throw new Error(responsePrescription.data?.successMessage)
                    }
                    if (prescriptionFromLocalStorage?.prescriptionFlow === PRESCRIPTION_FLOW_MAP.UPLOAD) {
                      const rxFileLink: RXFileLink = {
                        orderId: responseLens.orderId,
                        orderItemId: currentRoxLensOrderItemId,
                        rxFileStorageId: prescriptionFromLocalStorage?.savedFileName ?? '',
                      }

                      return rxService.linkRXFileWithOrder(mySite, rxFileLink).then(responsePrescription => {
                        if (responsePrescription.data?.successCode !== HTTP_CODE_OK) {
                          throw new Error(responsePrescription.data?.successMessage)
                        }
                        return executeCallBack(dispatch, addToCartSuccessCallback)
                      })
                    } else {
                      return executeCallBack(dispatch, addToCartSuccessCallback)
                    }
                  })
                }
              }
              return executeCallBack(dispatch, addToCartSuccessCallback)
            })
        })
    } catch (error) {
      Log.error(`AddLensItem: (pID: ${product?.id}, part #: ${partnumber}) ->  Could not add lens item: ${error}`)
      // ensure we unblock cart on error in edit mode
      dispatch(doneEditingCartItem())
      return rejectWithValue(error)
    }
  }
)

const executeCallBack = (dispatch, addToCartSuccessCallback) => {
  if (addToCartSuccessCallback) {
    addToCartSuccessCallback()
  }
  return true
}

export default addLensItem

export { addFrame, addLens }
