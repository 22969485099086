import React, { PropsWithChildren, useContext } from 'react'
import { createContext } from 'react'
import { getCookies as internalGetCookies, getCookie as internalGetCookie } from 'cookies-next'
import { AppContext } from 'next/app'
import config from '@configs/config.base'
import { TmpCookiesObj } from 'cookies-next/lib/types'

export interface CookiesProviderProps {
  cookies: TmpCookiesObj
}

const CookiesContext = createContext<CookiesProviderProps['cookies'] | null>(null)

export const CookiesProvider: React.FC<PropsWithChildren<CookiesProviderProps>> = ({
  cookies,
  children,
}): JSX.Element => {
  return <CookiesContext.Provider value={cookies}>{children}</CookiesContext.Provider>
}

export function useCookies() {
  const cookiesContext = useContext(CookiesContext)
  if (!cookiesContext) {
    throw new Error('useCookies must be used within a CookiesProvider')
  }
  return cookiesContext
}

export function parseCookies(req: AppContext['ctx']['req']) {
  return internalGetCookies({ req })
}

export function useCookie(name: string): string | undefined {
  const isSSR = typeof window === 'undefined'
  const cookies = useCookies()

  return isSSR ? cookies[name] : internalGetCookie(name)
}

export function useCustomerSegmentsUtil(value?: string): string[] {
  const passedValue = value
  const usergCookie = useCookie('userg')
  const cookie = passedValue || usergCookie?.charAt(0) === '"' ? usergCookie?.slice(1, -1) : usergCookie // the userg is set by BE as a "fake" empty string
  return cookie ? cookie.split('|') : [config?.algolia?.guestSegment]
}
