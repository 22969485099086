import { cmsDataInstance } from './csmManager'
import { storeInfoDataInstance } from './storeInfoManager'
import { IStoreManagerSSR, StoreManagerLoadersTypeMap } from './types'

export type StoreManagerLoadersType<T extends keyof StoreManagerLoadersTypeMap> = {
  [K in T]: IStoreManagerSSR<StoreManagerLoadersTypeMap[K]>
}

export class StoreManagerLoaders<T extends keyof StoreManagerLoadersTypeMap> {
  private loaders = {} as StoreManagerLoadersType<T>
  private loaderTypes: Partial<Record<T, StoreManagerLoadersTypeMap[T]>> = {}

  register<K extends T>(key: K, loader: IStoreManagerSSR<StoreManagerLoadersTypeMap[K]>) {
    this.loaders[key] = loader
    this.loaderTypes[key] = {} as StoreManagerLoadersTypeMap[K]
  }

  get<K extends T>(key: K): StoreManagerLoadersType<T>[K] {
    return this.loaders[key]
  }
}

export const storeManagerLoaders = new StoreManagerLoaders()
storeManagerLoaders.register('storeInfo', storeInfoDataInstance)
storeManagerLoaders.register('cms', cmsDataInstance)
