import { createTheme } from '@mui/material/styles'
import { customPalette } from '../color-palette'

//TODO decomment this line once BE store name is set
//const appName = process.env.NEXT_PUBLIC_STORENAME
const appName = 'Clearly'
//customPalette = palette[appName?.toLowerCase()] ? palette[appName?.toLowerCase()] : palette.emerald
const storePalette = customPalette[appName.toLowerCase()]

const {
  wildSand,
  white,
  alto,
  boulder,
  tulipTree,
  thunderBird,
  greenPea,
  black,
  light,
  dark1,
  light2,
  blue,
  light1,
  grey,
  red,
  whiteSmoke,
} = storePalette

export const themeProps = {
  themeName: 'Clearly',
  name: 'Clearly',
  typography: {
    fontFamily: ['Mulish', 'sans-serif'].join(','),
    htmlFontSize: 16,
    body: {
      fontSize: '1rem',
      lineHeight: 24,
    },
    body2: {
      fontSize: '0.875rem',
    },
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.5rem',
    },
    h4: {
      fontSize: '1.25rem',
    },
    h5: {
      fontSize: '1.25rem',
    },
    h6: {
      fontSize: '1.25rem',
    },
    subtitle1: {
      fontSize: '0.75rem',
    },
    subtitle2: {
      fontSize: '0.625rem',
    },
  },
  palette: {
    type: 'light',
    background: {
      dark: {
        primary: black,
        secondary: blue,
        tertiary: light1.grey,
        quaternary: light.blue,
      },
      light: {
        primary: white,
        secondary: white,
        tertiary: light2.grey,
        quaternary: boulder,
        quinary: light.grey,
        senary: light.red,
        septenary: whiteSmoke,
      },
    },
    text: {
      dark: {
        primary: black,
        secondary: black,
        tertiary: light.blue,
        quaternary: blue,
        quinary: grey,
        senary: red,
      },
      light: {
        primary: white,
        secondary: boulder,
        tertiary: alto,
        quaternary: wildSand,
      },
    },
    color: {
      success: greenPea,
      warning: tulipTree,
      error: thunderBird,
      black: black,
    },
    action: {
      hover: dark1.blue,
    },
    custom: storePalette,
  },
  spacing: 4,
  button: {
    backgroundColor: dark1.blue,
    '&:hover': {
      backgroundColor: dark1.blue,
    },
  },
  child: {
    backgroundColor: dark1.blue,
  },
  rippleVisible: {
    opacity: 0.5,
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 600,
      md: 1025,
      lg: 1440,
      xl: 1920,
    },
  },
  shadows: [
    'none',
    '0px 1px 4px 2px rgba(0,0,0,0.05), 0px 2px 1px 0px rgba(0,0,0,0.03)',
    '0px 1px 4px 2px rgba(0,0,0,0.15), 0px 2px 1px 0px rgba(0,0,0,0.13)',
    '0px 1px 2px 2px rgba(0,0,0,0.1), 0px 2px 1px 0px rgba(0,0,0,0.1)',
    'inset 0px 1px 2px 2px rgba(0,0,0,0.1), inset 0px 2px 1px 0px rgba(0,0,0,0.1)',
    'inset 0px 1.47273px 11.7818px rgba(96, 97, 112, 0.32)',
    '0px 0px 2.94545px rgba(40, 41, 61, 0.08), 0px 1.47273px 5.89091px rgba(96, 97, 112, 0.16)',
    '0px 0px 2.94545px rgba(40, 41, 61, 0.04), 0px 5.89091px 11.7818px rgba(96, 97, 112, 0.16)',
    '0px 0px 5.89091px rgba(40, 41, 61, 0.04), 0px 11.7818px 23.5636px rgba(96, 97, 112, 0.16)',
    '0px 5.89091px 11.7818px rgba(40, 41, 61, 0.04), 0px 23.5636px 47.1273px rgba(96, 97, 112, 0.16)',
    '0px 5.89091px 23.5636px rgba(40, 41, 61, 0.04), 0px 47.1273px 70.6909px rgba(96, 97, 112, 0.16)',
    '0px 5.89091px 23.5636px rgba(40, 41, 61, 0.08), 0px 58.9091px 94.2545px rgba(96, 97, 112, 0.24)',
    'inset 0px 1.47273px 11.7818px rgba(96, 97, 112, 0.32)',
    '0px 0px 2.94545px rgba(0, 0, 0, 0.08), 0px 1.47273px 5.89091px rgba(0, 0, 0, 0.16)',
    '0px 0px 2.94545px rgba(0, 0, 0, 0.04), 0px 5.89091px 11.7818px rgba(0, 0, 0, 0.16)',
    '0px 0px 5.89091px rgba(0, 0, 0, 0.04), 0px 11.7818px 23.5636px rgba(0, 0, 0, 0.16)',
    '0px 5.89091px 11.7818px rgba(0, 0, 0, 0.04), 0px 23.5636px 47.1273px rgba(0, 0, 0, 0.16)',
    '0px 5.89091px 23.5636px rgba(0, 0, 0, 0.04), 0px 47.1273px 70.6909px rgba(0, 0, 0, 0.16)',
    '0px 5.89091px 23.5636px rgba(0, 0, 0, 0.08), 0px 58.9091px 94.2545px rgba(0, 0, 0, 0.24)',
    '0px 4px 4px rgba(0, 0, 0, 0.1)', //Index 19: Carousel Controller
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  components: {
    MuiCssBaseline: {
      html: {
        fontSize: '16px',
      },
      body: {
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: 'normal',
      },
    },
  },
}

const theme = createTheme(themeProps)

export default theme
