import { createSlice } from '@reduxjs/toolkit'
import { prescriptionApi } from './query'
import { UserPrescriptionState } from '@redux/rootReducer'

const initialState: UserPrescriptionState = {
  code: '',
  userId: 0,
  prescription: [],
  recordCount: 0,
}

export const prescriptionSlice = createSlice({
  name: 'prescription',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(prescriptionApi.endpoints.getPrescriptions.matchFulfilled, (state, action) => {
      const { code, userId, errorMessage, Prescription, recordCount } = action.payload
      state.code = code
      state.userId = userId
      state.errorMessage = errorMessage
      state.prescription = Prescription
      state.recordCount = recordCount
    })
  },
})
