export const LENS_ICONS = {
  fileName: '/images/icons/lens-sprite.svg',
  icons: [
    'lens',
    'cleaner',
    'glass',
    'glasses',
    'antistatic',
    'water-repellent',
    'smudge-repellent',
    'scratch-resistant',
    'antireflection',
    'primary',
    'progressive',
    'computer-light-filter',
  ],
} as const
