import { StateManagerSSR } from './stateManager'
import { CmsDataType } from './types'

class CmsData extends StateManagerSSR<CmsDataType> {
  private static instance: CmsData | null = null

  constructor() {
    super()
  }

  public static newInstance = (): CmsData => {
    if (this.instance) {
      return this.instance
    }
    const newInstance = new CmsData()
    this.instance = newInstance
    return newInstance
  }
}

export const cmsDataInstance = CmsData.newInstance()
