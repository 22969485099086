import { IPrescription } from '@features/prescription/query'
import { createSlice } from '@reduxjs/toolkit'

export interface IUiState {
  openDrawerMegaMenu: {
    isOpen: boolean
    id: string
    categoryName?: string
  }
  openDrawerFilters: boolean
  openDrawerSearch: boolean
  openDrawerNewsLetter: boolean
  openDrawerCartSummary: boolean
  hamburgerMenuDrawerOpen: boolean
  openDrawerProductNotificationAvailable: boolean
  lastSelectedProduct: string
  productsMobileListingLayout: 'compact' | 'full'
  openModalSignIn: {
    isOpen: boolean
    onLoginCallBack?: (any) => null
    onCancelCallBack?: (any) => null
  }
  openModalRegistration: boolean
  openModalAddPrescription: {
    isOpen: boolean
    prescription: IPrescription | null
    onActionSuccess: any
  }
  openModalDeletePrescription: {
    isOpen: boolean
    prescription: IPrescription | null
    onActionSuccess: any
  }
  openModalViewRX: {
    isOpen: boolean
    prescription: IPrescription | null
  }
  openModalBookExam: boolean
  vMisOpen: boolean
}

export const initialStateUi: IUiState = {
  openDrawerMegaMenu: {
    isOpen: false,
    id: '',
    categoryName: '',
  },
  openDrawerFilters: false,
  openDrawerSearch: false,
  openDrawerNewsLetter: false,
  openDrawerCartSummary: false,
  hamburgerMenuDrawerOpen: false,
  openDrawerProductNotificationAvailable: false,
  lastSelectedProduct: '',
  productsMobileListingLayout: 'compact',
  openModalSignIn: {
    isOpen: false
  },
  openModalRegistration: false,
  openModalAddPrescription: {
    isOpen: false,
    prescription: null,
    onActionSuccess: {},
  },
  openModalDeletePrescription: {
    isOpen: false,
    prescription: null,
    onActionSuccess: {},
  },
  openModalViewRX: {
    isOpen: false,
    prescription: null,
  },
  openModalBookExam: false,
  vMisOpen: false,
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState: initialStateUi,
  reducers: {
    openDrawerMegaMenu: (state: IUiState, action) => {
      state.openDrawerMegaMenu.id = action.payload.id
      state.openDrawerMegaMenu.categoryName = action.payload.name
      state.openDrawerMegaMenu.isOpen = true
    },
    closeDrawerMegaMenu: (state: IUiState) => {
      state.openDrawerMegaMenu.id = ''
      state.openDrawerMegaMenu.categoryName = ''
      state.openDrawerMegaMenu.isOpen = false
    },
    openDrawerFilters: (state: IUiState, action) => {
      state.openDrawerFilters = action.payload
    },
    openDrawerSearch: (state: IUiState, action) => {
      state.openDrawerSearch = action.payload
    },
    openDrawerNewsLetter: (state: IUiState, action) => {
      state.openDrawerNewsLetter = action.payload
    },
    openDrawerCartSummary: (state: IUiState, action) => {
      state.openDrawerCartSummary = action.payload
    },
    openDrawerProductNotificationAvailable: (state: IUiState, action) => {
      state.openDrawerProductNotificationAvailable = action.payload
    },
    toggleHamburgerMenuOpen: (state: IUiState, action) => {
      state.hamburgerMenuDrawerOpen = action.payload
    },
    lastSelectedProduct: (state: IUiState, action) => {
      state.lastSelectedProduct = action.payload
    },
    productsMobileListingLayout: (state: IUiState, action) => {
      state.productsMobileListingLayout = action.payload
    },
    openModalSignIn: (state: IUiState, action) => {
      state.openModalSignIn = action.payload
    },
    openModalRegistration: (state: IUiState, action) => {
      state.openModalRegistration = action.payload
    },
    openModalAddPrescription: (state: IUiState, action) => {
      state.openModalAddPrescription.prescription = action.payload?.prescription
      state.openModalAddPrescription.isOpen = action.payload?.isOpen
      state.openModalAddPrescription.onActionSuccess = action.payload?.onActionSuccess
    },
    openModalDeletePrescription: (state: IUiState, action) => {
      state.openModalDeletePrescription.prescription = action.payload?.prescription
      state.openModalDeletePrescription.isOpen = action.payload?.isOpen
      state.openModalDeletePrescription.onActionSuccess = action.payload?.onActionSuccess
    },
    openModalViewRX: (state: IUiState, action) => {
      state.openModalViewRX.prescription = action.payload?.prescription
      state.openModalViewRX.isOpen = action.payload?.isOpen
    },
    openModalBookExam: (state: IUiState, action) => {
      state.openModalBookExam = action.payload
    },
    vMisOpen: (state: IUiState, action) => {
      state.vMisOpen = action.payload
    },
  },
})

export const {
  openDrawerFilters,
  openDrawerMegaMenu,
  closeDrawerMegaMenu,
  openDrawerSearch,
  openDrawerNewsLetter,
  openDrawerCartSummary,
  openDrawerProductNotificationAvailable,
  toggleHamburgerMenuOpen,
  lastSelectedProduct,
  productsMobileListingLayout,
  openModalSignIn,
  openModalRegistration,
  openModalAddPrescription,
  openModalDeletePrescription,
  openModalViewRX,
  openModalBookExam,
  vMisOpen,
} = uiSlice.actions

export default uiSlice.reducer
