import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IProduct, ProductType, ContactLensesData } from '../../types/product'
import ProductService from '../../services/ProductService'
import { OrderItem } from '../../types/order'
import { OrderItemContactLensData } from '../../types/cart'
import { productApi } from './query'
import { getCurrentProductItemFromProductData, normalizedProductType } from '@utils/product'

const toggleAddToCartButton = (productType: ProductType): boolean => {
  switch (productType) {
    case 'contact-lenses':
      return false
    default:
      return true
  }
}

export interface CurrentProductBundle {
  product?: IProduct
  orderItem?: OrderItem
  contactLensData?: OrderItemContactLensData
}

export type ProductState = {
  productType: ProductType | null
  contactLensesData?: ContactLensesData | null
  addContactLensesToCartError?: boolean
  addCLAccessoriesToCartError?: string | null
  addSubscriptionToCartError?: string
  addToCartEnabled?: boolean
  currentBundle: CurrentProductBundle
  moCoOrderedIds: string[]
}

export type ProductApiState = Partial<IProduct>

const initialState: ProductState = {
  productType: null,
  contactLensesData: {
    left: {},
    right: {},
  },
  addToCartEnabled: false,
  addContactLensesToCartError: false,
  currentBundle: {},
  moCoOrderedIds: [],
}

export const {
  actions: {
    updateContactLensData,
    toggleAddToCart,
    toggleAddContactLensesToCartError,
    toggleAddClAccessoriesToCartError,
    toggleAddSubscriptionToCartError,
    setCurrentProductBundle,
    clearCurrentProductBundle,
    setMocoOrderedIds,
  },
  reducer,
} = createSlice({
  name: 'product',
  initialState,
  reducers: {
    updateContactLensData: (state: ProductState, action: PayloadAction<ContactLensesData>) => {
      state.contactLensesData = action.payload
    },
    toggleAddToCart: (state: ProductState, action: PayloadAction<boolean>) => {
      state.addToCartEnabled = action.payload
    },
    toggleAddContactLensesToCartError: (state: ProductState, action: PayloadAction<boolean>) => {
      state.addContactLensesToCartError = action.payload
    },
    toggleAddClAccessoriesToCartError: (state: ProductState, action: PayloadAction<string | null>) => {
      state.addCLAccessoriesToCartError = action.payload
    },
    toggleAddSubscriptionToCartError: (state: ProductState, action: PayloadAction<string>) => {
      state.addSubscriptionToCartError = action.payload
    },
    setCurrentProductBundle: (state: ProductState, action: PayloadAction<CurrentProductBundle>) => {
      const { product, orderItem, contactLensData } = action.payload

      if (orderItem) state.currentBundle.orderItem = orderItem
      if (contactLensData) state.currentBundle.contactLensData = contactLensData

      if (product) {
        const productType = ProductService.getProductType(product)
        state.productType = productType
        state.addToCartEnabled = toggleAddToCartButton(productType)
        state.currentBundle.product = product
      }
    },
    clearCurrentProductBundle: (state: ProductState) => {
      state.currentBundle = {}
      state.productType = null
    },
    setMocoOrderedIds: (state: ProductState, action: PayloadAction<string[]>) => {
      state.moCoOrderedIds = action.payload
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      productApi.endpoints.getProductDetails.matchFulfilled,
      (state, { payload }: PayloadAction<IProduct>) => {
        const productData = payload
        const currentProduct =
          productData || payload ? getCurrentProductItemFromProductData(productData?.partNumber!, productData) : null

        const productType = currentProduct?.productAttributes['PRODUCT_TYPE']
          ? normalizedProductType(currentProduct?.productAttributes['PRODUCT_TYPE'])
          : null

        state.productType = productType
        state.addToCartEnabled = toggleAddToCartButton(productType)
      }
    )
  },
})

export default reducer
