export const PRODUCT_SOLDOUT_STATUS = {
  NONE: 'NONE',
  SOLDOUT: 'SOLD OUT',
  COMING_SOON: 'COMING SOON',
  COMING_BACK_SOON: 'COMING BACK SOON',
  INFINITE_INVENTORY: 'INFINITE_INVENTORY',
  OUT_OF_STOCK: 'OUT-OF-STOCK',
} as const

export const DEFAULT_DELIVERY_DATES = {
  PLANO_STANDARD: 5,
  RX_MAN: 2,
  RX_STANDARD: 7,
}

// lowercased values related to `FRAME_MATERIAL_CLASS` attribute
export const RECYCLABEL_FRAME_MACRO_MATERIALS = ['bio-acetate', 'bio-plastic']

// lowercased values related to `LENS_MATERIAL_MACRO_CLASS` attribute
export const RECYCLABEL_LENS_MACRO_MATERIALS = ['bio-lenses', 'standard lenses']

export const PRODUCT_TYPES_KEYS = {
  CONTACT_LENSES: 'contact-lenses',
  OPTICAL: 'optical',
  FRAMES: 'frames',
  ACCESSORIES: 'accessories',
  CONTACT_LENSES_ACCESSORIES: 'contact-lenses-accessories',
  SUN: 'sun',
  ELECTRONICS: 'electronics',
} as const

export const PRODUCT_TYPES_MAP = {
  'contact lenses': 'contact-lenses',
  'contact-lenses': 'contact-lenses',
  contactlenses: 'contact-lenses',
  frames: 'frames',
  accessories: 'accessories',
  'contact lenses accessories': 'contact-lenses-accessories',
  'contact-lenses-accessories': 'contact-lenses-accessories',
  optical: 'optical',
  sun: 'sun',
  electronics: 'electronics',
} as const

export const CL_EYE_LEFT = 'left'
export const CL_EYE_RIGHT = 'right'

export const PRODUCT_TYPE = 'PRODUCT_TYPE'
export const ATTRIBUTE_NATURAL = 'attribute.natural'

export const LX_SEARCH_PAGE_PRODUCT_TYPE = 'LX_SEARCH_PAGE_PRODUCT_TYPE'
export const SKU_TYPE = 'SKU_TYPE'

export const PRODUCT_URL_SEARCH_PARAMS = {
  ADD_LENS: 'addLens',
  EDIT_LENS: 'editLens',
  EDIT_SIZE: 'editSize',
  EDIT_CONTACT_LENS: 'editContactLens',
} as const

export const SUGGESTED_PRODUCT_FAMILY = {
  X_SELL_FAMILY: 'calculated-x-sell',
  CL_FAMILY: 'x-sell-family',
}

export const PRODUCT_LIFE_CYCLE = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}

export const RECENTLY_VIEWED_PRODUCT_HISTORY = 'pdpH'

export const PRODUCT_CATEGORY_TYPES = [
  'cly-products-category-tabs',
  'cly-four-products-category-tabs',
  'box-and-2-products',
  'box-and-4-products',
]
