import { AppContext } from 'next/app'
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next/types'
import { StoreManagerLoaders, storeManagerLoaders } from './stateManagment/stateManagerHandler'
import { StoreManagerLoadersTypeMap } from './stateManagment/types'

type GetServerInitialPropsResult = unknown

const serverSideWrapper =
  <Rtn, Ctx = GetServerSidePropsContext, T = GetServerSidePropsResult<Rtn>>(
    strategyPattern: StoreManagerLoaders<keyof StoreManagerLoadersTypeMap>
  ) =>
  (handler: (strategyPattern: StoreManagerLoaders<keyof StoreManagerLoadersTypeMap>, context: Ctx) => Promise<T>) =>
  async (context: Ctx): Promise<T> => {
    const result = await handler(strategyPattern, context)

    return result
  }

type HandlerFunction<Rtn, Ctx = GetServerSidePropsContext, T = GetServerSidePropsResult<Rtn>> = (
  strategyPattern: StoreManagerLoaders<keyof StoreManagerLoadersTypeMap>,
  context: Ctx
) => Promise<T>

export const serverSidePropsWrapper = <Rtn>(handler: HandlerFunction<Rtn>) =>
  serverSideWrapper<Rtn>(storeManagerLoaders)(handler)

export const serverInitialPropsWrapper = <Rtn>(
  handler: HandlerFunction<Rtn, AppContext, GetServerInitialPropsResult>
) => serverSideWrapper<Rtn, AppContext, GetServerInitialPropsResult>(storeManagerLoaders)(handler)
