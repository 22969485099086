//Standard libraries
import { AnyAction, createSlice } from '@reduxjs/toolkit'
//Redux
// @ts-ignore
import {
  LOAD_SUBSCRIPTION_CONFIG_REQUEST_ACTION,
  SET_SUBSCRIPTION_CONFIG_ACTION,
  SET_SUBSCRIPTION_LAST_SUBSCRIBED_ORDER_ACTION,
  SET_SUBSCRIPTION_ORDER_COMPLETE_ACTION,
} from '@redux/actions/subscription'
import { SubscriptionReducerState } from './reducerStateInterface'

const initialState: SubscriptionReducerState = {
  enabled: false,
}

/**
 * Subscription reducer
 * handles states used by subscriptions related components
 * @param state State object managed by subscription reducer
 * @param action The dispatched action
 */

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      LOAD_SUBSCRIPTION_CONFIG_REQUEST_ACTION,
      (state: SubscriptionReducerState | any, action: AnyAction) => {
        const { enabled, allowedProducts } = action.payload
        Object.assign(state, {
          ...state,
          enabled,
          allowedProducts,
        })
      }
    )
    builder.addCase(SET_SUBSCRIPTION_CONFIG_ACTION, (state: SubscriptionReducerState | any, action: AnyAction) => {
      const updatedItem = action.payload.item
      const allItems = state.subscribedItems || []
      const currentItems = allItems.filter(item => item.identifier !== updatedItem.identifier)
      currentItems.push({
        identifier: updatedItem.identifier,
        recurrency: updatedItem.recurrency,
        active: updatedItem.active,
      })

      Object.assign(state, {
        ...state,
        subscribedItems: currentItems,
      })
    })
    builder.addCase(
      SET_SUBSCRIPTION_LAST_SUBSCRIBED_ORDER_ACTION,
      (state: SubscriptionReducerState | any, action: AnyAction) => {
        Object.assign(state, {
          ...state,
          lastSubscribedOrderId: action.payload,
          subscribedItems: [],
        })
      }
    )
  },
})

export default subscriptionSlice.reducer
