import { USER_CONTEXT_REQUEST_ERROR_ACTION, USER_CONTEXT_REQUEST_SUCCESS_ACTION } from '../../actions/context'
import { call, put, select } from 'redux-saga/effects'

import { AppPayloadWithWidgetAction } from '../../store'
import Log from '../../../services/Log'
import jsonBig from 'json-bigint'
import userContextService from '../../../foundation/apis/transaction/userContext.service'
import { storeIdSelector } from '@redux/selectors/site'

export function* getUserContext({ payload }: AppPayloadWithWidgetAction) {
  try {
    const storeId = yield select(storeIdSelector)
    // NOTE: making sure that storeId is always present in the request payload.
    // there might still be a case when storeId is undefined, but this needs to be monitored
    const response = yield call(userContextService.get, { ...payload, storeId })
    const userPayload = jsonBig.parse(response?.request.response || '{}')

    userPayload.entitlement.currentTradingAgreementIds[0] =
      userPayload.entitlement.currentTradingAgreementIds[0].toString()

    yield put(USER_CONTEXT_REQUEST_SUCCESS_ACTION(userPayload))
  } catch (e) {
    // console.log('error', e)
    Log.error('Error in retrieving context' + e)
    yield put(USER_CONTEXT_REQUEST_ERROR_ACTION(e))
  }
}
