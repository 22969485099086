export const MISC_3_ICONS = {
  fileName: '/images/icons/misc3-sprite.svg',
  icons: [
    'one-sight',
    'direction',
    'everyday-comfort',
    'leading-tech',
    'sanitation-station',
    'extra-cleaning',
    'insurance',
    'sunny',
    'mitigate-sensitivity',
    'credit-card-secure',
    'polarized-primary',
    'magnify-eye',
    'savings',
    'shipping-delay',
    'heart-on-hand',
    'try-on-anywhere',
    'glasses-with-heart',
    'glass-on-hand',
    'glass-on-face',
    'lens-sun',
    'smiling-face',
    'add-circle',
    'remove-circle',
    'subscription-free-delivery',
    'subscription-auto-delivery',
    'subscription-calendar',
    'global-online',
    'download',
  ],
} as const
