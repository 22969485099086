import { CANADA_COUNTRY_CODE } from '@constants/common'
import { SiteInfo } from '@redux/rootReducer'

const storeUtil = {
  isNumeric: (input: string) => isFinite(Number(input)),
  isMTOEnabled: (mySite: SiteInfo) => {
    const mtoValue = mySite?.xStoreCfg?.isMtoEnabled
    //Safeguard in case they send it as String
    return String(mtoValue).toLowerCase() === 'true'
  },
}

export const isCanadaStore = (locale: string): boolean => {
  return locale.endsWith(`_${CANADA_COUNTRY_CODE}`)
}

export default storeUtil
