import pino from 'pino'

const prettyTransport = {
  target: 'pino-pretty',
  options: {
    colorize: true,
  },
}

const stdoutTransport = {
  target: 'pino/file',
  options: {
    destination: 1,
  },
}

export const logger = pino({
  transport: process.env.NODE_ENV === 'development' ? prettyTransport : stdoutTransport,
})

// Usage
// import logger in appropriate module
// to track `info` logger.info(message)
// to track `error` logger.error(message)
// to track `warn` logger.warn(message)
// to track `trace` logger.trace(message)
